export const useStyles = (theme) => ({
    mainContainer: {
        width: "100%",
        height: "100%",
        padding: "0px 15px 10px 0px",
        marginBottom: "10px"
    },
    componentContainer: {
        height: "100%",
        margin: "0px 15px 0px 180px",
    },
    cardContainer: {
        backgroundColor: "#FFFFFF",
        borderRadius: "20px",
        height: "100%",
        overflow: "auto",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    sidebar: {
        width: '180px',
        height: '100%',
        flexShrink: 0,
        position: 'fixed'
    },

})