import { useEffect, useState } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './styles/App.css';
import Signin from './Auth/Signin';
import Authorized from './Auth/Authorized';
import { routesConfig } from './Routes/Routes';
import ProtectedRoutes from './Routes/ProtectedRoutes';

import { getMainStyles } from './styles/styles';
import { useTheme } from '@mui/material/styles';
import Sidebar from './pages/Sidebar/Sidebar';
import Navbar from './pages/Navbar/Navbar';
import useAuthCheck from './Auth/AuthTokenCheck';

function App() {
  useAuthCheck()
  const navigate = useNavigate()
  const location = useLocation()
  const theme = useTheme()
  const mainStyles = getMainStyles(theme);

  const [isAuthenticated, setIsAuthenticated] = useState(false)

  useEffect(() => {
    handleAuthentication()
    //eslint-disable-next-line
  }, [navigate])

  const handleAuthentication = async () => {
    const jwtIdToken = JSON.parse(localStorage.getItem('dpod-token'))
    if (location.pathname !== "/authorized") {
      if (jwtIdToken) {
        setIsAuthenticated(true)
      } else {
        setIsAuthenticated(false)
        localStorage.clear()
        navigate('/login');
      }
    }
  }

  return (

    <div className="App" style={mainStyles.mainContainer}>
      <ToastContainer position="top-center" autoClose={3500} hideProgressBar newestOnTop={false} closeOnClick
        rtl={false} pauseOnFocusLoss draggable pauseOnHover theme='colored' />

      {!isAuthenticated ?
        (<Routes>
          <Route path='/login' element={<Signin />}></Route>
          <Route path='/authorized' element={<Authorized />}></Route>
        </Routes>)
        :
        (<>

          <div style={mainStyles.sidebarContainer}>
            <Sidebar />
          </div>

          <div style={mainStyles.navMainConatainer}>

            <div style={mainStyles.navContainer}>
              <Navbar />
            </div>

            <main style={mainStyles.mainComponentContainer}>
              <Routes>
                <Route element={<ProtectedRoutes />}>
                  {routesConfig.map((route, index) => (
                    <Route key={index} path={route.path} element={route.element} />
                  ))}
                </Route>
              </Routes>
            </main>
          </div>
        </>)
      }
    </div>
  );
}

export default App;
