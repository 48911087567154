import React, { useState, useEffect, useRef } from 'react';

import {
    Box, Typography, SwipeableDrawer, IconButton, Avatar, Button, CircularProgress,
    TableCell, Paper, TableContainer, TableHead, TableRow, Table, TableBody, MenuItem, Select, Pagination,
    TextField,
    InputAdornment
} from '@mui/material';
import { SendOutlined, ThumbDownAlt, ThumbUpAlt } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { getComponentsStyles } from '../../../../styles/componentsStyles';
import { Styles } from './Styles';
import { getMainStyles } from '../../../../styles/styles';
import { fontStyles } from '../../../../styles/fontStyles';
import { tableStyles } from '../../../../styles/tableStyles';

import { getUserInitials } from '../../../../utils/GetAccountDetails';

function ChatHandler({
    chatdrawer, toggleDrawer, chatType,
    setQmsChatData, qmsChatData,
    setRenderableItems, renderableItems,
    setUserPrompt, userPrompt,
    handleQmsChat, chatMessageLoading,
    handleUserThumbsFeedback, handleUserChatFeedback, handleUserContinueOptions, handleUserPrompt }) {

    const theme = useTheme();
    const mainStyles = getMainStyles(theme);
    const componentsStyles = getComponentsStyles(theme);

    const chatWindowRef = useRef(null);
    const [loadingDots, setLoadingDots] = useState('');

    useEffect(() => {
        if (chatWindowRef.current) {
            chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
        }
    }, [qmsChatData, setQmsChatData, renderableItems, handleUserThumbsFeedback, handleUserChatFeedback, handleUserContinueOptions, handleUserPrompt]);

    useEffect(() => {
        const interval = setInterval(() => {
            setLoadingDots(prevDots => (prevDots.length < 6 ? prevDots + '.' : ''));
        }, 500);

        return () => clearInterval(interval);
    }, []);

    const getRenderableItems = (chats) => {
        let hasShownUnprocessed = false;
        const updatedChats = chats?.map((chat) => {
            const updatedTrainChatData = chat?.train_chat_data?.filter((msg) => {
                if (!hasShownUnprocessed && !msg?.isProcessed) {
                    hasShownUnprocessed = true;
                    return true;
                }
                return msg?.isProcessed;
            });
            return {
                ...chat,
                train_chat_data: updatedTrainChatData,
            };
        });

        return updatedChats;
    };

    useEffect(() => {
        const updatedItems = qmsChatData?.chats?.length > 0 ? { chats: getRenderableItems(qmsChatData.chats) } : { chats: [] };
        setRenderableItems(updatedItems);
        //eslint-disable-next-line
    }, [qmsChatData])

    const handleRowsPerPageChange = (event, q_item, chat_index) => {
        const newRowsPerPage = event.target.value;
        const totalPages = Math.ceil(q_item.train_response.length / newRowsPerPage);
        setQmsChatData((prevState) => ({
            ...prevState,
            chats: prevState.chats.map((chat, idx) =>
                idx === chat_index
                    ? {
                        ...chat,
                        pagination: {
                            ...chat.pagination,
                            rowsPerPage: newRowsPerPage,
                            totalPages: totalPages,
                            page: 1,
                        }
                    }
                    : chat
            )
        }));
    };

    const handlePageChange = (newPage, q_item, chat_index) => {
        setQmsChatData((prevState) => ({
            ...prevState,
            chats: prevState.chats.map((chat, idx) =>
                idx === chat_index
                    ? {
                        ...chat,
                        pagination: {
                            ...chat.pagination,
                            page: newPage,
                        }
                    }
                    : chat
            )
        }));
    };

    return (
        <SwipeableDrawer
            anchor='right'
            open={chatdrawer.state}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
            sx={{
                height: '100vh',
            }}
        >
            <Box sx={mainStyles.sideDrawerContainer}>

                <Typography style={fontStyles.drawerHeading}>{chatType} AI QMS Agent</Typography>

                {!chatMessageLoading && renderableItems?.chats?.length === 0 && (
                    <Box sx={Styles.chatBoxLoadingContainer}>
                        No Preview Available
                    </Box>
                )}

                {renderableItems?.chats?.length === 0 && chatMessageLoading ?
                    (<Box sx={Styles.chatBoxLoadingContainer}>
                        <CircularProgress sx={{ color: '#0B51C5' }} />
                    </Box>) :
                    (<Box sx={Styles.chatBoxOuterContainer} ref={chatWindowRef}>
                        <Box sx={Styles.chatBoxInnerContainer}>
                            {renderableItems?.chats?.map((q_item, q_index) => {
                                return (
                                    <Box key={q_index} width={'100%'}>

                                        {q_item?.train_chat_data?.length > 0 && q_item?.train_chat_data?.map((msg, msg_index) => (
                                            <Box key={msg_index}>
                                                {<Box display={'flex'} width={'100%'}>
                                                    {msg?.sender === 'user' &&
                                                        <Box width={'20px'} marginTop={'5px'}>
                                                            <Avatar sx={{ bgcolor: '#000000', height: '32px', width: '32px' }}>
                                                                <Typography sx={fontStyles.mediumText}>{getUserInitials()}</Typography>
                                                            </Avatar>
                                                        </Box>}

                                                    {msg?.sender === 'system' &&
                                                        <Box width={'20px'} marginTop={'5px'}>
                                                            <Box sx={mainStyles.ameya_icon}>
                                                            </Box>
                                                        </Box>
                                                    }

                                                    <Box
                                                        sx={Styles.chatBoxMessageContainer}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                ...fontStyles.smallText,
                                                                ...Styles.chatMessages
                                                            }}
                                                        >
                                                            {msg.chat}
                                                        </Typography>
                                                    </Box>
                                                </Box>}

                                                {msg?.feedBackOptions?.length > 0 && msg?.sender === 'system' && !msg?.isProcessed &&
                                                    < Box width={'100%'}>
                                                        <Box sx={Styles.chatResponseContainer}>
                                                            {msg?.feedBackOptions?.map((item, index) => (
                                                                <Button
                                                                    key={index}
                                                                    sx={{ ...Styles.chatResponsebtn }}
                                                                    onClick={() => handleQmsChat(q_index, msg.questionId, item)}
                                                                >
                                                                    <Typography sx={{ ...fontStyles.smallText, textTransform: 'capitalize' }}>{item}</Typography>
                                                                </Button>
                                                            ))}
                                                        </Box>
                                                    </Box>
                                                }
                                            </Box>))
                                        }

                                        {q_item?.train_response?.length > 0 &&
                                            <Box paddingLeft={'35px'} marginBottom={'15px'}>
                                                <Paper>
                                                    < TableContainer component={Paper} sx={tableStyles.tcontainer}>
                                                        <Table stickyHeader>
                                                            <TableHead sx={tableStyles.thead}>
                                                                <TableRow>
                                                                    <TableCell sx={{ ...tableStyles.thcell, width: '20%', paddingTop: '10px', paddingBottom: '10px' }}>Specification</TableCell>
                                                                    <TableCell sx={{ ...tableStyles.thcell, width: '20%', paddingTop: '10px', paddingBottom: '10px' }}>Document</TableCell>
                                                                    <TableCell sx={{ ...tableStyles.thcell, width: '30%', paddingTop: '10px', paddingBottom: '10px' }}>Sample Id</TableCell>
                                                                    <TableCell sx={{ ...tableStyles.thcell, width: '30%', paddingTop: '10px', paddingBottom: '10px' }}>Report Value</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody sx={tableStyles.tbody}>
                                                                {q_item?.pagination?.page > 0 && q_item?.pagination?.rowsPerPage > 0 &&
                                                                    (q_item?.train_response
                                                                        .slice((q_item?.pagination.page - 1) * q_item?.pagination.rowsPerPage, Math.min(q_item?.pagination.page * q_item?.pagination.rowsPerPage, q_item?.train_response.length))
                                                                        .map((item, index) => (
                                                                            <TableRow sx={tableStyles.trow} key={index}>
                                                                                <TableCell sx={{ ...tableStyles.tdcell, paddingTop: '8px', paddingBottom: '8px' }}>{item?.test_name_spec}</TableCell>
                                                                                <TableCell sx={{ ...tableStyles.tdcell, paddingTop: '8px', paddingBottom: '8px' }}>{item?.test_name_document}</TableCell>
                                                                                <TableCell sx={{ ...tableStyles.tdcell, paddingTop: '8px', paddingBottom: '8px' }}>{item?.sample_id}</TableCell>
                                                                                <TableCell style={{ ...tableStyles.tdcell, paddingTop: '8px', paddingBottom: '8px' }}>
                                                                                    {item?.status === "pass" ?
                                                                                        <Box>{item?.result_value}&nbsp;{item?.uom}</Box>
                                                                                        : <Typography sx={{ ...fontStyles.smallText, color: "#C30E2E", fontWeight: 'bold' }}>UNKNOWN</Typography>}
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        ))
                                                                    )}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                    <Box display={'flex'} justifyContent={'space-between'} padding={'10px'} alignItems={'center'}>
                                                        <Box display={'flex'} alignItems={'center'}>
                                                            <Box marginLeft={'20px'}>
                                                                <Select
                                                                    value={q_item?.pagination?.rowsPerPage || 5}
                                                                    onChange={(event) => handleRowsPerPageChange(event, q_item, q_index)}
                                                                    sx={{ width: '110px', height: '35px' }}
                                                                >
                                                                    <MenuItem value={5}>5 rows</MenuItem>
                                                                    <MenuItem value={10}>10 rows</MenuItem>
                                                                    <MenuItem value={15}>15 rows</MenuItem>
                                                                    <MenuItem value={20}>20 rows</MenuItem>
                                                                </Select>

                                                            </Box>
                                                        </Box>
                                                        <Pagination
                                                            count={q_item?.pagination?.totalPages || 1}
                                                            page={q_item?.pagination?.page || 1}
                                                            onChange={(event, newPage) => handlePageChange(newPage, q_item, q_index)}
                                                            color="standard"
                                                        />
                                                    </Box>
                                                </Paper>

                                            </Box>
                                        }

                                        {q_item?.train_feedback_thumbs_options?.length > 0 &&
                                            <Box sx={Styles.chatFeedBackContainer}>
                                                {q_item?.train_feedback_thumbs_options?.map((item, index) => (
                                                    <Box key={index} sx={{ marginLeft: '5px' }}>
                                                        <IconButton
                                                            sx={{
                                                                backgroundColor: '#0B51C5',
                                                                color: 'white',
                                                                '&:hover': {
                                                                    backgroundColor: 'white',
                                                                    color: '#0B51C5',
                                                                },
                                                                '&:active': {
                                                                    backgroundColor: 'white',
                                                                    color: '#0B51C5',
                                                                },
                                                            }}
                                                            disabled={chatMessageLoading}
                                                            onClick={() => handleUserThumbsFeedback(q_index, item)}
                                                        >
                                                            {item === "thumbs_up" ? <ThumbUpAlt /> : <ThumbDownAlt />}
                                                        </IconButton>
                                                    </Box>
                                                ))}
                                            </Box>
                                        }

                                        {q_item?.train_feedback_chat_options?.length > 0 &&
                                            <Box sx={Styles.chatFeedBackContainer}>
                                                {q_item?.train_feedback_chat_options?.map((item, index) => (
                                                    < Box display={'flex'} key={index}>
                                                        <Button
                                                            sx={{ ...Styles.chatFeedbackbtn, marginLeft: '10px' }}
                                                            disabled={chatMessageLoading}
                                                            onClick={() => handleUserChatFeedback(q_index, item)}
                                                        >
                                                            <Typography sx={{ ...fontStyles.smallText }}>{item?.replace('_', ' ').replace(/\b\w/g, char => char.toUpperCase())}</Typography>
                                                        </Button>
                                                    </Box>
                                                ))}
                                            </Box>
                                        }

                                        {q_item?.train_continue_options?.length > 0 &&
                                            <Box sx={Styles.chatFeedBackContainer}>
                                                {q_item?.train_continue_options?.map((item, index) => (
                                                    < Box display={'flex'} key={index}>
                                                        <Button
                                                            sx={{ ...Styles.chatFeedbackbtn, marginLeft: '10px' }}
                                                            disabled={chatMessageLoading}
                                                            onClick={() => handleUserContinueOptions(q_index, item)}
                                                        >
                                                            <Typography sx={{ ...fontStyles.smallText }}>{item?.replace('_', ' ').replace(/\b\w/g, char => char.toUpperCase())}</Typography>
                                                        </Button>
                                                    </Box>
                                                ))}
                                            </Box>
                                        }

                                        {q_item?.train_user_Prompt?.user_prompt_status === 'active' &&
                                            <Box paddingLeft={'35px'} marginBottom={'15px'}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    size="small"
                                                    value={userPrompt}
                                                    placeholder="Type here..."
                                                    sx={{ ...componentsStyles.textField }}
                                                    onChange={(e) => setUserPrompt(e.target.value)}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter' && userPrompt.trim()) {
                                                            handleUserPrompt(q_index, userPrompt)
                                                        }
                                                    }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton color="primary" onClick={() => handleUserPrompt(q_index, userPrompt)}>
                                                                    <SendOutlined sx={{ color: '#0B51C5' }} />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Box>
                                        }

                                        {q_item?.train_user_Prompt?.user_prompt_status === 'closed' &&
                                            <Box display={'flex'} width={'100%'}>
                                                <Box width={'20px'} marginTop={'5px'}>
                                                    <Avatar sx={{ bgcolor: '#000000', height: '32px', width: '32px' }}>
                                                        <Typography sx={fontStyles.mediumText}>{getUserInitials()}</Typography>
                                                    </Avatar>
                                                </Box>
                                                <Box sx={Styles.chatBoxMessageContainer}>
                                                    <Typography sx={{ ...fontStyles.smallText, ...Styles.chatMessages }}>
                                                        {q_item?.train_user_Prompt?.user_prompt_message}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        }

                                    </Box>)
                            })}

                            {chatMessageLoading &&
                                <Box
                                    width={'100%'}
                                    display={'flex'}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                    marginBottom={'20px'}
                                    paddingBottom={'20px'}
                                    color={'#0B51C5'}
                                    fontSize={'20px'}
                                >
                                    {loadingDots}
                                </Box>
                            }

                        </Box>
                    </Box>)
                }
            </Box>
        </SwipeableDrawer >
    )
}

export default ChatHandler;