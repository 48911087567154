import React, { useState } from 'react';
import moment from 'moment';

import {
    Box, Typography, Card, CardContent,
    TextField, Button, Checkbox
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useStyles } from './styles.js';
import { getComponentsStyles } from "../../../styles/componentsStyles.js";

import ElasticSearchAPIConnector from "@elastic/search-ui-elasticsearch-connector";
import { ErrorBoundary, SearchProvider, SearchBox, Results, PagingInfo, ResultsPerPage, Paging, WithSearch, Sorting, Facet } from "@elastic/react-search-ui";
import { Layout } from "@elastic/react-search-ui-views";
import "@elastic/react-search-ui-views/lib/styles/styles.css";
// import { getSessionData } from '../../../utils/sessionDataHandle.js';
import { fontStyles } from '../../../styles/fontStyles.js';

function ApiLogs() {
    const theme = useTheme();
    const styles = useStyles(theme);
    const componentsStyles = getComponentsStyles(theme);
    // const selected_project = getSessionData("selected_project")

    const connector = new ElasticSearchAPIConnector({
        host: window.env.REACT_APP_ELASTIC_SEARCH_HOST || process.env.REACT_APP_ELASTIC_SEARCH_HOST,
        index: window.env.REACT_APP_ELASTIC_SEARCH_INDEX || process.env.REACT_APP_ELASTIC_SEARCH_INDEX,
    });

    const SORT_OPTIONS = [
        {
            name: "Relevance",
            value: []
        },
        {
            name: "Start Time",
            value: [
                {
                    field: "@timestamp",
                    direction: "desc"
                }
            ]
        },
        {
            name: "End Time",
            value: [
                {
                    field: "EndTimestamp",
                    direction: "desc"
                }
            ]
        },
        {
            name: "Duration",
            value: [
                {
                    field: "Duration",
                    direction: "asc"
                }
            ]
        }
    ];

    const config = {
        debug: true,
        alwaysSearchOnInitialLoad: true,
        apiConnector: connector,
        hasA11yNotifications: true,
        searchQuery: {
            filters: [
                {
                    field: "Attributes.service.keyword",
                    values: ["audit"],
                    // values: [selected_project],
                    type: "all"
                }
            ],
            result_fields: {
                id: { raw: {} },
                "@timestamp": { raw: {} },
                endpoint: { raw: {} },
                message: { raw: {} },
                Attributes: { raw: {} },
                Duration: { raw: {} },
                EndTimestamp: { raw: {} },
                Name: { raw: {} },
                status_code: { raw: {} }
            },
            disjunctiveFacets: ["status_code", "Attributes.endpoint.keyword"],
            facets: {
                "Attributes.status_code": { type: "value" },
                "Attributes.endpoint.keyword": { type: "value" }
            },
            sort: SORT_OPTIONS,
        }
    };

    const CustomResultView = ({ result }) => {
        const durationInSeconds = (result?.Duration?.raw / 1000).toFixed(2);
        const startTimestamp = result?.['@timestamp']?.raw;
        return (
            <Card variant="outlined" sx={{ marginBottom: '20px' }}>
                <CardContent>
                    <Typography><strong>Id:</strong> {result?.id?.raw}</Typography>
                    <Typography><strong>Name:</strong> {result?.Name?.raw}</Typography>
                    <Typography><strong>Descriptions:</strong> {JSON.stringify(result?.Attributes?.raw?.description)}</Typography>
                    <Typography><strong>Engine Type:</strong> {JSON.stringify(result?.Attributes?.raw?.engine_type)}</Typography>
                    <Typography><strong>Endpoint:</strong> {JSON.stringify(result?.Attributes?.raw?.endpoint)}</Typography>
                    <Typography><strong>Service:</strong> {JSON.stringify(result?.Attributes?.raw?.service)}</Typography>
                    <Typography><strong>Start Timestamp:</strong> {moment(startTimestamp).format('YYYY-MM-DD HH:mm:ss.SSS')}</Typography>
                    <Typography><strong>End Timestamp:</strong> {moment(result?.EndTimestamp?.raw).format('YYYY-MM-DD HH:mm:ss.SSS')}</Typography>
                    <Typography><strong>Duration:</strong> {durationInSeconds} seconds</Typography>
                    {JSON.stringify(result?.Attributes?.raw?.status_code) === 200 ?
                        (<Typography><strong>Status Code:</strong> {JSON.stringify(result?.Attributes?.raw?.status_code)}</Typography>)
                        :
                        (<Typography sx={{ color: 'red' }}><strong>Status Code:</strong> {JSON.stringify(result?.Attributes?.raw?.status_code)}</Typography>)
                    }
                </CardContent>
            </Card>
        )
    }

    const CustomFacetView = ({ options = [], onChange, selectedValues: initialSelectedValues = [] }) => {
        const [searchQuery, setSearchQuery] = useState("");
        const [showAll, setShowAll] = useState(false);
        const [selectedValues, setSelectedValues] = useState(initialSelectedValues);

        const getLastPartOfEndpoint = (value) => value.split("/").pop();

        const filteredOptions = options.filter((option) =>
            option.value.toLowerCase().includes(searchQuery.toLowerCase())
        );

        const displayedOptions = showAll ? filteredOptions : filteredOptions.slice(0, 3);

        const handleCheckboxChange = (value) => {
            const newSelectedValues = selectedValues.includes(value)
                ? selectedValues.filter((v) => v !== value)
                : [...selectedValues, value];

            setSelectedValues(newSelectedValues);
            onChange(newSelectedValues);
        };

        return (
            <div>
                <Typography variant="h8" color='grey'>Endpoints</Typography>
                <TextField

                    variant="outlined"
                    size="small"
                    placeholder="Filter endpoints..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    sx={{ ...componentsStyles.textField, marginBottom: "5px", marginTop: "5px", width: '100%' }}
                />

                {options.length > 0 &&
                    displayedOptions.map((option) => (
                        <div key={option.value}>
                            <label style={{ display: "flex", alignItems: "center" }}>
                                <Checkbox
                                    sx={{
                                        color: "grey",
                                        "&.Mui-checked": {
                                            color: "#0B51C5",
                                        },
                                        "& .MuiSvgIcon-root": {
                                            fontSize: 16,
                                        },
                                    }}
                                    checked={selectedValues.includes(option.value)}
                                    onChange={() => handleCheckboxChange(option.value)}
                                />
                                <span style={{ whiteSpace: "normal", wordBreak: "break-word" }}>
                                    {getLastPartOfEndpoint(option.value)} ({option.count})
                                </span>
                            </label>
                        </div>
                    ))}

                {filteredOptions.length > 3 && (
                    <Button
                        onClick={() => setShowAll(!showAll)}
                        sx={{ marginTop: "5px", width: "100%", color: "#000000", fontSize: '12px' }}
                    >
                        {showAll ? "Show Less" : "Show More"}
                    </Button>
                )}
            </div>
        );
    };

    return (
        <Box style={styles.mainContainer} >
            <Box sx={styles.cardContainer}>
                <Typography sx={fontStyles.largeText}>All Logs</Typography>
                <Box marginTop={'20px'}>
                    <SearchProvider config={config} debug={true}>
                        <WithSearch
                            mapContextToProps={({ wasSearched, searchTerm, results }) => ({
                                wasSearched,
                                searchTerm,
                                results,
                            })}
                        >
                            {({ wasSearched }) => {

                                return (
                                    <div className="App" style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
                                        <ErrorBoundary>
                                            <div style={{ flex: '0 0 auto' }}>
                                                <SearchBox
                                                    autocompleteMinimumCharacters={3}
                                                    autocompleteSuggestions={true}
                                                    debounceLength={0}
                                                />
                                            </div>
                                            <div style={{ flex: '1 1 auto' }}>
                                                <Layout
                                                    sideContent={

                                                        <div>

                                                            <div style={{ marginBottom: '20px' }}>
                                                                <Facet
                                                                    field="Attributes.endpoint.keyword"
                                                                    label="Endpoints"
                                                                    filterType="any"
                                                                    isFilterable={true}
                                                                    view={CustomFacetView}
                                                                />
                                                            </div>

                                                            <div style={{ marginBottom: '20px' }}>
                                                                {wasSearched && (
                                                                    <Sorting label={"Sort by Time"} sortOptions={SORT_OPTIONS} />
                                                                )}
                                                            </div>

                                                            <div>

                                                                <Facet
                                                                    field="Attributes.status_code"
                                                                    label="Status Code"
                                                                    filterType="any"
                                                                    isFilterable={true}
                                                                />
                                                            </div>




                                                            {/* <Facet
                                  field="Attributes.endpoint.keyword"
                                  label="33"
                                  filterType="any"
                                  isFilterable={true}
                                /> */}






                                                        </div>
                                                    }
                                                    bodyContent={
                                                        <Results
                                                            titleField="message"
                                                            resultView={CustomResultView}
                                                            shouldTrackClickThrough={true}
                                                        />
                                                    }
                                                    bodyHeader={
                                                        <React.Fragment>
                                                            {wasSearched && <PagingInfo />}
                                                            {wasSearched && <ResultsPerPage />}
                                                        </React.Fragment>
                                                    }
                                                    bodyFooter={<Paging />}
                                                />
                                            </div>
                                        </ErrorBoundary>
                                    </div>
                                );
                            }}
                        </WithSearch>
                    </SearchProvider>
                </Box>
            </Box >
        </Box >)
}
export default ApiLogs;