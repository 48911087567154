import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Autocomplete, Box, Button, FormControl, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { getComponentsStyles } from '../../../styles/componentsStyles';

import { getStyles } from './Styles'
import { fontStyles } from '../../../styles/fontStyles'
import { buttonStyles } from '../../../styles/buttonStyles';
import { tostAlert } from '../../../utils/AlertToast';
import LoadBar from '../../../utils/LoadBar';

import getAmeyaSettingsData from '../../../utils/ApiFunctions/AmeyaSettingsData';
import AmeyaSettingsApi from '../../../Api/Services/AppflyteBackend/AmeyaSettings';
import { getUserName } from '../../../utils/GetAccountDetails';
import { getSessionData } from '../../../utils/sessionDataHandle';

function LLMSettings() {
    const theme = useTheme();
    const styles = getStyles(theme);
    const componentsStyles = getComponentsStyles(theme);

    const [initialLoading, setInitialLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [isExistingData, setIsExistingData] = useState(false);
    const settingsDetails = { item_id: null, update_key: null };
    const [formErrors, setFormErrors] = useState({});
    const [settingsData, setSettingsData] = useState(settingsDetails);
    const [realmAttributes, setRealmAttributes] = useState([]);
    const [llmSettings, setLLMSettings] = useState({ api_keys: "", llm_model: null })

    const selected_space = getSessionData("selected_space")
    const selected_project = getSessionData("selected_project")

    useEffect(() => {
        fetchSettingsData();
        //eslint-disable-next-line
    }, []);

    const fetchSettingsData = async () => {
        setInitialLoading(true);
        try {
            const response = await getAmeyaSettingsData(selected_project?.payload?.__auto_id__);
            if (response?.length > 0) {
                const responseData = response?.[0] ?? [];
                const realm_settings = responseData?.payload?.settings?.realm_attributes ?? [];
                const llm_settings = responseData?.payload?.settings?.llm_settings ?? {};
                if (realm_settings?.length > 0 || llm_settings) {
                    setRealmAttributes(realm_settings || []);
                    setLLMSettings({
                        ...llmSettings,
                        api_keys: typeof llm_settings?.api_keys === "string" ? llm_settings.api_keys : "",
                        llm_model: typeof llm_settings?.llm_model === "string" ? llm_settings.llm_model : ""
                    });
                    setSettingsData({ ...settingsData, item_id: responseData?.payload.__auto_id__, update_key: responseData?.update_key });
                    setIsExistingData(true);
                }
            }
        } catch (error) {
            console.error('Error fetching settings data:', error);
        } finally {
            setInitialLoading(false);
        }
    };

    const handleLLMModelSelection = (event, value) => {
        setLLMSettings({ ...llmSettings, llm_model: value?.name || null });
    };

    useEffect(() => {
        const errors = { ...formErrors };
        if (formErrors?.api_keys && llmSettings?.api_keys?.trim()) { delete errors?.api_keys }
        if (formErrors?.llm_model && llmSettings?.llm_model) { delete errors?.llm_model }
        if (JSON.stringify(errors) !== JSON.stringify(formErrors)) { setFormErrors(errors) }
    }, [llmSettings, formErrors]);

    const saveSettings = async () => {
        setLoading(true);
        try {
            const errors = {};
            if (!llmSettings?.api_keys?.trim()) { errors.api_keys = 'Api Key is required' }
            if (!llmSettings?.llm_model) { errors.llm_model = 'LLm Model is required' }
            if (Object.keys(errors).length === 0) {
                const userName = await getUserName();
                const settingsObject = {
                    collection_item: {
                        workspace_id: selected_space?.payload?.__auto_id__,
                        project_id: selected_project?.payload?.__auto_id__,
                        created_by: userName,
                        created_on: moment().format("DD-MM-YYYY HH:mm:ss"),
                        settings: {
                            "realm_attributes": realmAttributes,
                            "llm_settings": {
                                "llm_model": llmSettings.llm_model,
                                "api_keys": llmSettings.api_keys
                            }
                        }
                    }
                };

                let response;
                if (isExistingData && settingsData.item_id && settingsData.update_key) {
                    const dataObject = {
                        id: settingsData.item_id,
                        fields: [
                            {
                                path: '$.settings',
                                value: {
                                    "realm_attributes": realmAttributes,
                                    "llm_settings": {
                                        "llm_model": llmSettings.llm_model,
                                        "api_keys": llmSettings.api_keys
                                    }
                                }
                            }
                        ]
                    };
                    response = await AmeyaSettingsApi.updateSettings(JSON.stringify(dataObject), settingsData.item_id, settingsData.update_key);
                } else {
                    response = await AmeyaSettingsApi.addSettings(JSON.stringify(settingsObject));
                }

                if (response.status === 200) {
                    tostAlert('LLm Configuration Successfull', 'success');
                    fetchSettingsData();
                } else {
                    tostAlert('LLM Configuration Failed', 'error');
                }
            }
            else {
                setFormErrors(errors);
            }
        } catch (error) {
            tostAlert(error?.message, 'error');
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const LLM_DATA = [
        { 'name': 'openai' },
    ]
    return (
        <Box sx={styles.mainContainer}>
            <Box sx={styles.cardContainer}>
                {(loading || initialLoading) && <LoadBar />}
                <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                    <Typography sx={styles.mainHeadingText}>LLM</Typography>

                    <Box display={'flex'} alignItems={'center'}>
                        <Button
                            sx={{ ...buttonStyles.primaryBtn, width: '100px' }}
                            disabled={loading || initialLoading}
                            onClick={saveSettings}
                        >
                            <Typography sx={styles.btnText}>SAVE</Typography>
                        </Button>
                        <Button sx={{ ...buttonStyles.secondaryBtn, width: '100px', marginLeft: '10px' }}>
                            <Typography sx={styles.btnText}>CANCEL</Typography>
                        </Button>
                    </Box>

                </Box>

                <Typography sx={{ ...styles.paraText, marginTop: '10px' }}>
                    Select LLM model to power your app
                </Typography>

                <Box marginTop={'20px'}>
                    <FormControl>
                        <Typography sx={{ ...styles.paraText, marginBottom: '6px' }}>Select LLM Model</Typography>
                        <Autocomplete
                            sx={{ ...componentsStyles.autocomplete, width: '300px' }}
                            options={LLM_DATA}
                            getOptionLabel={(option) => option?.name || ""}
                            id="clear-on-escape"
                            clearOnEscape
                            value={LLM_DATA.find((option) => option.name === llmSettings.llm_model) || null}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" placeholder="LLM Model" />
                            )}
                            onChange={handleLLMModelSelection}
                        />
                        {formErrors.llm_model && <Typography sx={{ ...styles.paraText, color: 'red' }}>{formErrors.llm_model}</Typography>}
                    </FormControl>
                </Box>

                <Box marginTop={'20px'}>
                    <FormControl>
                        <Typography sx={{ ...styles.paraText, marginBottom: '6px' }}>API Key</Typography>
                        <TextField
                            id="api-key"
                            variant="outlined"
                            placeholder='API Key'
                            size='medium'
                            sx={{ ...componentsStyles.textField, width: '600px' }}
                            name="api_key"
                            value={llmSettings.api_keys}
                            onChange={(e) => setLLMSettings({ ...llmSettings, api_keys: e.target.value })}
                        />
                        {formErrors.api_keys && <Typography sx={{ ...styles.paraText, color: 'red' }}>{formErrors.api_keys}</Typography>}
                    </FormControl>
                </Box>
            </Box>
        </Box>
    )
}

export default LLMSettings;