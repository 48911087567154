import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { getStyles } from './styles';
import { IconSvg } from '../../../utils/globalIcons';
import { ReactSVG } from 'react-svg';

function SourceType({ setSourceSelected, setSelectedSource }) {

    const theme = useTheme();
    const styles = getStyles(theme);
    const [dataSourceType, setDataSourceType] = useState('');

    const handleDataSourceType = (item) => {
        setSelectedSource(item)
        setDataSourceType(item)
        setSourceSelected(true)
    }

    return (
        <div style={styles.mainContainer}>
            <Box sx={styles.cardContainer}>

                <Typography sx={styles.mainHeadingText}>Select Datasource</Typography>
                <Typography sx={{ ...styles.paraText, marginTop: '5px' }}>
                    Select a datasource to continue
                </Typography>

                <Box sx={styles.sourceListMainContainer}>
                    {[
                        { type: 'files', icon: IconSvg.fileSourceIcon, label: 'Files' },
                        { type: 'database', icon: IconSvg.databaseIcon, label: 'Database' },
                        // { type: 'gdrive', icon: IconSvg.gDriveIcon, label: 'Google Drive' },
                    ].map((ds) => (
                        <Box
                            key={ds.type}
                            sx={styles.sourceListContainer(dataSourceType, ds.type)}
                            onClick={() => handleDataSourceType(ds.type)}
                        >
                            <ReactSVG src={ds.icon} />
                            <Typography sx={styles.paraText}>{ds.label}</Typography>
                        </Box>
                    ))}
                </Box>

            </Box>
        </div >

    )
}

export default SourceType;