import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useTheme } from '@mui/material/styles';
import { getStyles } from './Styles';

import { Box, Button, Card, CardContent, Grid2 as Grid, Typography } from '@mui/material';
import { buttonStyles } from '../../styles/buttonStyles';
import { getMainStyles } from '../../styles/styles';

import { setSpacesState } from "../../Redux/slice/dataSlice";

import LoadBar from '../../utils/LoadBar';
import getSpaceData from '../../utils/ApiFunctions/SpaceData';
import { setWorkspaceAddedAdded } from '../../Redux/slice/newDataSlice';
import { storeSessionData } from '../../utils/sessionDataHandle';
import { setSpaceChanged } from '../../Redux/slice/currentSelectedSlice';
import { fontStyles } from '../../styles/fontStyles';

function Spaces() {
    const theme = useTheme();
    const styles = getStyles(theme);
    const mainStyles = getMainStyles(theme)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const all_spaces = useSelector(state => state.all_data.spaces)
    const workspace_added = useSelector(state => state.data_added.workspace_added)

    const [spaces, setSpaces] = useState([])
    const [spaceLoading, setSpaceLoading] = useState(false);

    useEffect(() => {
        getSpaceDetails()
        //eslint-disable-next-line
    }, [])

    const getSpaceDetails = async () => {
        setSpaceLoading(true)
        try {
            if (all_spaces?.length > 0 && workspace_added === false) {
                setSpaces(all_spaces)
                return
            }
            const response = await getSpaceData()
            dispatch(setSpacesState(response))
            dispatch(setWorkspaceAddedAdded(false))
            const responseData = response?.length > 0 ? response : []
            setSpaces(responseData)
        } catch (error) {
            console.error(error);
        } finally {
            setSpaceLoading(false)
        }
    }

    const handleSpaceSelection = async (item) => {
        storeSessionData("selected_space", item)
        dispatch(setSpaceChanged(true))
        const current_space_id = item?.payload?.__auto_id__ ?? null
        navigate(`/spaces/${current_space_id}/projects`)
    }

    const handleSpaceCreation = async () => {
        navigate(`/spaces/add`)
    }

    return (
        <div style={styles.mainContainer}>
            <Box sx={{ ...styles.cardContainer }}>
                {spaceLoading && <LoadBar />}
                <Box padding={'20px'}>

                    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                        <Typography sx={styles.mainHeadingText}>All Spaces</Typography>
                        <Button
                            sx={{ ...buttonStyles.primaryBtn, width: '110px' }}
                            onClick={() => handleSpaceCreation()}
                        >
                            <Typography sx={styles.btnText}>New Space</Typography>
                        </Button>
                    </Box>

                    <Box marginTop={'50px'}>
                        <Grid container spacing={6}>
                            {spaces && spaces?.length > 0 ? spaces?.map((space, index) => (
                                <Grid size={{ xs: 6, md: 3 }} key={index} >
                                    <Card sx={styles.card}>
                                        <CardContent sx={{ padding: '0' }} onClick={() => handleSpaceSelection(space)}>
                                            <Box sx={styles.cardHeader}>
                                                <Typography sx={styles.cardHeadingText}>{space?.payload?.name}</Typography>
                                            </Box>
                                            <Box sx={styles.cardBody}>
                                                <Typography sx={styles.cardDescriptionText}>{space?.payload?.description}</Typography>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            )) : (spaces?.length === 0 && !spaceLoading) && (
                                <Box sx={mainStyles.noRecord}>
                                    <Typography sx={styles.noRecordsText}>No Records To Display</Typography>
                                </Box>
                            )}
                        </Grid>
                    </Box>

                </Box>
            </Box>
        </div>
    )
}

export default Spaces;