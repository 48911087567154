import React, { useState, } from 'react';
import { List, ListItem, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { getStyles } from "../../styles/subsidebar/styles";
import '../../styles/subsidebar/styles.css';

import { IconSvg } from '../../utils/globalIcons';
import { ReactSVG } from "react-svg";
import { getSessionData } from '../../utils/sessionDataHandle';

function LogsSidebar({ handleMenuChange }) {
    const theme = useTheme();
    const styles = getStyles(theme);
    const [selectedMenuItem, setSelectedMenuItem] = useState(0);

    const menuItems = [
        { icon: IconSvg.llmIcon, label: 'Agent Logs', path: 'agents-logs' },
        { icon: IconSvg.apiIcon, label: 'API Logs', path: 'api-logs' }
    ];

    const filteredMenuItems = () => {
        let items = []
        const selected_space = getSessionData("selected_space")
        const selected_project = getSessionData("selected_project")
        if (selected_space && selected_project) {
            items.push(...menuItems);
        }
        return items;
    };

    const handleMenuItemClick = (index, path) => {
        setSelectedMenuItem(index);
        handleMenuChange(path)
    };

    return (
        <nav style={styles.sidebar}>
            <List>
                {filteredMenuItems()?.map((item, index) => (
                    <ListItem
                        key={index}
                        sx={styles.siebarItem(selectedMenuItem, index)}
                        onClick={() => handleMenuItemClick(index, item.path)}
                    >
                        <ReactSVG style={styles.siebarIcon} src={item.icon} className='sidebar_icon' />
                        <Typography sx={styles.siebarLabelText}>{item.label}</Typography>
                    </ListItem>
                ))}
            </List>
        </nav >
    )
}

export default LogsSidebar;