export const getStyles = (theme) => ({
    mainContainer: {
        width: "100%",
        height: '100vh',
    },
    cardContainer: {
        padding: '20px 20px 0px 20px'
    },
    fileUploadContainer: {
        padding: '20px',
        textAlign: 'center',
        cursor: 'pointer',
        backgroundColor: '#F3F5F7',
        borderRadius: '20px',
    },
    uploadLoadingBox: {
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 2100,
        display: 'flex',
        flexDirection: 'column',
        width: '600px',
        minHeight: '120px',
        boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
        backgroundColor: 'white'
    },
    uploadingHeaderBox: {
        display: 'flex',
        height: '40px',
        paddingLeft: '15px',
        paddingRight: '15px',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#0B51C5'
    },
    uploadingText: {
        fontSize: 15,
        fontWeight: 400,
        color: '#FFFFFF',
    },
    saveBtn: {
        width: '100px',
        borderRadius: '20px',
        border: 'none',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#0B51C5',
        color: '#ffffff'
    },
    mainHeadingText: {
        fontSize: theme.typography.h4,
        fontWeight: 600
    },
    paraText: {
        fontSize: theme.typography.h6,
        fontWeight: 400,
    },
    btnText: {
        fontSize: theme.typography.h6,
        fontWeight: 600,
        textTransform: 'none'
    },
    fileDropLabelText: {
        fontSize: theme.typography.h6,
        fontWeight: 600,
    },
    realmHeadingText: {
        fontSize: theme.typography.h6,
        fontWeight: 600,
    },
    failedIcon: {
        height: '18px',
        width: '18px',
        marginRight: '15px',
        color: 'red'
    },
})