import AuditToolApi from "../../Api/Services/AmeyaBackend/AuditToolApi";

const AuditTestHistoryData = async (request_from) => {

    const getAllTestHistory = async (request_from) => {
        const tempArr = [];
        const page_size = 10;
        let current_page = 1;
        let total_records = 0;
        try {
            do {
                const response = await AuditToolApi.getTrainHistoryByTemplate(page_size, current_page, request_from);
                const response_data = response?.data || {};
                total_records = response_data?.no_of_records || 0;
                const response_results = response_data?.results ?? []
                if (response_results.length > 0) {
                    tempArr.push(...response_results);
                } else {
                    break;
                }
                current_page++;
            } while (tempArr?.length < total_records);
            return tempArr;
        }
        catch (error) {
            console.error("Error fetching PDF source files:", error);
            return tempArr;
        }
    }

    return getAllTestHistory(request_from);
};

export default AuditTestHistoryData;
