import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { handleCheckLoginToken } from "../Api/Services/AppflyteAuth/refreshToken";

const useAuthCheck = () => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.pathname !== "/authorized") {
            handleCheckLoginToken(navigate);
        }
        //eslint-disable-next-line
    }, [location.pathname]);

    useEffect(() => {
        const interval = setInterval(() => {
            handleCheckLoginToken(navigate);
        }, 120000);

        return () => clearInterval(interval);
        //eslint-disable-next-line
    }, []);
};

export default useAuthCheck;