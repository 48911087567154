import axios from "axios";
import agentApiTokenManager from "../../utils/AgentApiToken/getAgentAdminToken";
import { getSessionData } from "../../utils/sessionDataHandle";

const getAgentApiToken = async () => {
    const currentProject = getSessionData("selected_project")
    if (currentProject) {
        const token = await agentApiTokenManager.getAgentAdminToken();
        return token ?? null
    }
    return null
}

const requestHandler = async (request) => {
    const agentTokenId = await getAgentApiToken()
    request.headers['Authorization'] = `Bearer ${agentTokenId}`;
    request.headers['Content-Type'] = 'application/json';
    request.headers['Accept'] = 'application/json';
    return request
}

const AxiosAuditToolObj = axios.create({
    baseURL: window.env.REACT_APP_QMS_AUDIT_TOOL_URL || process.env.REACT_APP_QMS_AUDIT_TOOL_URL,
    responseType: "json"
});

const AxiosDocSearchObj = axios.create({
    baseURL: window.env.REACT_APP_DOCUMENT_SEARCH_URL || process.env.REACT_APP_DOCUMENT_SEARCH_URL,
    responseType: "json"
});

const AxiosLLMDocSearchObj = axios.create({
    baseURL: window.env.REACT_APP_LLM_DOCUMENT_SEARCH_URL || process.env.REACT_APP_LLM_DOCUMENT_SEARCH_URL,
    responseType: "json"
});

const AxiosAnalyticsObj = axios.create({
    baseURL: window.env.REACT_APP_ANALYTICS_BASE_URL || process.env.REACT_APP_ANALYTICS_BASE_URL,
    responseType: "json"
});

AxiosAuditToolObj.interceptors.request.use(request => requestHandler(request))
AxiosDocSearchObj.interceptors.request.use(request => requestHandler(request))
AxiosLLMDocSearchObj.interceptors.request.use(request => requestHandler(request))
AxiosAnalyticsObj.interceptors.request.use(request => requestHandler(request))

export { AxiosAuditToolObj, AxiosDocSearchObj, AxiosLLMDocSearchObj, AxiosAnalyticsObj };

