import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import moment from 'moment';

import { Box, Button, Typography } from '@mui/material';
import { Styles } from './Styles';
import { fontStyles } from '../../../styles/fontStyles';
import { buttonStyles } from '../../../styles/buttonStyles';

import { setAuditTestHistoryState } from "../../../Redux/slice/dataSlice";
import { setAuditTestHistoryAdded } from "../../../Redux/slice/newDataSlice";
import AuditTestHistoryData from '../../../utils/ApiFunctions/AuditTestHistory';
import { tostAlert } from '../../../utils/AlertToast';
import AuditToolApi from '../../../Api/Services/AmeyaBackend/AuditToolApi';
import LoadBar from '../../../utils/LoadBar';
import ChatHandler from './AuditToolChat/ChatHandler';
import GeneralTable from '../../../components/GeneralTable';

function AuditToolPlaygroundHistory() {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [testSessionHistory, setTestSessionHistory] = useState([])

    // Redux States
    const audit_test_history = useSelector(state => state.all_data.audit_test_history)
    const audit_test_history_added = useSelector(state => state.data_added.audit_test_history_added)

    //Retrain Data
    const chatType = 'Retrain'
    const [chatMessageLoading, setChatMessageLoading] = useState(false);
    const [chatdrawer, setChatDrawer] = useState({ state: false });

    const [qmsTestId, setQmsTestId] = useState(null);
    const initialQmsChat = { chats: [] }
    const [qmsChatData, setQmsChatData] = useState(initialQmsChat)
    const [renderableItems, setRenderableItems] = useState(initialQmsChat)
    const [userPrompt, setUserPrompt] = useState('')

    useEffect(() => {
        getAuditToolTestHistory()
        //eslint-disable-next-line
    }, [audit_test_history_added])

    const getAuditToolTestHistory = async () => {
        setLoading(true)
        try {
            if (audit_test_history?.length > 0 && audit_test_history_added === false) {
                setTestSessionHistory(audit_test_history)
                return
            } else {
                const response = await AuditTestHistoryData('ui');
                const transformedData = response
                    .flatMap(item =>
                        (item?.documents || []).map(doc => ({
                            test_id: doc.test_id ?? null,
                            temp_layout_id: doc?.temp_layout_id ?? null,
                            pdf_name: doc?.result_data?.[0]?.pdf_name ?? '',
                            json_name: doc?.result_data?.[0]?.json_name ?? '',
                            tested_on: doc?.result_data?.[0]?.tested_on ?? '',
                        }))
                    )
                    .sort((a, b) => (a.temp_layout_id ?? "").localeCompare(b.temp_layout_id ?? ""))
                    .map((item, index, arr) => ({
                        ...item,
                        is_first: index === 0 || item.temp_layout_id !== arr[index - 1].temp_layout_id
                    }));
                setTestSessionHistory(transformedData)
                dispatch(setAuditTestHistoryState(transformedData))
                dispatch(setAuditTestHistoryAdded(false))
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const columns = [
        {
            id: 'tested_on',
            label: 'Date',
            width: '20%',
            sortable: true,
            renderCell: (row) => moment(row?.tested_on)?.format('DD-MMM-YYYY hh:mm A')
        },
        {
            id: 'pdf_name',
            label: 'Report',
            width: '30%',
            sortable: true,
            renderCell: (row) => row?.pdf_name
        },
        {
            id: 'json_name',
            label: 'Specifications',
            width: '30%',
            sortable: true,
            renderCell: (row) => row?.json_name
        },
        {
            id: '',
            label: '',
            width: '20%',
            sortable: false,
            renderCell: (row) => row?.is_first && (<Button
                sx={{ ...buttonStyles.tertiaryBtn, width: '80px', marginLeft: '10px' }}
            >
                <Typography
                    sx={{ ...fontStyles.btnSmallText, fontWeight: 'bold' }}
                    onClick={() => handleRetrain(row)}
                >RETRAIN</Typography>
            </Button>)
        }
    ];

    // Handle Retrain
    const toggleDrawer = (open) => (event) => {
        setChatDrawer({ ...chatdrawer, state: open });
    };

    const handleClearChatInfo = async (chatStart, errorMessage) => {
        if (!chatStart) {
            tostAlert(errorMessage, 'error')
        }
        setChatDrawer({ ...chatdrawer, state: false });
        setQmsChatData(initialQmsChat);
        setRenderableItems(initialQmsChat)
        dispatch(setAuditTestHistoryAdded(true))
        setQmsTestId(null)
        setUserPrompt('')
    }

    const createChatFlow = async (item) => {
        return [
            {
                sender: 'system',
                testId: qmsTestId,
                questionId: item?.question_id,
                chat: item?.text,
                isProcessed: false,
                feedBackOptions: item?.feedback_options,
            },
            {
                sender: 'user',
                testId: qmsTestId,
                questionId: item?.question_id,
                chat: '',
                isProcessed: false,
                feedBackOptions: item?.feedback_options,
            }
        ];
    }

    const getChatQuestions = async (responseData) => {
        try {
            const system_message_string = responseData?.system_message?.trim()?.replace(/someUnexpectedChar/g, '');
            const system_message = system_message_string ? JSON.parse(system_message_string) : null;
            return Array.isArray(system_message?.questions) ? system_message.questions : [];
        } catch (error) {
            return [];
        }
    };

    const handleRetrain = async (item) => {
        setLoading(true)
        try {
            if (item?.test_id) {

                await handleClearChatInfo(true)
                const testId = item?.test_id
                setQmsTestId(testId)

                const reqObj = {
                    "test_id": testId,
                    "retrain": true
                }
                const response = await AuditToolApi.retrainQmsModel(JSON.stringify(reqObj))

                if (response.status === 200) {
                    const responseData = response?.data
                    const canvasData = responseData?.convos ?? []
                    const feedback_options = responseData?.feedback_options ?? []

                    if (canvasData?.length > 0 && feedback_options?.length > 0) {

                        const promises = canvasData.map(async (item) => {

                            let chatFlow = []

                            const systemChat = {
                                sender: 'system',
                                testId: testId,
                                questionId: uuid(),
                                chat: item?.system,
                                isProcessed: true,
                                feedBackOptions: item?.feedback_options
                            }
                            chatFlow.push(systemChat);

                            const userChat = {
                                sender: 'user',
                                testId: testId,
                                questionId: uuid(),
                                chat: item?.user,
                                isProcessed: true,
                                feedBackOptions: item?.feedback_options
                            }
                            chatFlow.push(userChat);

                            return chatFlow;
                        });
                        const chatFlows = await Promise.all(promises);
                        const allChatFlows = chatFlows.flat();

                        const newChat = {
                            train_chat_data: allChatFlows,
                            train_response: [],
                            train_feedback_thumbs_options: [],
                            train_feedback_chat_options: feedback_options,
                            train_continue_options: [],
                            train_user_Prompt: {
                                user_prompt_status: 'inactive',
                                user_prompt_message: ''
                            },
                            pagination: {
                                page: 1,
                                rowsPerPage: 20,
                                totalPages: 1,
                            },
                        };

                        setQmsChatData({ chats: [newChat] })
                        setChatDrawer({ ...chatdrawer, state: true });
                    } else {
                        await handleClearChatInfo(false, canvasData.error || 'Retrain Failed')
                    }
                } else {
                    await handleClearChatInfo(false, response.data?.error || 'Retrain Failed')
                }
            } else {
                await handleClearChatInfo(false, "Something went wrong, please try again")
            }
        } catch (error) {
            console.log(error)
            await handleClearChatInfo(false, error.message || "An unexpected error occurred")
        } finally {
            setLoading(false)
        }
    }

    const handleQmsChatData = async (chat_index, questionId, chat_feedback_type) => {
        const updatedQmsChatData = qmsChatData?.chats.map((chat, index) => {
            if (index === chat_index) {
                return {
                    ...chat,
                    train_chat_data: chat.train_chat_data.map((item) => {
                        if (item?.questionId === questionId) {
                            if (item?.sender === 'system') {
                                return { ...item, isProcessed: true };
                            }
                            if (item?.sender === 'user') {
                                return { ...item, isProcessed: true, chat: chat_feedback_type };
                            }
                        }
                        return item;
                    }),
                };
            }
            return chat;
        });
        return { chats: updatedQmsChatData };
    };

    const handleQmsChat = async (chat_index, questionId, chat_feedback_type) => {
        setChatMessageLoading(true)
        try {
            if (qmsChatData?.chats?.length > 0) {
                const updatedQmsChatData = await handleQmsChatData(chat_index, questionId, chat_feedback_type)
                setQmsChatData(updatedQmsChatData);

                const filterdUpdatedQmsChatData = updatedQmsChatData?.chats?.[chat_index]?.train_chat_data?.filter((item) => !item?.isProcessed)
                if (filterdUpdatedQmsChatData?.length === 0) {

                    const canReqObjs = [];

                    for (let i = 0; i < updatedQmsChatData?.chats?.[chat_index]?.train_chat_data?.length; i += 2) {
                        const canReqObj = {};

                        const currentItem = updatedQmsChatData?.chats?.[chat_index]?.train_chat_data?.[i];
                        const nextItem = updatedQmsChatData?.chats?.[chat_index]?.train_chat_data?.[i + 1];

                        if (currentItem?.sender === 'system') {
                            canReqObj.system = currentItem.chat;
                        }

                        if (nextItem?.sender === 'user') {
                            canReqObj.user = nextItem.chat;
                        }

                        if (canReqObj.system && canReqObj.user) {
                            canReqObjs.push(canReqObj);
                        }
                    }

                    const reqObj = {
                        "test_id": qmsTestId,
                        "chats": canReqObjs,
                        "retrain": true
                    }

                    setLoading(true)

                    const response = await AuditToolApi.qmsUserChatMessage(reqObj);
                    if (response.status === 200) {

                        const responseData = response?.data
                        const testOutput = responseData?.results?.output ?? []
                        const feedbackThumbsOptions = responseData?.results?.feedback_options ?? [];

                        if (testOutput?.length === 0 || feedbackThumbsOptions?.length === 0) {
                            await handleClearChatInfo(false, responseData?.error ?? 'Retrain Failed')
                        } else {
                            setQmsChatData((prevState) => ({
                                ...prevState,
                                chats: prevState.chats.map((chat, idx) => idx === chat_index ? {
                                    ...chat,
                                    train_response: [...(chat.train_response || []), ...testOutput],
                                    train_feedback_thumbs_options: [...(chat.train_feedback_thumbs_options || []), ...feedbackThumbsOptions],
                                    pagination: {
                                        ...chat?.pagination,
                                        totalPages: Math.ceil((chat?.train_response?.length || 0) / chat?.pagination?.rowsPerPage),
                                        page: chat?.pagination?.page <= Math.ceil((chat?.train_response?.length || 0) / chat?.pagination?.rowsPerPage) ? chat?.pagination?.page : 1,
                                    }
                                } : chat)
                            }));
                        }
                    } else {
                        await handleClearChatInfo(false, response?.data?.error || "An unexpected error occurred")
                    }
                }
            } else {
                await handleClearChatInfo(false, 'Retrain Failed')
            }
        } catch (error) {
            await handleClearChatInfo(false, error.message || "An unexpected error occurred")
            console.log(error)
        } finally {
            setLoading(false)
            setChatMessageLoading(false)
        }
    };

    const handleUserThumbsFeedback = async (chat_index, feed_back_type) => {
        setChatMessageLoading(true)

        if (feed_back_type === "thumbs_up") {

            const canReqObjs = [];
            for (let i = 0; i < qmsChatData?.chats?.[chat_index]?.train_chat_data?.length; i += 2) {
                const canReqObj = {};

                const currentItem = qmsChatData?.chats?.[chat_index]?.train_chat_data?.[i];
                const nextItem = qmsChatData?.chats?.[chat_index]?.train_chat_data?.[i + 1];

                if (currentItem?.sender === 'system') {
                    canReqObj.system = currentItem.chat;
                }

                if (nextItem?.sender === 'user') {
                    canReqObj.user = nextItem.chat;
                }

                if (canReqObj.system && canReqObj.user) {
                    canReqObjs.push(canReqObj);
                }
            }

            const reqObj = {
                "test_id": qmsTestId,
                "thumbs_ups": feed_back_type,
                "chats": canReqObjs
            }
            const thumbsUpResponse = await AuditToolApi.retrainQmsModel(JSON.stringify(reqObj));
            if (thumbsUpResponse.status === 200) {
                tostAlert('Retrain Completed Successfully', 'success')
                await handleClearChatInfo(true);
                return
            } else {
                await handleClearChatInfo(false, 'Retrain Failed')
                return
            }
        }

        setQmsChatData((prevState) => ({
            ...prevState,
            chats: prevState.chats.map((chat, idx) => idx === chat_index ? {
                ...chat,
                train_feedback_thumbs_options: [feed_back_type],
            } : chat)
        }));

        try {
            const reqObj = {
                "test_id": qmsTestId,
                "thumbs_ups": feed_back_type,
                "retrain": true
            }
            const response = await AuditToolApi.retrainQmsModel(JSON.stringify(reqObj))
            if (response.status === 200) {

                const responseData = response?.data
                const feedbackChatsOptions = responseData?.results?.feedback_options ?? [];

                if (feedbackChatsOptions?.length === 0) {
                    await handleClearChatInfo(false, responseData?.error || 'Retrain Failed')
                } else {
                    setQmsChatData((prevState) => ({
                        ...prevState,
                        chats: prevState.chats.map((chat, idx) => idx === chat_index ? {
                            ...chat,
                            train_feedback_chat_options: [...(chat.train_feedback_chat_options || []), ...feedbackChatsOptions],
                            train_feedback_thumbs_options: [],
                        } : chat)
                    }));
                }
            } else {
                await handleClearChatInfo(false, response?.data?.error || "An unexpected error occurred")
            }
        } catch (error) {
            await handleClearChatInfo(false, error.message || "An unexpected error occurred")
            console.log(error)
        } finally {
            setChatMessageLoading(false)
        }
    }

    const handleUserChatFeedback = async (chat_index, feed_back_type) => {
        setChatMessageLoading(true)
        try {

            setQmsChatData((prevState) => ({
                ...prevState,
                chats: prevState.chats.map((chat, idx) => idx === chat_index ? {
                    ...chat,
                    train_feedback_chat_options: [feed_back_type],
                } : chat)
            }));

            if (feed_back_type === "start_fresh") {

                const reqObj = {
                    "test_id": qmsTestId,
                    "user_action": feed_back_type,
                    "retrain": true
                }

                const response = await AuditToolApi.retrainQmsModel(JSON.stringify(reqObj))
                if (response.status === 200) {
                    const responseData = response?.data
                    const questions_data = await getChatQuestions(responseData)

                    if (questions_data?.length > 0) {
                        const updatedQuestionsData = questions_data?.map((item) => ({ ...item, question_id: uuid(), is_processed: false }));
                        const promises = updatedQuestionsData?.map(async (item) => await createChatFlow(item));
                        const chatFlows = await Promise.all(promises);
                        const allChatFlows = chatFlows.flat();

                        const newChat = {
                            train_chat_data: allChatFlows,
                            train_response: [],
                            train_feedback_thumbs_options: [],
                            train_feedback_chat_options: [],
                            train_continue_options: [],
                            train_user_Prompt: {
                                user_prompt_status: 'inactive',
                                user_prompt_message: ''
                            },
                            pagination: {
                                page: 1,
                                rowsPerPage: 20,
                                totalPages: 1,
                            },
                        };

                        setQmsChatData((prevState) => ({
                            chats: [
                                ...prevState.chats.map((chat) => ({
                                    ...chat,
                                    train_continue_options: [],
                                    train_feedback_chat_options: []
                                })),
                                newChat
                            ]
                        }));
                        return
                    } else {
                        await handleClearChatInfo(false, responseData.error || 'Retrain Failed')
                    }
                } else {
                    await handleClearChatInfo(false, response.data?.error || 'Retrain Failed')
                }
            }
            else {
                await handleClearChatInfo(true)
                dispatch(setAuditTestHistoryAdded(true))
                tostAlert('Retrain Completed Successfully', 'success')
                return
            }

            // if (feed_back_type === "continue") {
            //     setQmsChatData((prevState) => ({
            //         ...prevState,
            //         chats: prevState.chats.map((chat, idx) => idx === chat_index ? {
            //             ...chat,
            //             train_feedback_chat_options: [],
            //             train_continue_options: ['retake', 'reassess'],
            //         } : chat)
            //     }));
            //     return
            // }

            // const reqObj = {
            //     "test_id": qmsTestId,
            //     "user_action": feed_back_type
            // }

            // const response = await AuditToolApi.retrainQmsModel(reqObj);
            // if (response.status === 200) {
            //     const responseData = response?.data

            //     if (feed_back_type === "start_fresh") {
            //         const testOutput = responseData?.results?.output ?? []
            //         const feedbackThumbsOptions = responseData?.results?.feedback_options ?? [];

            //         if (testOutput?.length === 0 || feedbackThumbsOptions?.length === 0) {
            //             await handleClearChatInfo(false, responseData.error || 'Training Failed')
            //         } else {
            //             setQmsChatData((prevState) => ({
            //                 ...prevState,
            //                 chats: prevState.chats.map((chat, idx) => idx === chat_index ? {
            //                     ...chat,
            //                     train_response: [...testOutput],
            //                     train_feedback_thumbs_options: [...feedbackThumbsOptions],
            //                     train_feedback_chat_options: []
            //                 } : chat)
            //             }));
            //         }
            //     }
            // } else {
            //     await handleClearChatInfo(false, response.data?.error || 'Training Failed')
            // }

        } catch (error) {
            await handleClearChatInfo(false, error.message || "An unexpected error occurred")
            console.log(error)
        } finally {
            setChatMessageLoading(false)
        }
    }

    const handleUserContinueOptions = async (chat_index, feed_back_type) => {
        setChatMessageLoading(true)
        try {

            setQmsChatData((prevState) => ({
                ...prevState,
                chats: prevState.chats.map((chat, idx) => idx === chat_index ? {
                    ...chat,
                    train_continue_options: [feed_back_type],
                } : chat)
            }));

            if (feed_back_type === "reassess") {
                setQmsChatData((prevState) => ({
                    ...prevState,
                    chats: prevState.chats.map((chat, idx) => idx === chat_index ? {
                        ...chat,
                        train_continue_options: [],
                        train_user_Prompt: {
                            user_prompt_status: 'active',
                            user_prompt_message: ''
                        }
                    } : chat)
                }));
                return
            }

            if (feed_back_type === "retake") {

                const reqObj = {
                    "test_id": qmsTestId,
                    "user_action": 'continue',
                    "continue_options": feed_back_type
                }

                const response = await AuditToolApi.retrainQmsModel(JSON.stringify(reqObj))
                if (response.status === 200) {
                    const responseData = response?.data
                    const questions_data = await getChatQuestions(responseData)

                    if (questions_data?.length > 0) {
                        const updatedQuestionsData = questions_data?.map((item) => ({ ...item, question_id: uuid(), is_processed: false }));
                        const promises = updatedQuestionsData?.map(async (item) => await createChatFlow(item));
                        const chatFlows = await Promise.all(promises);
                        const allChatFlows = chatFlows.flat();

                        const newChat = {
                            train_chat_data: allChatFlows,
                            train_response: [],
                            train_feedback_thumbs_options: [],
                            train_feedback_chat_options: [],
                            train_continue_options: [],
                            train_user_Prompt: {
                                user_prompt_status: 'inactive',
                                user_prompt_message: ''
                            },
                            pagination: {
                                page: 1,
                                rowsPerPage: 20,
                                totalPages: 1,
                            },
                        };

                        setQmsChatData((prevState) => ({
                            chats: [
                                ...prevState.chats.map((chat) => ({
                                    ...chat,
                                    train_continue_options: []
                                })),
                                newChat
                            ]
                        }));
                        return
                    } else {
                        await handleClearChatInfo(false, responseData.error || 'Retrain Failed')
                    }
                } else {
                    await handleClearChatInfo(false, response.data?.error || 'Retrain Failed')
                }
            }

        } catch (error) {
            await handleClearChatInfo(false, error.message || "An unexpected error occurred")
            console.log(error)
        } finally {
            setChatMessageLoading(false)
        }
    }

    const handleUserPrompt = async (chat_index, user_prompt) => {
        setChatMessageLoading(true)
        try {

            const reqObj = {
                "test_id": qmsTestId,
                "user_action": 'continue',
                "continue_options": 'reassess',
                "user_promt": user_prompt
            }

            const response = await AuditToolApi.retrainQmsModel(JSON.stringify(reqObj))
            if (response.status === 200) {

                const responseData = response?.data
                const questions_data = await getChatQuestions(responseData)

                if (questions_data?.length > 0) {

                    const updatedQuestionsData = questions_data?.map((item) => ({ ...item, question_id: uuid(), is_processed: false }));
                    const promises = updatedQuestionsData?.map(async (item) => await createChatFlow(item));
                    const chatFlows = await Promise.all(promises);
                    const allChatFlows = chatFlows.flat();

                    const newChat = {
                        train_chat_data: allChatFlows,
                        train_response: [],
                        train_feedback_thumbs_options: [],
                        train_feedback_chat_options: [],
                        train_continue_options: [],
                        train_user_Prompt: {
                            user_prompt_status: 'inactive',
                            user_prompt_message: ''
                        },
                        pagination: {
                            page: 1,
                            rowsPerPage: 20,
                            totalPages: 1,
                        },
                    };

                    setQmsChatData((prevState) => ({
                        chats: [
                            ...prevState.chats.map((chat, index) =>
                                index === chat_index
                                    ? {
                                        ...chat,
                                        train_user_Prompt: {
                                            user_prompt_status: 'closed',
                                            user_prompt_message: user_prompt
                                        }
                                    }
                                    : chat
                            ),
                            newChat
                        ]
                    }));
                    setUserPrompt('')
                    return
                } else {
                    await handleClearChatInfo(false, responseData.error || 'Retrain Failed')
                }
            } else {
                await handleClearChatInfo(false, response.data?.error || 'Retrain Failed')
            }
        } catch (error) {
            await handleClearChatInfo(false, error.message || "An unexpected error occurred")
            console.log(error)
        } finally {
            setChatMessageLoading(false)
        }
    }

    return (
        <Box sx={Styles.mainContainer}>
            <Box sx={Styles.cardContainer}>
                {loading && (<LoadBar />)}

                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                    <Typography sx={fontStyles.largeText}>Playground</Typography>

                    <Button
                        sx={{ ...buttonStyles.primaryBtn, width: '175px', height: '35px' }}
                        onClick={() => navigate('/launch/qms-audit-tool/playground/test')}
                    >
                        <Typography style={fontStyles.btnText}>NEW TEST SESSION</Typography>
                    </Button>
                </Box>

                <Typography sx={{ ...fontStyles.smallText, marginTop: '10px' }}>
                    Test {"AI QMS "}Agent
                </Typography>

                <Box marginTop={'20px'}>
                    <Typography sx={{ ...fontStyles.largeText, marginTop: '10px' }}>
                        Test Session History
                    </Typography>

                    <Box marginTop={'20px'} paddingBottom={'20px'}>
                        <GeneralTable
                            data={testSessionHistory}
                            columns={columns}
                            loading={loading}
                        />
                    </Box>
                </Box>

                <ChatHandler
                    chatdrawer={chatdrawer}
                    toggleDrawer={toggleDrawer}
                    chatType={chatType}
                    chatMessageLoading={chatMessageLoading}

                    setQmsChatData={setQmsChatData}
                    qmsChatData={qmsChatData}

                    setRenderableItems={setRenderableItems}
                    renderableItems={renderableItems}

                    setUserPrompt={setUserPrompt}
                    userPrompt={userPrompt}

                    handleQmsChat={handleQmsChat}
                    handleUserChatFeedback={handleUserChatFeedback}
                    handleUserThumbsFeedback={handleUserThumbsFeedback}
                    handleUserContinueOptions={handleUserContinueOptions}
                    handleUserPrompt={handleUserPrompt}
                />
            </Box>
        </Box>
    )
}

export default AuditToolPlaygroundHistory;