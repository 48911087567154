import { AxiosObj } from "../../Configurations/axios-setup";
import { fetchAccountId } from "../../../utils/GetAccountDetails"

class dpodappFlyteApi {

    constructor() {
        this.accountId = null;
    }

    async initialize() {
        this.accountId = await fetchAccountId();
    }

    checkNameExistence = async (space_name) => {
        await this.initialize();
        const queryObj = [{
            field_name: "payload.name",
            field_value: space_name,
            operator: "eq"
        }]
        const queyString = encodeURIComponent(JSON.stringify(queryObj))
        return AxiosObj.get(`/${this.accountId}/api/collection/${this.accountId}/user/private/cm/v7/appflyte_workspaces?filters=${queyString}&include_detail=false`)
    }

    createWorkspace = async (reqObj) => {
        await this.initialize();
        return AxiosObj.post(`/${this.accountId}/api/collection/${this.accountId}/user/private/cm/v1/appflyte_workspaces`, reqObj)
    }

    getAllWorkspaces = async (last_evaluated_key) => {
        await this.initialize();
        return AxiosObj.get(`/${this.accountId}/api/collection/${this.accountId}/user/private/cm/v1/appflyte_workspaces?filters=null&last_evaluated_key=${last_evaluated_key}&page_size=50&include_detail=false`)
    }

}

const WorkspaceApi = new dpodappFlyteApi();
export default WorkspaceApi;


