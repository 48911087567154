export const getStyles = (theme) => ({
    navbar: {
        top: 0,
        left: '85px',
        right: 0,
        zIndex: 999,
        height: "75px",
        position: 'fixed',
        flexWrap: 'wrap',
        backgroundColor: theme.palette.secondary.main,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignSelf: 'center',
        padding: '0px 20px 0px 30px',
    },
    navLeftContent: {
        display: 'flex',
        alignItems: 'center',
        alignSelf: 'center',
    },
    navRightContent: {
        display: 'flex',
        alignItems: 'center',
        alignSelf: 'center',
    },
    navbarBrand: {
        height: '37.48px',
        width: '110px',
        marginRight: '20px',
        display: 'flex',
        alignItems: 'center',
    },
    iconButton: {
        width: '18px',
        height: '12px',
        top: '6px',
        left: '3px',
        gap: '0px',
        border: '1.5px 0px 0px 0px',
        opacity: ' 0px',
        color: '#FFFFFF'
    }
})