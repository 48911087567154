import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSVG } from "react-svg";
import moment from 'moment';

import {
    Box, Button, CircularProgress, Collapse, Divider, IconButton, LinearProgress, Menu,
    MenuItem, Modal, TextField, Tooltip, Typography
} from '@mui/material'
import { CancelOutlined, CheckCircleOutlineOutlined, CloudUpload, ErrorOutlineOutlined, ExpandMoreSharp, QueryBuilderOutlined } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

import { getComponentsStyles } from '../../../styles/componentsStyles';
import "./Styles.css"
import { getStyles } from './Styles';
import { buttonStyles } from '../../../styles/buttonStyles';

import LoadBar from '../../../utils/LoadBar';
import { IconSvg } from '../../../utils/globalIcons';
import { tostAlert } from '../../../utils/AlertToast';
import { AlertMessages } from '../../../utils/AlertMessages';
import { fetchUserId } from '../../../utils/GetAccountDetails';
import { apiErrorHandler } from '../../../utils/ApiErrorHandler';
import { fileRealmExcistenceCheck } from '../../../utils/FileHandling';
import DocSearchFilesData from '../../../utils/ApiFunctions/DocSearchFilesData';
import getAmeyaSettingsData from '../../../utils/ApiFunctions/AmeyaSettingsData';

import { setDocSearchFilesState } from "../../../Redux/slice/dataSlice";
import { setDocSearchFilesAdded } from "../../../Redux/slice/newDataSlice";

import FilesApi from '../../../Api/Services/AppflyteBackend/FileServiceApi';
import DocumentSearchApi from '../../../Api/Services/AmeyaBackend/DocumentSearchApi';
import GeneralTable from '../../../components/GeneralTable';
import { getMainStyles } from '../../../styles/styles';
import { getSessionData } from '../../../utils/sessionDataHandle';

let resJsonVar = []
let uploadProgress = []

function DocSearchFiles() {
    const theme = useTheme();
    const styles = getStyles(theme);
    const mainStyles = getMainStyles(theme);
    const componentsStyles = getComponentsStyles(theme);
    const dispatch = useDispatch()

    // Files Upload
    const filePermission = "PUBLIC"
    const [uploadJson, setUploadJson] = useState([])
    const [selectedFiles, setSelectedFiles] = useState([]);
    const deleteDetails = { existingfiles: [], fileName: [], modalstatus: false };
    const [deleteData, setDeleteData] = useState(deleteDetails);
    const [uploadProgressState, setUploadProgress] = useState([])
    const [loading, setLoading] = useState(false);
    const [loadingSave, SetLoadingSave] = useState(false)

    // Doc Search Files
    const [docSearchFilesInfo, setDocSearchFilesInfo] = useState([]);
    const [docSearchFileLoading, setDocSearchFileLoading] = useState(false);

    // Realm and llm Configurations
    const [initialLoading, setInitialLoading] = useState(false)
    const [realmSettings, setRealmSettings] = useState([]);
    const [llmSettings, setLLMSettings] = useState({ api_keys: "", llm_model: "" })
    const [values, setValues] = useState({});

    // Realm Filter
    const [isFilterApply, setIsFilterApply] = useState(false);
    const [filterMenu, setFilterMenu] = useState(null);
    const [expandedOptions, setExpandedOptions] = useState({});
    const [inputValues, setInputValues] = useState({});

    const doc_search_files_added = useSelector(state => state.data_added.doc_search_files_added)

    useEffect(() => {
        const initializeData = async () => {
            try {
                setInitialLoading(true)
                setSelectedFiles([]);
                const selected_project = getSessionData("selected_project")
                const response = await getAmeyaSettingsData(selected_project?.payload?.__auto_id__);
                if (response?.length > 0) {
                    const responseData = response?.[0] ?? [];
                    const realm_settings = responseData?.payload?.settings?.realm_attributes ?? [];
                    const llm_settings = responseData?.payload?.settings?.llm_settings ?? {};
                    if (realm_settings?.length > 0 || llm_settings) {
                        setRealmSettings(realm_settings);
                        setLLMSettings({
                            ...llmSettings,
                            api_keys: typeof llm_settings?.api_keys === "string" ? llm_settings.api_keys : "",
                            llm_model: typeof llm_settings?.llm_model === "string" ? llm_settings.llm_model : ""
                        });
                    }
                }
                setInitialLoading(false)
                getAllDocSearchFiles(false);
            } catch (error) {
                console.error("Error initializing data:", error);
            }
        };

        initializeData();
        //eslint-disable-next-line
    }, [doc_search_files_added])

    const handleToggle = (item) => {
        setExpandedOptions(prev => ({
            ...prev,
            [item]: !prev[item],
        }));
    };

    const handleInputChange = (item, value) => {
        setInputValues(prev => ({
            ...prev,
            [item]: value,
        }));
    };

    const handleFilterApply = async () => {
        const filteredValues = Object?.fromEntries(Object?.entries(inputValues)?.filter(([key, value]) => value)) || {};
        if (Object.keys(filteredValues).length === 0) {
            tostAlert('Please select at least one filter', 'warning')
            return;
        }
        setIsFilterApply(true)
        setFilterMenu(null)
        getAllDocSearchFiles(true);
    }

    const handleCancelFilter = () => {
        setFilterMenu(null)
        setInputValues({})
        setExpandedOptions({})
        setIsFilterApply(false)
        getAllDocSearchFiles(false);
    }

    // Handling Realm Config
    const handleValueChange = (event) => {
        const { name, value } = event.target;
        setValues((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const getAllDocSearchFiles = async (is_filter) => {
        setDocSearchFileLoading(true)
        try {
            let doc_search_files = []
            const selected_project = getSessionData("selected_project")
            const filteredValues = Object?.fromEntries(Object?.entries(inputValues)?.filter(([key, value]) => value)) || {};
            const reqObj = {
                "realm": is_filter ? filteredValues : {},
                "project_id": selected_project?.payload?.__auto_id__
            }

            const response = await DocSearchFilesData(JSON.stringify(reqObj))
            if (!is_filter) {
                dispatch(setDocSearchFilesState(response))
                dispatch(setDocSearchFilesAdded(false))
            }

            doc_search_files = response

            const updatedData = doc_search_files?.length > 0 ? doc_search_files?.map(item => {
                const file_format = item?.file_name?.includes('.')
                    ? item.file_name.split('.').pop()
                    : null;

                const fixedDate = item?.uploaded_date?.replace(/:(\d{2}):(\d{2})$/, (match, mins, secs) => {
                    return `:${mins}:${Math.min(Number(secs), 59)}`;
                });
                return { ...item, file_format, fixedDate };
            }) : []

            const sorted_doc_search_files = updatedData?.length > 0
                ? [...updatedData]?.sort((a, b) => {
                    const dateA = Date.parse(a?.fixedDate) ? new Date(a?.fixedDate) : null;
                    const dateB = Date.parse(b?.fixedDate) ? new Date(b?.fixedDate) : null;
                    if (!dateA || !dateB) return !dateA ? 1 : -1;
                    return dateB - dateA;
                })
                : [];
            setDocSearchFilesInfo(sorted_doc_search_files)
        } catch (error) {
            console.log(error)
        } finally {
            setDocSearchFileLoading(false)
        }
    }

    // Handle File Upload
    const handleFileDrop = async (event) => {
        try {
            event.preventDefault();
            event.stopPropagation();
            const files = Array.from(event.dataTransfer.files);
            const maxFileSize = 50 * 1024 * 1024;
            const validFiles = files.filter(file => file.size <= maxFileSize) ?? []
            if (validFiles.length < files.length) {
                tostAlert('File Size Exceeds 50 MB', 'warning')
                return
            }
            event.target.value = null;
            setSelectedFiles(files);
        } catch (error) {
            console.log(error)
        }
    };

    const handleFileChange = async (event) => {
        try {
            event.preventDefault();
            event.stopPropagation();

            const files = Array.from(event.target.files);
            const maxFileSize = 50 * 1024 * 1024;
            const validFiles = files.filter(file => file.size <= maxFileSize) ?? []
            if (validFiles.length < files.length) {
                tostAlert('File Size Exceeds 50 MB', 'warning')
                return
            }
            event.target.value = null;
            setSelectedFiles(files);
        } catch (error) {
            console.log(error)
        }
    };

    const handleFileRemove = (index) => {
        const updatedFiles = [...selectedFiles];
        updatedFiles.splice(index, 1);
        setSelectedFiles(updatedFiles);
    }

    const markIndexFailed = (index, errorMessage) => {
        const tempLoadArr = [...uploadProgress]
        tempLoadArr[index] = { status: 'failed', message: errorMessage };
        uploadProgress = tempLoadArr
        setUploadProgress([...tempLoadArr])
    }

    const saveInAmeyaBackend = async (fileData) => {
        try {
            const userId = await fetchUserId();
            const selected_project = getSessionData("selected_project")
            const documents_urls = fileData?.map((item) => item?.fileUrl) ?? []
            const document_ids = fileData?.map((item) => item?.fileId) ?? []

            const reqObj = {
                "document_download_url": documents_urls,
                "user_id": userId,
                "realm": Object.keys(values)?.length > 0 ? values : {},
                "document_id": document_ids,
                "project_id": selected_project?.payload?.__auto_id__,
                "uploaded_date": moment().format("YYYY-MM-DD HH:MM:SS"),
            }
            const upload_reponse = await DocumentSearchApi.uploadDraftPdfFile(JSON.stringify(reqObj));
            return upload_reponse
        } catch (error) {
            return error
        }
    }

    const uploadFile = async (blob, reqBody) => {
        try {
            const resData = await FilesApi.getPresignedURL(JSON.stringify(reqBody))
            if (resData.status === 200) {
                const urlFields = JSON.parse(resData.data.url_fields)
                let formData = new FormData();

                formData.append('key', urlFields.key);
                formData.append('AWSAccessKeyId', urlFields.AWSAccessKeyId);
                // formData.append('x-amz-security-token', urlFields['x-amz-security-token']);
                formData.append('policy', urlFields.policy);
                formData.append('signature', urlFields.signature);
                formData.append('file', blob);

                const resUpload = await FilesApi.uploadFile(resData.data.url, formData)
                if (resUpload.status === 200 || resUpload.status === 204) {
                    return resData?.data?.file_id
                }
                return null
            }
            return null
        } catch (e) {
            console.log('Err ' + e)
            return null
        }
    }

    const uploadAndAppendForPost = async (file, fileType, thumbnailFileId) => {
        try {

            const reqBodyFile = {
                file_context: 'bucket_dpod_user_file',
                content_type: file?.name?.split('.')[1],
                file_type: '',
                file_name: file?.name
            }

            const uploadedFileId = await uploadFile(file, reqBodyFile)
            if (uploadedFileId) {
                const uploadedFileUrl = await FilesApi.getUploadedFileUrls(uploadedFileId)
                if (uploadedFileUrl.status === 200) {
                    const tempJsonArr = [...resJsonVar]
                    const uploadResObj = {
                        file_id: uploadedFileId,
                        folder_id: null,
                        access_type: filePermission,
                        file_type: fileType,
                        file_attributes: {
                            file_name: file?.name,
                            file_url: uploadedFileUrl?.data?.[0]?.download_url,
                            created_on: moment().format("DD-MM-YYYY"),
                            file_extension: file?.name?.split('.')[1]
                        },
                        thumbnail_file_id: thumbnailFileId,
                        is_hidden: true
                    }
                    tempJsonArr.push(uploadResObj)
                    resJsonVar = tempJsonArr
                    setUploadJson(tempJsonArr)
                    return { fileId: uploadedFileId, fileUrl: uploadedFileUrl?.data?.[0]?.download_url }
                }
                return { fileId: null, fileUrl: null }
            }
            return { fileId: null, fileUrl: null }
        }
        catch (err) {
            console.log("ERROR: ", err);
            return { fileId: null, fileUrl: null }
        }
    }

    const handleDocumentUpload = async (file) => {
        try {
            const uploadResponse = await uploadAndAppendForPost(file, 'Document', null)
            if (uploadResponse.fileUrl && uploadResponse.fileId) {
                return { fileUrl: uploadResponse.fileUrl, fileId: uploadResponse.fileId }
            }
            return { fileUrl: null, fileId: null }
        }
        catch (err) {
            console.log("ERROR: ", err);
            return { fileUrl: null, fileId: null }
        }
    }

    const startUploading = async () => {
        setLoading(true)
        try {
            setDeleteData({ ...deleteData, modalstatus: false });
            const selected_project = getSessionData("selected_project")
            const tempArr = selectedFiles.map(file => ({ status: 'pending', message: "Waiting to upload" }));
            setUploadProgress([...tempArr]);
            uploadProgress = tempArr;
            let uploadedFilesData = [];

            if (deleteData?.existingfiles?.length > 0) {
                try {
                    for (let [index, item] of deleteData?.existingfiles.entries()) {

                        const reqObj = {
                            "realm": item?.realm ?? {},
                            "document_id": item?.document_id,
                            "project_id": selected_project?.payload?.__auto_id__,
                            "version_id": 0,
                            "state": "Draft"
                        };
                        const delete_response = await DocumentSearchApi.deleteUploadedFiles(JSON.stringify(reqObj));
                        if (delete_response.status !== 200) {
                            markIndexFailed(index, 'Upload failed');
                        }
                    }
                } catch (error) {
                    console.error("Error deleting files:", error);
                }
            }

            for (const [index, file] of selectedFiles.entries()) {
                const tempLoadArr = [...uploadProgress];
                tempLoadArr[index] = { status: 'uploading', message: "uploading the file" };
                setUploadProgress(tempLoadArr);
                uploadProgress = tempLoadArr;

                try {
                    const file_upload_response = await handleDocumentUpload(file);
                    if (file_upload_response.fileUrl && file_upload_response.fileId) {
                        uploadedFilesData?.push({
                            fileUrl: file_upload_response.fileUrl,
                            fileId: file_upload_response.fileId
                        });
                    } else {
                        markIndexFailed(index, 'Upload failed');
                    }
                } catch (err) {
                    markIndexFailed(index, 'Upload failed');
                    console.error(`Failed to upload file at index ${index}`, err);
                }

            }

            if (uploadedFilesData?.length > 0) {
                try {
                    const response = await saveInAmeyaBackend(uploadedFilesData);
                    if (response.status === 200) {
                        for (let index = 0; index < uploadedFilesData?.length; index++) {
                            const tempLoadArr = [...uploadProgress]
                            tempLoadArr[index] = { status: 'success', message: "upload success" };
                            uploadProgress = tempLoadArr
                            setUploadProgress([...tempLoadArr])
                        }
                        return
                    } else {
                        for (let index = 0; index < uploadedFilesData?.length; index++) {
                            markIndexFailed(index, 'Upload failed')
                        }
                        return
                    }
                } catch (error) {
                    for (let index = 0; index < uploadedFilesData?.length; index++) {
                        markIndexFailed(index, 'Upload failed')
                    }
                }
            } else {
                markIndexFailed(0, 'Upload failed')
            }

        } catch (error) {
            console.log(error)
            apiErrorHandler(error)
        } finally {
            setDeleteData(deleteDetails);
            setLoading(false)
        }
    }

    const handleDeleteAlertModalClose = () => {
        setDeleteData(deleteDetails);
    }

    const FileUploadChecks = async () => {
        try {
            if (selectedFiles?.length === 0) {
                const message = await AlertMessages('warning', 'Files');
                tostAlert(message, 'warning')
                return
            }

            // if (!llmSettings?.api_keys?.trim() || !llmSettings?.llm_model?.trim()) {
            //     tostAlert('LLM Configuration Required', 'warning')
            //     return
            // }

            const missingFields = realmSettings?.filter((key) => !values[key]);
            if (missingFields.length > 0) {
                tostAlert(`Please fill in all fields: ${missingFields.join(', ')}`, 'warning')
                return
            }
            const realm_settings = Object.keys(values)?.length > 0 ? values : {}
            const existingfiles = await fileRealmExcistenceCheck(docSearchFilesInfo, selectedFiles, realm_settings)

            if (existingfiles.length > 0) {
                const existing_files_names = existingfiles?.map(item => item?.file_name) ?? []
                setDeleteData({ ...deleteData, existingfiles: existingfiles, fileName: existing_files_names, modalstatus: true });
            }
            else {
                startUploading()
            }

        } catch (error) {
            console.log(error)
        }
    }

    const closeUploadIndicator = () => {
        setLoading(false)
        setUploadProgress([])
        uploadProgress = []
        resJsonVar = []
        setSelectedFiles([])
    }

    const saveUploadChanges = async () => {
        SetLoadingSave(true)
        try {
            const res = await FilesApi.createFile(uploadJson)
            if (res.status === 200 || res.status === 204) {
                dispatch(setDocSearchFilesAdded(true))
            }
        } catch (error) {
            console.log(error)
            apiErrorHandler(error)
        } finally {
            SetLoadingSave(false)
            setLoading(false)
            uploadProgress = []
            resJsonVar = []
            setUploadProgress([])
            setSelectedFiles([])
        }
    }

    useEffect(() => {
        if (uploadProgressState && uploadProgressState.length && uploadProgressState.length > 0) {
            let i
            let flagToSave = true
            for (i = 0; i < uploadProgressState.length; i++) {
                if (!(uploadProgressState[i]?.status === "success" || uploadProgressState[i]?.status === "failed")) {
                    flagToSave = false
                    break
                }
            }
            if (flagToSave) {
                saveUploadChanges()
            }
        }
        //eslint-disable-next-line
    }, [uploadProgressState])


    const handleSearchFileDelete = async (item) => {

        setLoading(true)
        try {
            const selected_project = getSessionData("selected_project")
            const reqObj = {
                "realm": item?.realm ?? {},
                "document_id": item?.document_id,
                "project_id": selected_project?.payload?.__auto_id__,
                "version_id": 0,
                "state": "Draft"
            }
            const res = await DocumentSearchApi.deleteUploadedFiles(JSON.stringify(reqObj))
            if (res.status === 200) {
                dispatch(setDocSearchFilesAdded(true))
                tostAlert('File Deleted Successfully', 'success')
            }
        } catch (error) {
            tostAlert('File Deletion Failed', 'error')
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const columns = [
        {
            id: 'file_name',
            label: 'Document',
            width: '35%',
            sortable: true,
            renderCell: (row) => (
                <Box display={'flex'} alignItems={'center'}>
                    <span style={{ marginRight: '10px', whiteSpace: 'nowrap' }}>
                        <ReactSVG src={IconSvg.filesIcon} className='files_icon' />
                    </span>
                    <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        <Tooltip
                            title={
                                <div style={{ padding: '8px' }}>
                                    <Typography sx={{ ...styles.paraText }}>{row.file_name}</Typography>
                                </div>
                            }
                            arrow
                        >
                            {row.file_name?.length > 30
                                ? `${row.file_name.substring(0, 50)}...`
                                : row.file_name}
                        </Tooltip>
                    </span>
                </Box>
            ),
        },
        {
            id: 'status',
            label: 'Indexing Status',
            width: '20%',
            sortable: true,
            renderCell: (row) => (
                <Box display={'flex'} alignItems={'center'}>
                    {
                        row?.status === "Processing" && (
                            <span style={{ marginRight: '10px' }}>
                                <ReactSVG src={IconSvg.trainedIcon} className='upload_proccess_icon' />
                            </span>
                        )
                    }
                    {
                        row?.status === "Success" && (
                            <span style={{ marginRight: '10px' }}>
                                <ReactSVG src={IconSvg.trainedIcon} className='upload_success_icon' />
                            </span>
                        )
                    }
                    {
                        row?.status === "Failed" && (
                            <span style={{ marginRight: '10px' }}>
                                <ReactSVG src={IconSvg.trainedIcon} className='upload_failed_icon' />
                            </span>
                        )
                    }
                    {
                        row?.status === "Processing" ?
                            (<span>processing</span>) :
                            (<span>{row?.status}</span>)
                    }
                </Box>
            )
        },
        {
            id: 'uploaded_date',
            label: 'Created On',
            width: '15%',
            sortable: true,
            renderCell: (row) => row?.uploaded_date?.substring(0, 10)
        },
        {
            id: 'realm',
            label: 'Realms',
            width: '20%',
            sortable: false,
            renderCell: (row) =>
                row?.realm && (
                    <Tooltip
                        title={
                            <div style={{ padding: '8px', fontSize: '14px' }}>
                                {Object.entries(row.realm).map(([key, value]) => (
                                    <Typography sx={{ ...styles.paraText }} key={key}>
                                        {`${key}: ${value}`}
                                    </Typography>
                                ))}
                            </div>
                        }
                        arrow
                    >
                        <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            {Object.entries(row.realm)
                                .slice(0, 1)
                                .map(([key, value]) => `${key}: ${value}`)
                                .join(', ')}
                            {Object.keys(row?.realm).length > 1 ? ', ...' : ''}
                        </span>
                    </Tooltip>
                )
        },
        {
            id: '',
            label: '',
            width: '10%',
            sortable: false,
            renderCell: (row) => (
                <ReactSVG
                    src={IconSvg.deleteIcon}
                    className="delete_icon"
                    onClick={() => handleSearchFileDelete(row)}
                />
            ),
        },
    ];

    return (
        <Box sx={styles.mainContainer}>
            <Box sx={styles.cardContainer}>
                <Typography sx={styles.mainHeadingText}>Sources - Files</Typography>

                <Typography sx={{ ...styles.paraText, marginTop: '10px' }}>
                    Upload documents to index
                </Typography>

                {(loading || docSearchFileLoading) && (<LoadBar />)}

                {
                    uploadProgressState && uploadProgressState.length > 0
                        ?
                        <Box sx={styles.uploadLoadingBox}>
                            <Box sx={styles.uploadingHeaderBox}>
                                {
                                    loadingSave
                                        ?
                                        <Typography sx={styles.uploadingText}>Saving changes</Typography>
                                        :
                                        <Typography sx={styles.uploadingText}>Uploading files …</Typography>
                                }
                                {
                                    !loadingSave ? <CancelOutlined sx={styles.closeIcon} onClick={closeUploadIndicator} /> : ''
                                }
                            </Box>
                            {
                                loadingSave ? <LinearProgress width="100%" /> : ''
                            }
                            <Box sx={styles.fileListContainer}>
                                {
                                    selectedFiles.map((file, index) =>
                                        <Box key={"upload_progress_ind_key_" + index}>
                                            {
                                                index > 0 ? <Divider width="100%" /> : ''
                                            }
                                            <Box display="flex" height="40px" width="100%" alignItems="center" paddingX="10px">
                                                {
                                                    ({
                                                        'uploading': <CircularProgress size="18px" sx={{ ...styles.tickIcon, color: '#0B51C5' }} />,
                                                        'pending': <QueryBuilderOutlined sx={{ ...styles.tickIcon, color: '#1976d2' }} />,
                                                        'success': <CheckCircleOutlineOutlined sx={{ ...styles.tickIcon, color: '#1976d2' }} />,
                                                        'failed': <ErrorOutlineOutlined sx={styles.failedIcon} />
                                                    }[uploadProgressState[index]?.status])
                                                }
                                                <Box display="flex" flexDirection="column" width="100%">
                                                    <Typography noWrap sx={styles.dropZoneFont}>{file?.name}</Typography>
                                                    {/* {
                                                        uploadProgressState[index]?.status === 'failed' && (
                                                            <Typography
                                                                noWrap
                                                                sx={{
                                                                    ...Styles.dropZoneFont,
                                                                    color: 'red',
                                                                    marginTop: '3px',
                                                                    marginBottom: '8px',
                                                                }}
                                                            >
                                                                {uploadProgressState[index]?.message}
                                                            </Typography>
                                                        )
                                                    } */}
                                                    {/* {
                                                        uploadProgressState[index]?.status === 'success' && (
                                                            <Typography
                                                                noWrap
                                                                sx={{
                                                                    ...Styles.dropZoneFont,
                                                                    color: 'green',
                                                                    marginTop: '3px',
                                                                    marginBottom: '8px',
                                                                }}
                                                            >
                                                                {uploadProgressState[index]?.message}
                                                            </Typography>
                                                        )
                                                    } */}
                                                </Box>
                                            </Box>
                                        </Box>
                                    )
                                }
                            </Box>
                        </Box>
                        :
                        ''
                }

                <Box marginTop={'20px'} sx={styles.fileUploadContainer}>
                    <Box
                        onDrop={handleFileDrop}
                        onDragOver={(e) => e.preventDefault()}
                    >
                        <input
                            type="file"
                            accept=".pdf,.docx,.doc,.txt,.xls,.xlsx,.csv"
                            onChange={handleFileChange}
                            style={{ display: 'none', cursor: 'pointer' }}
                            id="fileInput"
                            multiple
                        />
                        <label
                            id='fileInput'
                            htmlFor="fileInput">
                            {selectedFiles.length === 0 &&
                                <Box>
                                    <Typography sx={styles.fileDropLabelText}>Drag and drop files or&nbsp;<span style={{ color: '#0B51C5', cursor: 'pointer' }}>click to upload</span> </Typography>
                                    <Typography sx={styles.paraText}>Upto 50 MB in size (.pdf, .docx, .doc, .txt, .xls, .xlsx, .csv) </Typography>
                                </Box>
                            }
                            <IconButton component="span">
                                <CloudUpload style={{ color: "black", width: "32px", height: "32px" }} />
                            </IconButton>
                        </label>
                    </Box>
                    <Box display={'flex'} justifyContent={'center'} alignItems={'center'} flexWrap="wrap" >
                        {
                            selectedFiles.length > 0 && selectedFiles.map((file, index) => (
                                < Box key={index} display={'flex'} justifyContent={'center'} alignItems={'center'} alignContent={'center'} textAlign={'center'} padding={'3px 15px 3px 15px'} margin={'5px'} backgroundColor={'#FFFFFF'} borderRadius={'20px'}>
                                    <Typography sx={styles.paraText}>{file.name}</Typography>
                                    <Box marginLeft={'10px'} onClick={() => handleFileRemove(index)}>
                                        <ReactSVG src={IconSvg.deleteIcon} className='delete_icon' />
                                    </Box>
                                </Box>
                            ))
                        }
                    </Box>
                </Box >

                {(!initialLoading && realmSettings?.length > 0) &&
                    (<Box marginTop={'20px'} >
                        <Typography sx={styles.realmHeadingText}>Realm Settings</Typography>
                        <Box>
                            {realmSettings?.length > 0 ?
                                (<Box display={'flex'} alignItems={'center'} flexWrap="wrap" >
                                    {realmSettings.map((key, index) => (
                                        <Box key={index} marginRight={'15px'} marginTop={'10px'}>
                                            <Typography sx={{ ...styles.paraText, width: '150px', marginBottom: '10px' }}>{key}</Typography>
                                            <TextField
                                                value={values?.[key] || ''}
                                                onChange={handleValueChange}
                                                placeholder={`Enter ${key}`}
                                                size="small"
                                                sx={{ ...componentsStyles.textField, width: '200px' }}
                                                name={key}
                                            />
                                        </Box>
                                    ))}
                                </Box>)
                                :
                                (<Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                    <Typography sx={styles.paraText}>No Records To Display</Typography>
                                </Box>)}
                        </Box>
                    </Box>)
                }


                {initialLoading && (
                    <Box display={'flex'} justifyContent={'center'} alignItems={'center'} marginTop={'20px'}>
                        <CircularProgress />
                    </Box>
                )}

                <Box marginTop={'30px'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                    <Button
                        sx={{ ...buttonStyles.primaryBtn, width: '180px', height: '40px' }}
                        disabled={docSearchFileLoading || loading}
                        onClick={() => FileUploadChecks()}
                    >
                        <Typography style={styles.btnText}>Upload and Train</Typography>
                    </Button>
                </Box>

                <Box display={'flex'} justifyContent={'end'} alignItems={'center'} marginTop={'20px'} color={'#0B51C5'}>
                    <Box display={'flex'} alignItems={'center'} alignSelf={'center'} cursor={'pointer'}
                        onClick={(event) => setFilterMenu(event.currentTarget)}
                    >
                        <ReactSVG src={IconSvg.filterOpenIcon} className={isFilterApply ? 'filter_icon_active' : 'filter_icon'} />
                        <Typography sx={{ ...styles.paraText, marginLeft: '10px' }}>Filter</Typography>
                    </Box>

                    <Box>
                        <Menu
                            anchorEl={filterMenu}
                            open={filterMenu}
                            onClose={() => setFilterMenu(null)}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                            sx={{ borderRadius: '15px' }}
                        >
                            {realmSettings?.length > 0 ?
                                (<MenuItem sx={{ '&:hover': { backgroundColor: 'transparent' }, borderRadius: '15px' }} >
                                    <Box display={"flex"} flexDirection={"column"} maxHeight={"300px"} overflowY={"auto"} padding={"20px"} width={"300px"} paddingBottom={"70px"}>
                                        <Box flexGrow={1}></Box>
                                        {realmSettings?.map((item, index) =>
                                        (<Box key={index} display={"flex"} flexDirection={"column"} marginBottom={"15px"} padding={"5px"} borderRadius={"5px"} border={"1px solid #D9D9D9"}>
                                            <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}
                                                onClick={() => handleToggle(item)}>
                                                <Typography noWrap sx={{ ...styles.paraText, overflow: 'hidden', textOverflow: 'ellipsis' }}>{item}</Typography>
                                                <Box><ExpandMoreSharp /></Box>
                                            </Box>

                                            <Collapse in={expandedOptions[item]} timeout="auto" unmountOnExit>
                                                <Box marginTop={1}>
                                                    <TextField
                                                        placeholder={`Enter value for ${item}`}
                                                        value={inputValues[item] || ''}
                                                        onChange={(e) => handleInputChange(item, e.target.value)}
                                                        fullWidth
                                                        size="small"
                                                        sx={{ ...componentsStyles.textField }}
                                                    />
                                                </Box>
                                            </Collapse>
                                        </Box>))}
                                    </Box>
                                </MenuItem>)
                                :
                                (<MenuItem>No Records To Display</MenuItem>)
                            }
                            <Divider sx={{ marginTop: '10px', marginBottom: '10px' }} />

                            <Box display={"flex"} justifyContent={"end"} alignItems={"center"} padding={"5px 5px"}>
                                <Button sx={{ ...buttonStyles.secondaryBtn, width: '100px' }}
                                    onClick={() => handleCancelFilter()}
                                >
                                    <Typography sx={styles.btnText}>Cancel</Typography>
                                </Button>
                                <Button
                                    sx={{ ...buttonStyles.primaryBtn, width: '100px', marginLeft: '10px' }}
                                    onClick={() => handleFilterApply()}
                                >
                                    <Typography sx={styles.btnText}>Apply</Typography>
                                </Button>
                            </Box>
                        </Menu>
                    </Box>
                </Box>

                <Box marginTop={'10px'} paddingBottom={'20px'}>
                    <GeneralTable
                        data={docSearchFilesInfo}
                        columns={columns}
                        loading={docSearchFileLoading}
                    />
                </Box>
            </Box>

            <Modal open={deleteData.modalstatus} onClose={handleDeleteAlertModalClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={{ ...mainStyles.modalcontainer, width: '40%' }}>
                    <Box sx={mainStyles.mainWarning}>Do You Want To Replace{('\n')}</Box>
                    <Box sx={{ ...mainStyles.subWarning, whiteSpace: 'pre-line' }}>
                        {deleteData.existingfiles.length > 0 && `${deleteData?.fileName.join('\n')}`}
                    </Box>
                    <Box sx={mainStyles.subWarning}>There is Already a file with same name is present</Box>
                    <Box style={mainStyles.confirmButtonGroup}>
                        <Button
                            onClick={handleDeleteAlertModalClose}
                            sx={{ ...mainStyles.cancelDelete, textTransform: 'none' }}
                        >
                            <Typography sx={styles.btnText}>Cancel</Typography>
                        </Button>
                        <Button
                            onClick={() => { startUploading() }}
                            sx={{ ...mainStyles.confirmDelete, textTransform: 'none' }}
                        >
                            <Typography sx={styles.btnText}>Upload</Typography>
                        </Button>
                    </Box>
                </Box>
            </Modal >


        </Box >
    )
}

export default DocSearchFiles;