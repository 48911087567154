import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import { Styles } from './Styles';
import LoadBar from '../../../utils/LoadBar';
import { fetchSubscriberId, fetchSubscriptionId } from '../../../utils/GetAccountDetails';
import { useSelector } from 'react-redux';
import { fontStyles } from '../../../styles/fontStyles';
import agentApiTokenManager from '../../../utils/AgentApiToken/getAgentAdminToken';
import { getSessionData } from '../../../utils/sessionDataHandle';

function LLMDocSearchApi() {

    const [filteredSpec, setFilteredSpec] = useState(null);
    const [specLoading, setSpecLoading] = useState(false)
    const selected_project = getSessionData("selected_project")

    const getAgentApiToken = async () => {
        if (selected_project?.payload?.__auto_id__) {
            const token = await agentApiTokenManager.getAgentAdminToken();
            return token ?? null
        }
        return null
    }

    useEffect(() => {
        const fetchAndFilterSpec = async () => {
            setSpecLoading(true)
            try {
                const agentTokenId = await getAgentApiToken()
                const response = await fetch(`${window.env.REACT_APP_LLM_DOCUMENT_SEARCH_API_DOCS_URL || process.env.REACT_APP_LLM_DOCUMENT_SEARCH_API_DOCS_URL}`, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${agentTokenId}`,
                    },
                });

                const spec = await response.json();
                const baseUrl = window.env.REACT_APP_LLM_DOCUMENT_SEARCH_URL || process.env.REACT_APP_LLM_DOCUMENT_SEARCH_URL;
                spec.servers = [
                    {
                        url: baseUrl,
                        description: "Base URL for API",
                    },
                ];

                const filteredPaths = Object.keys(spec.paths).reduce((acc, path) => {
                    if (path.startsWith("/user")) {
                        acc[path] = spec.paths[path];
                    }
                    return acc;
                }, {});

                const filteredSpec = {
                    ...spec,
                    paths: filteredPaths,
                };

                const subscriber_id = await fetchSubscriberId();
                const subscription_id = await fetchSubscriptionId()

                Object.keys(filteredSpec.paths).forEach((path) => {
                    Object.keys(filteredSpec.paths[path]).forEach((method) => {
                        const operation = filteredSpec.paths[path][method];

                        if (operation.parameters) {
                            const pathParams = operation.parameters.filter(
                                (param) => param.in === 'path'
                            );

                            pathParams.forEach((param) => {
                                if (param.name === 'subscriber_id' && subscriber_id) {
                                    param.default = subscriber_id;
                                }
                                if (param.name === 'subscription_id' && subscription_id) {
                                    param.default = subscription_id;
                                }
                            });
                        }
                    });
                });
                setFilteredSpec(filteredSpec);
            } catch (error) {
                console.error("Error fetching or filtering OpenAPI spec:", error);
            } finally {
                setSpecLoading(false)
            }
        };

        fetchAndFilterSpec();
        //eslint-disable-next-line
    }, []);

    if (specLoading) {
        return (
            <Box sx={{ ...Styles.mainContainer, textAlign: 'center', marginTop: '100px' }}>
                <LoadBar />
            </Box>
        );
    }

    if (!filteredSpec) {
        return (
            <Box sx={{ ...Styles.mainContainer, textAlign: 'center', marginTop: '100px' }}>
                No records to display
            </Box>
        );
    }

    return (
        <Box sx={{ ...Styles.mainContainer, overflowX: "auto" }}>
            <Box sx={Styles.cardContainer}>
                <Box marginBottom={'10px'}>
                    <Typography sx={fontStyles.mediumText}>ProjectId:&nbsp;&nbsp;{selected_project?.payload?.__auto_id__}</Typography>
                </Box>
                <SwaggerUI
                    spec={filteredSpec}
                    deepLinking={true}
                    docExpansion="list"
                    defaultModelsExpandDepth={-1}
                    defaultModelExpandDepth={1}
                    layout="BaseLayout"
                    showExtensions={true}
                    showCommonExtensions={true}
                />
            </Box>
        </Box >
    );
}
export default LLMDocSearchApi
