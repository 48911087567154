import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSVG } from "react-svg";
import { v4 as uuid } from 'uuid';

import { Autocomplete, Box, Button, FormControl, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { getComponentsStyles } from '../../../styles/componentsStyles';
import { Styles } from './Styles';
import { fontStyles } from '../../../styles/fontStyles';
import { buttonStyles } from '../../../styles/buttonStyles';

import { setCOAFilesState, setSpecificationFilesState } from "../../../Redux/slice/dataSlice";
import { setSpecificationFilesAdded, setCOAFilesAdded, setAuditTestHistoryAdded } from "../../../Redux/slice/newDataSlice";

import LoadBar from '../../../utils/LoadBar';
import { IconSvg } from '../../../utils/globalIcons';
import { tostAlert } from '../../../utils/AlertToast';
import PdfSourceFiles from '../../../utils/ApiFunctions/PdfSourceFiles';
import ChatHandler from './AuditToolChat/ChatHandler';
import JsonSourceFiles from '../../../utils/ApiFunctions/JsonSourceFiles';
import AuditToolApi from '../../../Api/Services/AmeyaBackend/AuditToolApi';
import GeneralTable from '../../../components/GeneralTable';

function AuditToolPlayground() {

    const theme = useTheme();
    const componentsStyles = getComponentsStyles(theme);

    const dispatch = useDispatch();
    const [coafiles, setCOAFiles] = useState([]);
    const [specificationfiles, setSpecificationFiles] = useState([]);
    const [isResubmit, setIsResubmit] = useState(false);
    const [currentFiles, setCurrentFiles] = useState([]);
    const [loading, setLoading] = useState(false);

    // Redux States
    const coa_files_data = useSelector(state => state.all_data.coafiles)
    const specification_files_data = useSelector(state => state.all_data.specificationfiles)
    const coa_files_added = useSelector(state => state.data_added.coa_files_added)
    const specification_files_added = useSelector(state => state.data_added.specification_files_added)

    const [selectedpdffile, setSelectedPdfFile] = useState(null)
    const [selectedjsonfile, setSelectedJsonFile] = useState(null)
    const documentprocessdetails = { test_id: null, total_pass: null, total_tests: null, test_result: [], test_status: 'loading' }
    const [documentprocessdata, setDocumentProcessData] = useState(documentprocessdetails)

    // QMS Chat Handle
    const chatType = 'Train'
    const [chatMessageLoading, setChatMessageLoading] = useState(false);
    const [chatdrawer, setChatDrawer] = useState({ state: false });

    const [qmsTestId, setQmsTestId] = useState(null);
    const initialQmsChat = { chats: [] }
    const [qmsChatData, setQmsChatData] = useState(initialQmsChat)
    const [renderableItems, setRenderableItems] = useState(initialQmsChat)
    const [userPrompt, setUserPrompt] = useState('');

    useEffect(() => {
        getsourceFilesDetails()
        //eslint-disable-next-line
    }, [coa_files_added, specification_files_added,])

    const getCoaSourceFiles = async () => {
        if (coa_files_data?.length > 0 && coa_files_added === false) {
            return coa_files_data
        } else {
            const response = await PdfSourceFiles();
            dispatch(setCOAFilesState(response))
            dispatch(setCOAFilesAdded(false))
            return response
        }
    }

    const getSpecificationSourceFiles = async () => {
        if (specification_files_data?.length > 0 && specification_files_added === false) {
            return specification_files_data
        } else {
            const response = await JsonSourceFiles();
            dispatch(setSpecificationFilesState(response))
            dispatch(setSpecificationFilesAdded(false))
            return response
        }
    }

    const getsourceFilesDetails = async () => {
        setLoading(true)
        try {
            const [coaResponse, jsonResponse] = await Promise.all([
                getCoaSourceFiles(),
                getSpecificationSourceFiles()
            ])

            if (coaResponse && coaResponse?.length > 0) {
                const filterd_coa_files = coaResponse?.length > 0 ? coaResponse?.filter(item => item?.status === 'success') : []
                setCOAFiles(filterd_coa_files)
            }

            if (jsonResponse && jsonResponse?.length > 0) {
                const filterd_spec_files = jsonResponse?.length > 0 ? jsonResponse?.filter(item => item?.status === 'success') : []
                setSpecificationFiles(filterd_spec_files)
            }

        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (selectedpdffile?.file_name && selectedjsonfile?.file_name && currentFiles?.pdf_file_name && currentFiles?.json_file_name) {
            if (selectedpdffile.file_name === currentFiles.pdf_file_name && selectedjsonfile.file_name === currentFiles.json_file_name) {
                setIsResubmit(true);
            } else {
                setIsResubmit(false);
            }
        } else {
            setIsResubmit(false);
        }
    }, [selectedpdffile, selectedjsonfile, currentFiles]);

    const handlePdfFileSelection = async (event, item) => {
        event.preventDefault()
        setSelectedPdfFile(item)
    }

    const handleJsonFileSelection = async (event, item) => {
        event.preventDefault()
        setSelectedJsonFile(item)
    }

    //Handle Document APi
    const handleDocuments = async () => {
        setLoading(true);
        setIsResubmit(false)
        try {
            if (selectedpdffile && selectedjsonfile) {
                setCurrentFiles({
                    pdf_file_name: selectedpdffile?.file_name,
                    json_file_name: selectedjsonfile?.file_name
                })

                const reqObj = {
                    "json_file_name": selectedjsonfile?.file_name,
                    "pdf_file_name": selectedpdffile?.file_name,
                }
                const response = await AuditToolApi.qmsCheckCompliance(JSON.stringify(reqObj))
                if (response.status === 200) {
                    const reposne_data = response?.data?.results ?? []
                    setDocumentProcessData({
                        ...documentprocessdata,
                        test_id: response?.data?.test_id,
                        test_result: reposne_data?.output?.length > 0 ? reposne_data?.output : [],
                        total_pass: reposne_data?.total_pass,
                        total_tests: reposne_data?.total_tests,
                        test_status: reposne_data?.status_code === 200 || 206 ? 'success' : 'failed'
                    })
                    setIsResubmit(true)
                }
                return
            } else {
                tostAlert('File Not Selected or Invalid File', 'custom_warning')
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const columns = [
        {
            id: 'test_name_spec',
            label: 'Specification',
            width: '20%',
            sortable: true,
            renderCell: (row) => row?.test_name_spec
        },
        {
            id: 'test_name_document',
            label: 'Document',
            width: '20%',
            sortable: true,
            renderCell: (row) => row?.test_name_document
        },
        {
            id: 'result_value',
            label: 'Report Value',
            width: '20%',
            sortable: true,
            renderCell: (row) => {
                return row?.status === "pass" ?
                    (<Box>
                        {row?.result_value}&nbsp;{row?.uom}
                    </Box>) :
                    (<Typography sx={{ ...fontStyles.smallText, color: "#C30E2E", fontWeight: 'bold' }}>
                        UNKNOWN
                    </Typography>);
            }
        },
        {
            id: 'sample_id',
            label: 'Sample Id',
            width: '20%',
            sortable: true,
            renderCell: (row) => row?.sample_id
        },
        {
            id: 'status',
            label: 'Result',
            width: '20%',
            sortable: true,
            renderCell: (row) => (<Box sx={{ display: 'flex', alignItems: 'center', alignSelf: 'center' }}>
                {row?.status === "pass" ?
                    (<Box marginRight={'30px'}>
                        <ReactSVG src={IconSvg.resultCorrectIcon} />
                    </Box>) :
                    (<Box marginRight={'30px'} display={'flex'} alignItems={'center'}>
                        <ReactSVG src={IconSvg.resultErrorIcon} />
                        <Button
                            sx={{ ...buttonStyles.tertiaryBtn, width: '80px', marginLeft: '10px' }}
                            onClick={() => handleTrainQmsModel(documentprocessdata.test_id)}
                        >
                            <Typography style={{ ...fontStyles.btnSmallText, fontWeight: 'bold' }}>Train</Typography>
                        </Button>
                    </Box>)
                }
            </Box>)
        }
    ];

    const toggleDrawer = (open) => (event) => {
        setChatDrawer({ ...chatdrawer, state: open });
    };

    const handleClearChatInfo = async (chatStart, errorMessage) => {
        if (!chatStart) {
            tostAlert(errorMessage, 'error')
        }
        dispatch(setAuditTestHistoryAdded(true))
        setChatDrawer({ ...chatdrawer, state: false });
        setQmsChatData(initialQmsChat);
        setRenderableItems(initialQmsChat)
        setQmsTestId(null)
        setUserPrompt('')
    }

    const createChatFlow = async (item) => {
        return [
            {
                sender: 'system',
                testId: qmsTestId,
                questionId: item?.question_id,
                chat: item?.text,
                isProcessed: false,
                feedBackOptions: item?.feedback_options,
            },
            {
                sender: 'user',
                testId: qmsTestId,
                questionId: item?.question_id,
                chat: '',
                isProcessed: false,
                feedBackOptions: item?.feedback_options,
            }
        ];
    }

    const getChatQuestions = async (responseData) => {
        try {
            const system_message_string = responseData?.system_message?.trim()?.replace(/someUnexpectedChar/g, '');
            const system_message = system_message_string ? JSON.parse(system_message_string) : null;
            return Array.isArray(system_message?.questions) ? system_message.questions : [];
        } catch (error) {
            return [];
        }
    };

    const handleTrainQmsModel = async (testId) => {
        try {
            setChatMessageLoading(true)

            await handleClearChatInfo(true)
            setChatDrawer({ ...chatdrawer, state: true });
            setQmsTestId(testId);

            const response = await AuditToolApi.trainQmsModel(testId)
            if (response.status === 200) {
                const responseData = response?.data
                const initiate_chat = responseData?.initate_chat
                const questions_data = await getChatQuestions(responseData)

                if (initiate_chat && questions_data?.length > 0) {
                    const updatedQuestionsData = questions_data?.map((item) => ({ ...item, question_id: uuid(), is_processed: false }));
                    const promises = updatedQuestionsData?.map(async (item) => await createChatFlow(item));
                    const chatFlows = await Promise.all(promises);
                    const allChatFlows = chatFlows.flat();

                    const newChat = {
                        train_chat_data: allChatFlows,
                        train_response: [],
                        train_feedback_thumbs_options: [],
                        train_feedback_chat_options: [],
                        train_continue_options: [],
                        train_user_Prompt: {
                            user_prompt_status: 'inactive',
                            user_prompt_message: ''
                        },
                        pagination: {
                            page: 1,
                            rowsPerPage: 20,
                            totalPages: 1,
                        },
                    };
                    setQmsChatData({ chats: [newChat] })
                } else {
                    await handleClearChatInfo(false, responseData.error || 'Training Failed')
                }
            } else {
                await handleClearChatInfo(false, response?.data?.error || 'Training Failed')
            }
        } catch (error) {
            console.log(error)
            await handleClearChatInfo(false, error.message || "An unexpected error occurred")
        } finally {
            setChatMessageLoading(false)
        }
    }

    const handleQmsChatData = async (chat_index, questionId, chat_feedback_type) => {
        const updatedQmsChatData = qmsChatData?.chats.map((chat, index) => {
            if (index === chat_index) {
                return {
                    ...chat,
                    train_chat_data: chat.train_chat_data.map((item) => {
                        if (item?.questionId === questionId) {
                            if (item?.sender === 'system') {
                                return { ...item, isProcessed: true };
                            }
                            if (item?.sender === 'user') {
                                return { ...item, isProcessed: true, chat: chat_feedback_type };
                            }
                        }
                        return item;
                    }),
                };
            }
            return chat;
        });
        return { chats: updatedQmsChatData };
    };

    const handleQmsChat = async (chat_index, questionId, chat_feedback_type) => {
        setChatMessageLoading(true)
        try {
            if (qmsChatData?.chats?.length > 0) {
                const updatedQmsChatData = await handleQmsChatData(chat_index, questionId, chat_feedback_type)
                setQmsChatData(updatedQmsChatData);

                const filterdUpdatedQmsChatData = updatedQmsChatData?.chats?.[chat_index]?.train_chat_data?.filter((item) => !item?.isProcessed)
                if (filterdUpdatedQmsChatData?.length === 0) {

                    const canReqObjs = [];

                    for (let i = 0; i < updatedQmsChatData?.chats?.[chat_index]?.train_chat_data?.length; i += 2) {
                        const canReqObj = {};

                        const currentItem = updatedQmsChatData?.chats?.[chat_index]?.train_chat_data?.[i];
                        const nextItem = updatedQmsChatData?.chats?.[chat_index]?.train_chat_data?.[i + 1];

                        if (currentItem?.sender === 'system') {
                            canReqObj.system = currentItem.chat;
                        }

                        if (nextItem?.sender === 'user') {
                            canReqObj.user = nextItem.chat;
                        }

                        if (canReqObj.system && canReqObj.user) {
                            canReqObjs.push(canReqObj);
                        }
                    }

                    const reqObj = {
                        "test_id": qmsTestId,
                        "chats": canReqObjs
                    }

                    setLoading(true)

                    const response = await AuditToolApi.qmsUserChatMessage(reqObj);
                    if (response.status === 200) {

                        const responseData = response?.data
                        const testOutput = responseData?.results?.output ?? []
                        const feedbackThumbsOptions = responseData?.results?.feedback_options ?? [];

                        if (testOutput?.length === 0 || feedbackThumbsOptions?.length === 0) {
                            await handleClearChatInfo(false, responseData?.error ?? 'Training Failed')
                        } else {
                            setQmsChatData((prevState) => ({
                                ...prevState,
                                chats: prevState.chats.map((chat, idx) => idx === chat_index ? {
                                    ...chat,
                                    train_response: [...(chat.train_response || []), ...testOutput],
                                    train_feedback_thumbs_options: [...(chat.train_feedback_thumbs_options || []), ...feedbackThumbsOptions],
                                    pagination: {
                                        ...chat?.pagination,
                                        rowsPerPage: 20,
                                        totalPages: Math.ceil((chat?.train_response?.length || 0) / chat?.pagination?.rowsPerPage),
                                        page: chat?.pagination?.page <= Math.ceil((chat?.train_response?.length || 0) / chat?.pagination?.rowsPerPage) ? chat?.pagination?.page : 1,
                                    }
                                } : chat)
                            }));
                        }
                    }
                    else {
                        await handleClearChatInfo(false, response?.data?.error || "An unexpected error occurred")
                    }
                }
            } else {
                await handleClearChatInfo(false, 'Training Failed')
            }
        } catch (error) {
            await handleClearChatInfo(false, error.message || "An unexpected error occurred")
            console.log(error)
        } finally {
            setLoading(false)
            setChatMessageLoading(false)
        }
    };

    const handleUserThumbsFeedback = async (chat_index, feed_back_type) => {
        setChatMessageLoading(true)
        try {

            if (feed_back_type === "thumbs_up") {

                const canReqObjs = [];
                for (let i = 0; i < qmsChatData?.chats?.[chat_index]?.train_chat_data?.length; i += 2) {
                    const canReqObj = {};

                    const currentItem = qmsChatData?.chats?.[chat_index]?.train_chat_data?.[i];
                    const nextItem = qmsChatData?.chats?.[chat_index]?.train_chat_data?.[i + 1];

                    if (currentItem?.sender === 'system') {
                        canReqObj.system = currentItem.chat;
                    }

                    if (nextItem?.sender === 'user') {
                        canReqObj.user = nextItem.chat;
                    }

                    if (canReqObj.system && canReqObj.user) {
                        canReqObjs.push(canReqObj);
                    }
                }

                const reqObj = {
                    "test_id": qmsTestId,
                    "thumbs_ups": feed_back_type,
                    "chats": canReqObjs
                }
                const thumbsUpResponse = await AuditToolApi.qmsUserChatMessage(reqObj);
                if (thumbsUpResponse.status === 200) {
                    tostAlert('Training Completed Successfully', 'success')
                    await handleClearChatInfo(true);
                    handleDocuments()
                    return
                } else {
                    await handleClearChatInfo(false, 'Training Failed')
                    return
                }
            }

            setQmsChatData((prevState) => ({
                ...prevState,
                chats: prevState.chats.map((chat, idx) => idx === chat_index ? {
                    ...chat,
                    train_feedback_thumbs_options: [feed_back_type],
                } : chat)
            }));

            const reqObj = {
                "test_id": qmsTestId,
                "thumbs_ups": feed_back_type
            }
            const response = await AuditToolApi.qmsUserChatMessage(reqObj);
            if (response.status === 200) {

                const responseData = response?.data
                const feedbackChatsOptions = responseData?.results?.feedback_options ?? [];

                if (feedbackChatsOptions?.length === 0) {
                    await handleClearChatInfo(false, responseData?.error || 'Training Failed')
                } else {
                    setQmsChatData((prevState) => ({
                        ...prevState,
                        chats: prevState.chats.map((chat, idx) => idx === chat_index ? {
                            ...chat,
                            train_feedback_chat_options: [...(chat.train_feedback_chat_options || []), ...feedbackChatsOptions],
                            train_feedback_thumbs_options: [],
                        } : chat)
                    }));
                }
            } else {
                await handleClearChatInfo(false, response?.data?.error || "An unexpected error occurred")
            }
        } catch (error) {
            await handleClearChatInfo(false, error.message || "An unexpected error occurred")
            console.log(error)
        } finally {
            setChatMessageLoading(false)
        }
    }

    const handleUserChatFeedback = async (chat_index, feed_back_type) => {
        setChatMessageLoading(true)

        try {

            setQmsChatData((prevState) => ({
                ...prevState,
                chats: prevState.chats.map((chat, idx) => idx === chat_index ? {
                    ...chat,
                    train_feedback_chat_options: [feed_back_type],
                } : chat)
            }));

            if (feed_back_type === "start_fresh") {

                const reqObj = {
                    "test_id": qmsTestId,
                    "user_action": feed_back_type,
                    // "continue_options": feed_back_type
                }

                const response = await AuditToolApi.qmsUserChatMessage(reqObj);
                if (response.status === 200) {
                    const responseData = response?.data
                    const questions_data = await getChatQuestions(responseData)

                    if (questions_data?.length > 0) {
                        const updatedQuestionsData = questions_data?.map((item) => ({ ...item, question_id: uuid(), is_processed: false }));
                        const promises = updatedQuestionsData?.map(async (item) => await createChatFlow(item));
                        const chatFlows = await Promise.all(promises);
                        const allChatFlows = chatFlows.flat();

                        const newChat = {
                            train_chat_data: allChatFlows,
                            train_response: [],
                            train_feedback_thumbs_options: [],
                            train_feedback_chat_options: [],
                            train_continue_options: [],
                            train_user_Prompt: {
                                user_prompt_status: 'inactive',
                                user_prompt_message: ''
                            },
                            pagination: {
                                page: 1,
                                rowsPerPage: 20,
                                totalPages: 1,
                            },
                        };

                        setQmsChatData((prevState) => ({
                            chats: [
                                ...prevState.chats.map((chat) => ({
                                    ...chat,
                                    train_continue_options: [],
                                    train_feedback_chat_options: []
                                })),
                                newChat
                            ]
                        }));
                    } else {
                        await handleClearChatInfo(false, responseData.error || 'Training Failed')
                    }
                } else {
                    await handleClearChatInfo(false, response.data?.error || 'Training Failed')
                }
            } else {
                await handleClearChatInfo(true)
                dispatch(setAuditTestHistoryAdded(true))
                handleDocuments()
                tostAlert('Training Completed Successfully', 'success')
            }




            // if (feed_back_type === "continue") {
            //     setQmsChatData((prevState) => ({
            //         ...prevState,
            //         chats: prevState.chats.map((chat, idx) => idx === chat_index ? {
            //             ...chat,
            //             train_feedback_chat_options: [],
            //             train_continue_options: ['retake', 'reassess'],
            //         } : chat)
            //     }));
            //     return
            // }

            // const reqObj = {
            //     "test_id": qmsTestId,
            //     "user_action": feed_back_type
            // }

            // const response = await AuditToolApi.qmsUserChatMessage(reqObj);
            // if (response.status === 200) {
            //     const responseData = response?.data

            // if (feed_back_type === "start_fresh") {
            //     const testOutput = responseData?.results?.output ?? []
            //     const feedbackThumbsOptions = responseData?.results?.feedback_options ?? [];

            //     if (testOutput?.length === 0 || feedbackThumbsOptions?.length === 0) {
            //         await handleClearChatInfo(false, responseData.error || 'Training Failed')
            //     } else {
            //         setQmsChatData((prevState) => ({
            //             ...prevState,
            //             chats: prevState.chats.map((chat, idx) => idx === chat_index ? {
            //                 ...chat,
            //                 train_response: [...testOutput],
            //                 train_feedback_thumbs_options: [...feedbackThumbsOptions],
            //                 train_feedback_chat_options: []
            //             } : chat)
            //         }));
            //     }
            // }



            // } else {
            //     await handleClearChatInfo(false, response.data?.error || 'Training Failed')
            // }
        } catch (error) {
            await handleClearChatInfo(false, error.message || "An unexpected error occurred")
            console.log(error)
        } finally {
            setChatMessageLoading(false)
        }
    }

    const handleUserContinueOptions = async (chat_index, feed_back_type) => {
        setChatMessageLoading(true)
        try {

            setQmsChatData((prevState) => ({
                ...prevState,
                chats: prevState.chats.map((chat, idx) => idx === chat_index ? {
                    ...chat,
                    train_continue_options: [feed_back_type],
                } : chat)
            }));

            if (feed_back_type === "reassess") {
                setQmsChatData((prevState) => ({
                    ...prevState,
                    chats: prevState.chats.map((chat, idx) => idx === chat_index ? {
                        ...chat,
                        train_continue_options: [],
                        train_user_Prompt: {
                            user_prompt_status: 'active',
                            user_prompt_message: ''
                        }
                    } : chat)
                }));
                return
            }

            if (feed_back_type === "retake") {

                const reqObj = {
                    "test_id": qmsTestId,
                    "user_action": 'continue',
                    "continue_options": feed_back_type
                }

                const response = await AuditToolApi.qmsUserChatMessage(reqObj);
                if (response.status === 200) {
                    const responseData = response?.data
                    const questions_data = await getChatQuestions(responseData)

                    if (questions_data?.length > 0) {
                        const updatedQuestionsData = questions_data?.map((item) => ({ ...item, question_id: uuid(), is_processed: false }));
                        const promises = updatedQuestionsData?.map(async (item) => await createChatFlow(item));
                        const chatFlows = await Promise.all(promises);
                        const allChatFlows = chatFlows.flat();

                        const newChat = {
                            train_chat_data: allChatFlows,
                            train_response: [],
                            train_feedback_thumbs_options: [],
                            train_feedback_chat_options: [],
                            train_continue_options: [],
                            train_user_Prompt: {
                                user_prompt_status: 'inactive',
                                user_prompt_message: ''
                            },
                            pagination: {
                                page: 1,
                                rowsPerPage: 20,
                                totalPages: 1,
                            },
                        };

                        setQmsChatData((prevState) => ({
                            chats: [
                                ...prevState.chats.map((chat) => ({
                                    ...chat,
                                    train_continue_options: []
                                })),
                                newChat
                            ]
                        }));
                        return
                    } else {
                        await handleClearChatInfo(false, responseData.error || 'Training Failed')
                    }
                } else {
                    await handleClearChatInfo(false, response.data?.error || 'Training Failed')
                }
            }

        } catch (error) {
            await handleClearChatInfo(false, error.message || "An unexpected error occurred")
            console.log(error)
        } finally {
            setChatMessageLoading(false)
        }
    }

    const handleUserPrompt = async (chat_index, user_prompt) => {
        setChatMessageLoading(true)
        try {

            const reqObj = {
                "test_id": qmsTestId,
                "user_action": 'continue',
                "continue_options": 'reassess',
                "user_promt": user_prompt
            }

            const response = await AuditToolApi.qmsUserChatMessage(reqObj);
            if (response.status === 200) {

                const responseData = response?.data
                const questions_data = await getChatQuestions(responseData)

                if (questions_data?.length > 0) {

                    const updatedQuestionsData = questions_data?.map((item) => ({ ...item, question_id: uuid(), is_processed: false }));
                    const promises = updatedQuestionsData?.map(async (item) => await createChatFlow(item));
                    const chatFlows = await Promise.all(promises);
                    const allChatFlows = chatFlows.flat();

                    const newChat = {
                        train_chat_data: allChatFlows,
                        train_response: [],
                        train_feedback_thumbs_options: [],
                        train_feedback_chat_options: [],
                        train_continue_options: [],
                        train_user_Prompt: {
                            user_prompt_status: 'inactive',
                            user_prompt_message: ''
                        },
                        pagination: {
                            page: 1,
                            rowsPerPage: 20,
                            totalPages: 1,
                        },
                    };

                    setQmsChatData((prevState) => ({
                        chats: [
                            ...prevState.chats.map((chat, index) =>
                                index === chat_index
                                    ? {
                                        ...chat,
                                        train_user_Prompt: {
                                            user_prompt_status: 'closed',
                                            user_prompt_message: user_prompt
                                        }
                                    }
                                    : chat
                            ),
                            newChat
                        ]
                    }));
                    setUserPrompt('')
                    return
                } else {
                    await handleClearChatInfo(false, responseData.error || 'Training Failed')
                }
            } else {
                await handleClearChatInfo(false, response.data?.error || 'Training Failed')
            }
        } catch (error) {
            await handleClearChatInfo(false, error.message || "An unexpected error occurred")
            console.log(error)
        } finally {
            setChatMessageLoading(false)
        }
    }

    return (
        <Box sx={Styles.mainContainer}>
            <Box sx={Styles.cardContainer}>
                {loading && (<LoadBar />)}

                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                    <Typography sx={fontStyles.largeText}>Playground</Typography>

                    <Button sx={{ ...buttonStyles.primaryBtn, width: '100px', height: '35px' }}
                        disabled={loading}
                        onClick={() => handleDocuments()}
                    >
                        {
                            isResubmit ?
                                (<Typography style={fontStyles.btnText}>RESUBMIT</Typography>)
                                :
                                (<Typography style={fontStyles.btnText}>SUBMIT</Typography>)
                        }
                    </Button>
                </Box>

                <Typography sx={{ ...fontStyles.smallText, marginTop: '10px' }}>
                    Test AI QMS Agent
                </Typography>

                <Box marginTop={'20px'} display={'flex'} alignItems={'center'}>

                    <FormControl>
                        <Typography sx={{ ...fontStyles.smallText, marginBottom: '6px' }}>Select a document</Typography>
                        <Autocomplete
                            sx={{ ...componentsStyles.autocomplete, width: '300px' }}
                            options={coafiles}
                            getOptionLabel={(option) => option?.file_name}
                            id="clear-on-escape"
                            clearOnEscape
                            value={selectedpdffile}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" placeholder="Document" />
                            )}
                            onChange={handlePdfFileSelection}
                        />
                    </FormControl>

                    <FormControl sx={{ marginLeft: '10px' }}>
                        <Typography sx={{ ...fontStyles.smallText, marginBottom: '6px' }}>Select a specification</Typography>
                        <Autocomplete
                            sx={{ ...componentsStyles.autocomplete, width: '300px' }}
                            options={specificationfiles}
                            getOptionLabel={(option) => option?.file_name}
                            id="clear-on-escape"
                            clearOnEscape
                            value={selectedjsonfile}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" placeholder="Specification" />
                            )}
                            onChange={handleJsonFileSelection}
                        />
                    </FormControl>

                </Box>

                <Box marginTop={'30px'} paddingBottom={'20px'}>
                    <Typography sx={fontStyles.mediumText}>Results</Typography>
                    <Box marginTop={'10px'} paddingBottom={'20px'}>
                        <GeneralTable
                            data={documentprocessdata?.test_result}
                            columns={columns}
                            loading={loading}
                        />
                    </Box>
                </Box>

                <ChatHandler
                    chatdrawer={chatdrawer}
                    toggleDrawer={toggleDrawer}
                    chatType={chatType}
                    chatMessageLoading={chatMessageLoading}

                    setQmsChatData={setQmsChatData}
                    qmsChatData={qmsChatData}

                    setRenderableItems={setRenderableItems}
                    renderableItems={renderableItems}

                    setUserPrompt={setUserPrompt}
                    userPrompt={userPrompt}

                    handleQmsChat={handleQmsChat}
                    handleUserThumbsFeedback={handleUserThumbsFeedback}
                    handleUserChatFeedback={handleUserChatFeedback}
                    handleUserContinueOptions={handleUserContinueOptions}
                    handleUserPrompt={handleUserPrompt}
                />
            </Box>
        </Box >
    )
}

export default AuditToolPlayground;