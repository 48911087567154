import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useStyles } from './styles';
import SubSidebar from './Sidebar';

import { getSessionData, InvalidAccess } from '../../utils/sessionDataHandle';
import DocSearchFiles from './DocumentSearch/DocSearchFiles';
import AuditToolFiles from './AuditTool/AuditToolFiles';
import LLMDocSearchFiles from './LLMDocSearch/LLMDocSearchFiles';
import SourceType from './Analytics/SourceType';
import DataBaseSource from './Analytics/DatabaseSource/DataBaseSource';
import FileSource from './Analytics/FileSource/FileSource';

function DataSourceHome() {

    const theme = useTheme();
    const styles = useStyles(theme);
    const navigate = useNavigate();
    const location = useLocation();
    const [sourceTypeSelected, setSourceSelected] = useState(false)
    const [selectedSource, setSelectedSource] = useState('');

    const selected_space = getSessionData("selected_space")
    const selected_project = getSessionData("selected_project")

    const handleMenuChange = (engine, path) => {
        if (engine === 'analytics_tool' && !path) {
            navigate(`/datasource/analytics-tool/files`)
            return
        }
        if (engine && path) {
            navigate(`/datasource/${engine?.toLowerCase()?.replace(/_/g, '-')}/${path?.toLowerCase()?.replace(/_/g, '-')}`);
        }
    };

    useEffect(() => {
        const selected_engine_name = selected_project?.payload?.configuration?.engine_name

        if (!selected_space || !selected_project) {
            InvalidAccess(navigate);
            return
        }

        if (location.pathname === '/datasource') {
            if (selected_engine_name === "qms_audit_tool") {
                navigate('/datasource/qms-audit-tool/files');
            }
            if (selected_engine_name === "doc_search") {
                navigate('/datasource/doc-search/files');
            }
            if (selected_engine_name === "llm_doc_search") {
                navigate('/datasource/llm-doc-search/files');
            }
            if (selected_engine_name === "analytics_tool") {
                if (!sourceTypeSelected && !selectedSource) {
                    navigate('/datasource/analytics-tool/sources');
                    return
                }
                if (sourceTypeSelected && selectedSource) {
                    setSourceSelected(false)
                    setSelectedSource('')
                    navigate('/datasource/analytics-tool/sources');
                    return
                }
                if (selectedSource) {
                    navigate(`/datasource/analytics-tool/${selectedSource}`);
                }
            }
        }
    }, [location.pathname, navigate, sourceTypeSelected]);


    return (
        <Box sx={styles.mainContainer}>
            {
                (selected_project?.payload?.configuration?.engine_name === "analytics_tool") && !sourceTypeSelected ?
                    (<Routes>
                        <Route
                            path="analytics-tool/sources/*"
                            element={
                                <SourceType
                                    setSourceSelected={setSourceSelected}
                                    setSelectedSource={setSelectedSource}
                                />}
                        />
                    </Routes>)
                    :
                    (<>
                        <Box sx={styles.sidebar}>
                            <SubSidebar handleMenuChange={handleMenuChange} />
                        </Box>
                        <main style={styles.componentContainer}>
                            <Box sx={styles.cardContainer}>
                                <Routes>
                                    <Route path="qms-audit-tool/files" element={<AuditToolFiles />} />
                                    <Route path="doc-search/files" element={<DocSearchFiles />} />
                                    <Route path="llm-doc-search/files" element={<LLMDocSearchFiles />} />
                                    <Route path="analytics-tool/database/*" element={<DataBaseSource />} />
                                    <Route path="analytics-tool/files/*" element={<FileSource />} />
                                </Routes>
                            </Box>
                        </main>
                    </>)
            }

        </Box>
        // <Box sx={styles.mainContainer}>
        //     {
        //         (selected_project?.payload?.configuration?.engine_name === "mongodb_analytics_tool" ||
        //             selected_project?.payload?.configuration?.engine_name === "file_analytics_tool" ||
        //             selected_project?.payload?.configuration?.engine_name === "analytics_tool") && !sourceTypeSelected
        //             ?
        //             (<>
        //                 <SourceType setSourceSelected={setSourceSelected} setSelectedSource={setSelectedSource} />
        //             </>)
        //             :
        //             (<>
        //                 <Box sx={styles.sidebar}>
        //                     <SubSidebar handleMenuChange={handleMenuChange} />
        //                 </Box>
        //                 <main style={styles.componentContainer}>
        //                     <Box sx={styles.cardContainer}>
        //                         <Routes>
        //                             <Route path="qms-audit-tool/files" element={<AuditToolFiles />} />
        //                             <Route path="doc-search/files" element={<DocSearchFiles />} />
        //                             <Route path="llm-doc-search/files" element={<LLMDocSearchFiles />} />
        //                             <Route path="analytics-tool/database" element={<DataBaseSource />} />
        //                             <Route path="mongodb-analytics-tool/database" element={<DataBaseSource />} />
        //                             <Route path="mongodb-analytics-tool/files" element={<DataBaseSource />} />
        //                         </Routes>
        //                     </Box>
        //                 </main>
        //             </>)
        //     }

        // </Box>
    )
}

export default DataSourceHome;