export const getStyles = (theme) => ({
    mainContainer: {
        width: "100%",
        height: '100vh',
    },
    cardContainer: {
        padding: '20px 20px 0px 20px'
    },
    paraText: {
        fontSize: theme.typography.h6,
        fontWeight: 400,
    },
    mainHeadingText: {
        fontSize: theme.typography.h4,
        fontWeight: 600
    },
    saveBtn: {
        height: '35px',
        width: '100px',
        borderRadius: '20px',
        border: 'none',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#0B51C5',
        color: "#FFFFFF"
    },
    cancelBtn: {
        height: '35px',
        width: '100px',
        borderRadius: '20px',
        border: 'none',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#DEDEDE',
        color: '#404040'
    },
    btnText: {
        fontSize: theme.typography.h5,
        fontWeight: 600,
    }
})