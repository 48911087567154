import AnalyticsApi from "../../Api/Services/AmeyaBackend/AnalyticsToolApi";

const AnalyticsFilesData = async () => {

    const getAllAnalyticsFiles = async () => {
        const tempArr = []
        try {
            const response = await AnalyticsApi.getFileList();
            if (response.data) {
                const response_data = response?.data ?? []
                const result = response_data?.result ?? []
                tempArr.push(...result)
            }
            return tempArr;
        }
        catch (error) {
            console.error(error)
        }
    }

    return getAllAnalyticsFiles();
};

export default AnalyticsFilesData;
