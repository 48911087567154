export const getStyles = (theme) => ({
    mainContainer: {
        width: "100%",
        height: '100vh',
    },
    cardContainer: {
        padding: '20px 20px 0px 20px'
    },
    mainHeadingText: {
        fontSize: theme.typography.h4,
        fontWeight: 600,
        color: 'black'
    },
    btnText: {
        fontSize: theme.typography.h6,
        fontWeight: 600,
        textTransform: 'none'
    },
    paraText: {
        fontSize: theme.typography.h6,
        fontWeight: 400,
    },
    linkText: {
        fontSize: theme.typography.h4,
        fontWeight: 400
    },
})