
export const handleSidebarConfig = async (selected_space, selected_project, navigate) => {

    const selected_space_id = selected_space?.payload?.__auto_id__ ?? null
    const selected_project_id = selected_project?.payload?.__auto_id__ ?? null

    const sidebar_config_order = selected_project?.payload?.configuration?.engine_config?.sidebar_items ?? [];
    const main_sidebar_order = ['Insights', 'Sources', 'Settings', 'Logs', 'Launch'];
    const sidebar_order = [...Object?.keys(sidebar_config_order)?.map(item => item.charAt(0).toUpperCase() + item.slice(1))];

    const sortSidebarItems = sidebar_order.slice().sort((a, b) => {
        const indexA = main_sidebar_order.indexOf(a);
        const indexB = main_sidebar_order.indexOf(b);
        return (indexA === -1 ? Infinity : indexA) - (indexB === -1 ? Infinity : indexB);
    });
    
    switch (sortSidebarItems?.[0]) {
        case 'Insights':
            navigate(`/spaces/${selected_space_id}/projects/${selected_project_id}/insights`)
            break;
        case 'Sources':
            navigate('/datasource')
            break;
        case 'Settings':
            navigate('/settings')
            break;
        case 'Logs':
            navigate('/logs')
            break;
        case 'Launch':
            navigate('/launch')
            break;
        default:
            navigate(`/spaces/${selected_space_id}/projects`)
    }
    return
}