export const useStyles = (theme) => ({
    mainContainer: {
        width: "100%",
        height: '100vh',
    },
    cardContainer: {
        padding: '20px 20px 0px 20px'
    },
    chatBotButtonContainer: {
        position: 'fixed',
        bottom: 20,
        right: 56,
        zIndex: 9999999999999
    },
    chatBotButton: {
        height: '60px',
        width: '60px',
        borderRadius: '50%',
        color: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19)',
        animation: 'slideIn 0.5s ease-out',
        '@keyframes fadeIn': {
            from: { opacity: 0 },
            to: { opacity: 1 },
        },
        '@keyframes fadeOut': {
            from: { opacity: 1 },
            to: { opacity: 0 },
        },
        '@keyframes slideIn': {
            from: { transform: 'translateY(100%)' },
            to: { transform: 'translateY(0)' },
        },
        '@keyframes slideOut': {
            from: { transform: 'translateY(0)' },
            to: { transform: 'translateY(100%)' },
        },
    },
    mainHeadingText: {
        fontSize: theme.typography.h2,
        fontWeight: 600,
        color: 'black'
    },
    paraText: {
        fontSize: theme.typography.h6,
        fontWeight: 400,
    }
})