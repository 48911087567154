import { AxiosDocSearchObj } from "../../Configurations/axios-ameya-setup";
import { fetchAccountId, fetchSubscriberId, fetchSubscriptionId } from "../../../utils/GetAccountDetails"

class ameyaBackendApi {

    constructor() {
        this.accountId = null;
        this.subscribeId = null;
        this.subscriptionId = null;
    }

    async initialize() {
        this.accountId = await fetchAccountId();
        this.subscriberId = await fetchSubscriberId();
        this.subscriptionId = await fetchSubscriptionId();
    }

    uploadDraftPdfFile = async (reqObj) => {
        await this.initialize();
        return AxiosDocSearchObj.post(`/user/subscriber/${this.subscriberId}/subscription/${this.subscriptionId}/function/managedocuments/adddraftdocument/event/documentsearch_draft_upload`, reqObj)
    }

    fetchUploadedFiles = async (reqObj) => {
        await this.initialize();
        return AxiosDocSearchObj.post(`/platform/subscriber/${this.subscriberId}/subscription/${this.subscriptionId}/function/managedocuments/getdocument/event/documentsearch_get_files`, reqObj)
    }

    deleteUploadedFiles = async (reqObj) => {
        await this.initialize();
        return AxiosDocSearchObj.post(`/user/subscriber/${this.subscriberId}/subscription/${this.subscriptionId}/function/managedocuments/archivedocument/event/documentsearch_archive`, reqObj)
    }

    documentSearchQuery = async (reqObj) => {
        await this.initialize();
        return AxiosDocSearchObj.post(`/user/subscriber/${this.subscriberId}/subscription/${this.subscriptionId}/function/searchdocument/event/documentsearch_search`, reqObj)
    }

    getDocsearchFilesCounts = async (reqObj) => {
        await this.initialize();
        return AxiosDocSearchObj.post(`/platform/subscriber/${this.subscriberId}/subscription/${this.subscriptionId}/function/managedocuments/get_document_count/event/documentsearch_document_count`, reqObj)
    }

}

const DocumentSearchApi = new ameyaBackendApi();
export default DocumentSearchApi;


