import Spaces from "../containers/Spaces/Spaces";
import CreateSpace from "../containers/Spaces/CreateSpace";

import CreateProject from "../containers/Projects/CreateProject";
import Projects from "../containers/Projects/ViewProjects";

import InsightsHome from "../containers/Insights/InsightsHome";
import DataSourceHome from "../containers/DataSource/DataSourceHome";
import SettingsHome from "../containers/Settings/SettingsHome";
import LaunchHome from "../containers/Launch/LaunchHome";
import ElasticLogs from "../containers/ElasticLogs/ElasticLogs";
import LogsHome from "../containers/Logs";

export const routesConfig = [
    {
        path: '/',
        element: <Spaces />,
        component: 'Spaces'
    },
    {
        path: '/spaces/add',
        element: <CreateSpace />,
        component: 'Spaces'
    },

    {
        path: '/spaces/:space_id/projects',
        element: <Projects />,
        component: 'Projects'
    },
    {
        path: '/projects',
        element: <Projects />,
        component: 'Projects'
    },
    {
        path: '/spaces/:space_id/projects/add',
        element: <CreateProject />,
        component: 'Projects'
    },

    {
        path: '/spaces/:space_id/projects/:project_id/insights',
        element: <InsightsHome />,
        component: 'Insights'
    },

    {
        path: '/datasource/*',
        element: <DataSourceHome />,
        component: 'Sources'
    },

    {
        path: '/settings/*',
        element: <SettingsHome />,
        component: 'Settings'
    },

    {
        path: '/logs/*',
        element: <LogsHome />,
        component: 'Logs'
    },

    {
        path: '/launch/*',
        element: <LaunchHome />,
        component: 'Launch'
    }
];