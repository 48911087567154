import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSVG } from "react-svg";
import moment from 'moment';

import { Box, Button, TextField, Typography, Grid2 as Grid, Card, CardContent } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { getComponentsStyles } from '../../styles/componentsStyles';
import { useStyles } from './Styles';
import { fontStyles } from '../../styles/fontStyles';
import { buttonStyles } from '../../styles/buttonStyles';

import LoadBar from '../../utils/LoadBar';
import { tostAlert } from '../../utils/AlertToast';
import { AlertMessages } from '../../utils/AlertMessages';
import { IconSvg } from '../../utils/globalIcons';
import { apiErrorHandler } from '../../utils/ApiErrorHandler';

import { setProjectAdded, setAppflyteEngineAdded } from "../../Redux/slice/newDataSlice";
import { setAppflyteEngineState } from "../../Redux/slice/dataSlice";
import ProjectsApi from '../../Api/Services/AppflyteBackend/ProjectsApi';
import getAppflyteEnginesData from '../../utils/ApiFunctions/AppflyteEngines';
import { getUserName } from '../../utils/GetAccountDetails';
import { getMainStyles } from '../../styles/styles';
import { getSessionData } from '../../utils/sessionDataHandle';

function CreateProject() {

    const theme = useTheme();
    const styles = useStyles(theme);
    const mainStyles = getMainStyles(theme);
    const componentsStyles = getComponentsStyles(theme);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [appflyteEngines, setAppflyteEngines] = useState([]);
    const [loading, setLoading] = useState(false)

    const projectDetails = {
        projectName: '',
        projectDescription: '',
        projectAiEngine: '',
        projectEngineConfig: {}
    }
    const [projectData, setProjectData] = useState(projectDetails);
    const [formErrors, setFormErrors] = useState({});

    // Redux States
    const all_engines = useSelector(state => state.all_data.appflyte_engines);
    const engine_added = useSelector(state => state.data_added.appflyte_engine_added);

    useEffect(() => {
        getAllAppflyteEngines();
        //eslint-disable-next-line
    }, [engine_added])

    const getAllAppflyteEngines = async () => {
        setLoading(true)
        let response = []
        if (all_engines?.length > 0 && engine_added === false) {
            response = all_engines
        } else {
            response = await getAppflyteEnginesData()
            dispatch(setAppflyteEngineState(response))
            dispatch(setAppflyteEngineAdded(false))
        }
        setAppflyteEngines(response)
        setLoading(false)
    }

    useEffect(() => {
        const errors = { ...formErrors };
        if (formErrors?.projectName && projectData?.projectName?.trim()) { delete errors?.projectName }
        if (formErrors?.projectDescription && projectData?.projectDescription?.trim()) { delete errors?.projectDescription }
        if (formErrors?.projectEngineConfig && Object?.keys(projectData.projectEngineConfig).length > 0) { delete errors?.projectEngineConfig }
        if (JSON.stringify(errors) !== JSON.stringify(formErrors)) { setFormErrors(errors) }
    }, [projectData, formErrors]);

    const handleAiEngineSelection = async (selected_engine_id, selected_engine_config) => {

        if (selected_engine_id === projectData?.projectAiEngine) {
            setProjectData({
                ...projectData,
                projectEngineConfig: {},
                projectAiEngine: ''
            })
            return
        }

        const engine_config = {
            "engine_name": selected_engine_config?.engine_name,
            "engine_config": selected_engine_config?.engine_config
        }
        setProjectData({
            ...projectData,
            projectEngineConfig: engine_config,
            projectAiEngine: selected_engine_id
        })
    }

    const handleCreateProject = async (e) => {
        setLoading(true);
        e.preventDefault();
        try {
            const errors = {};
            if (!projectData?.projectName?.trim()) { errors.projectName = 'Project name is required' }
            if (!projectData?.projectDescription?.trim()) { errors.projectDescription = 'Project description is required' }
            if (Object?.keys(projectData.projectEngineConfig).length === 0) { errors.projectEngineConfig = 'Ai Engine is required' }
            if (Object.keys(errors).length === 0) {
                const selected_space = getSessionData("selected_space")

                const collection_data = await ProjectsApi.checkNameExistence(projectData.projectName)
                if (collection_data.data && collection_data.data.published_collections_detail.length > 0) {
                    tostAlert('Project name already exists', 'warning')
                    return
                }

                const userName = getUserName()
                const projectObject = {
                    collection_item: {
                        name: projectData.projectName,
                        description: projectData.projectDescription,
                        workspace: [selected_space?.payload?.__auto_id__],
                        lookup_id: [projectData.projectAiEngine],
                        configuration: projectData.projectEngineConfig,
                        status: 'active',
                        created_by: userName,
                        created_on: moment().format("DD-MM-YYYY HH:mm:ss"),
                        history: [
                            {
                                updated_by: userName,
                                status: "active",
                                updated_date: moment().format("DD-MM-YYYY HH:mm:ss")
                            }
                        ],
                    }
                }
                const response = await ProjectsApi.createProject(JSON.stringify(projectObject));
                if (response.status === 200) {
                    setProjectData(projectDetails)
                    dispatch(setProjectAdded(true))
                    const message = await AlertMessages('create', 'project');
                    navigate(`/spaces/${selected_space?.payload?.__auto_id__}/projects`)
                    tostAlert(message, 'success')
                } else {
                    const message = await AlertMessages('error', 'project');
                    tostAlert(message, 'error')
                }
            }
            else {
                setFormErrors(errors);
            }
        } catch (error) {
            apiErrorHandler(error);
            console.error("Error creating workspace:", error);
        } finally {
            setLoading(false);
        }
    }

    const handleCancel = () => {
        const selected_space = getSessionData("selected_space")
        navigate(`/spaces/${selected_space?.payload?.__auto_id__}/projects`)
    }


    return (
        <div style={styles.mainContainer}>
            <Box sx={styles.cardContainer}>
                {loading && (<LoadBar />)}
                <Box padding={'20px'}>

                    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>

                        <Typography sx={styles.mainHeadingText}>Create Project</Typography>

                        <Box display={'flex'} alignItems={'center'}>

                            <Button
                                sx={{ ...buttonStyles.primaryBtn, width: '110px' }}
                                disabled={loading}
                                onClick={(e) => handleCreateProject(e)}
                            >
                                <Typography sx={styles.btnText}>CREATE</Typography>
                            </Button>

                            <Button
                                sx={{ ...buttonStyles.secondaryBtn, width: '110px', marginLeft: '10px' }}
                                onClick={handleCancel}>
                                <Typography sx={styles.btnText}>CANCEL</Typography>
                            </Button>

                        </Box>

                    </Box>

                    <Box marginTop={'20px'}>
                        <Box>
                            <TextField
                                id="app-name"
                                variant="outlined"
                                placeholder='App Name'
                                size='small'
                                sx={{ ...componentsStyles.textField, width: '40%' }}
                                value={projectData.projectName}
                                onChange={(e) => setProjectData({ ...projectData, projectName: e.target.value })}
                            />
                            {formErrors.projectName && <Typography sx={styles.errorText}>{formErrors.projectName}</Typography>}
                        </Box>
                        <Box marginTop={'20px'}>
                            <TextField
                                id="app-description"
                                variant="outlined"
                                placeholder='App Description'
                                size='medium'
                                sx={{ ...componentsStyles.textField, width: '40%' }}
                                value={projectData.projectDescription}
                                onChange={(e) => setProjectData({ ...projectData, projectDescription: e.target.value })}
                            />
                            {formErrors.projectDescription && <Typography sx={styles.errorText}>{formErrors.projectDescription}</Typography>}
                        </Box>
                    </Box>

                    <Box marginTop={'30px'}>

                        <Box display={'flex'} alignItems={'center'}>
                            <Typography sx={styles.subHeadingText}>Select an AI Engine</Typography>
                            {formErrors.projectEngineConfig && <Typography sx={{ ...styles.errorText, marginLeft: '20px' }}>{formErrors.projectEngineConfig}</Typography>}
                        </Box>

                        <Box marginTop={'10px'}>
                            <Grid container spacing={6}>

                                {appflyteEngines?.length > 0 ? appflyteEngines?.map((engine, index) => (
                                    <Grid size={{ xs: 6, md: 4 }} key={index}>
                                        <Card sx={{
                                            ...styles.aiEngineCard,
                                            backgroundColor: projectData.projectAiEngine === engine?.payload?.__auto_id__ ? '#D9D9D9' : '#FFFFFF'
                                        }}
                                            onClick={() => handleAiEngineSelection(engine?.payload?.__auto_id__, engine?.payload?.configuration)}
                                        >
                                            <CardContent sx={{ padding: '0' }}>
                                                <Box sx={styles.aiEngineCardHeader}>
                                                    <ReactSVG src={IconSvg[engine?.payload?.configuration?.engine_icon]} />
                                                    <Typography sx={styles.cardHeadingText}>{engine?.payload?.name?.replace(/_/g, ' ')}</Typography>
                                                </Box>
                                                <Box sx={styles.aiEngineCardBody}>
                                                    <Typography sx={styles.cardDescriptionText}>{engine?.payload?.description}</Typography>
                                                </Box>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                )) :
                                    (appflyteEngines?.length === 0 && !loading) && (
                                        <Box sx={mainStyles.noRecord}>
                                            <Typography sx={styles.noRecordsText}>No Records To Display</Typography>
                                        </Box>
                                    )}
                            </Grid>
                        </Box>
                    </Box>

                </Box>
            </Box>
        </div>
    )
}

export default CreateProject;