import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import { useTheme } from '@mui/material/styles';
import { getComponentsStyles } from '../../styles/componentsStyles';
import { getStyles } from './Styles';

import { fontStyles } from '../../styles/fontStyles';
import { buttonStyles } from '../../styles/buttonStyles';
import { Box, Button, TextField, Typography } from '@mui/material';

import LoadBar from '../../utils/LoadBar';
import { tostAlert } from '../../utils/AlertToast';
import { AlertMessages } from '../../utils/AlertMessages';
import { apiErrorHandler } from '../../utils/ApiErrorHandler';

import { setWorkspaceAddedAdded } from "../../Redux/slice/newDataSlice";
import WorkspaceApi from '../../Api/Services/AppflyteBackend/WorkspaceApi';
import { getUserName } from '../../utils/GetAccountDetails';

function CreateSpace() {
    const theme = useTheme();
    const styles = getStyles(theme);
    const componentsStyles = getComponentsStyles(theme);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const spaceDetails = {
        spaceName: '',
        spaceDescription: ''
    }
    const [spaceData, setSpaceData] = useState(spaceDetails);
    const [spaceLoading, setSpaceLoading] = useState(false);
    const [formErrors, setFormErrors] = useState({});

    const handleCancel = () => {
        navigate('/')
    }

    useEffect(() => {
        const errors = { ...formErrors };
        if (formErrors?.spaceName && spaceData?.spaceName.trim()) { delete errors?.spaceName }
        if (formErrors?.spaceDescription && spaceData?.spaceDescription.trim()) { delete errors?.spaceDescription }
        if (JSON.stringify(errors) !== JSON.stringify(formErrors)) { setFormErrors(errors) }
    }, [spaceData, formErrors]);

    const handleCreateSpace = async (e) => {
        setSpaceLoading(true);
        e.preventDefault();
        try {
            const errors = {};
            if (!spaceData.spaceName.trim()) { errors.spaceName = 'Space name is required' }
            if (!spaceData.spaceDescription.trim()) { errors.spaceDescription = 'Space description is required' }
            if (Object.keys(errors).length === 0) {

                const collection_data = await WorkspaceApi.checkNameExistence(spaceData.spaceName)
                if (collection_data.data && collection_data.data.published_collections_detail.length > 0) {
                    tostAlert('Workspace name already exists', 'warning')
                    return
                }

                const userName = getUserName()
                const spaceObject = {
                    collection_item: {
                        name: spaceData.spaceName,
                        description: spaceData.spaceDescription,
                        status: 'active',
                        created_by: userName,
                        created_on: moment().format("DD-MM-YYYY HH:mm:ss"),
                        history: [
                            {
                                updated_by: userName,
                                status: "active",
                                updated_date: moment().format("DD-MM-YYYY HH:mm:ss")
                            }
                        ],
                    }
                }
                const response = await WorkspaceApi.createWorkspace(JSON.stringify(spaceObject));
                if (response.status === 200) {
                    setSpaceData(spaceDetails)
                    dispatch(setWorkspaceAddedAdded(true))
                    const message = await AlertMessages('create', 'workspace');
                    navigate('/')
                    tostAlert(message, 'success')
                } else {
                    const message = await AlertMessages('error', 'workspace');
                    tostAlert(message, 'error')
                }
            }
            else {
                setFormErrors(errors);
            }
        } catch (error) {
            apiErrorHandler(error);
            console.error("Error creating workspace:", error);
        } finally {
            setSpaceLoading(false);
        }
    }

    return (
        <div style={styles.mainContainer}>
            <Box sx={styles.cardContainer}>
                {spaceLoading && (<LoadBar />)}

                <Box padding={'20px'}>

                    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                        <Typography sx={styles.mainHeadingText}>Create Space</Typography>

                        <Box display={'flex'} alignItems={'center'}>
                            <Button
                                sx={{ ...buttonStyles.primaryBtn, width: '110px' }}
                                onClick={(e) => handleCreateSpace(e)}
                            >
                                <Typography sx={styles.btnText}>CREATE</Typography>
                            </Button>
                            <Button
                                sx={{ ...buttonStyles.secondaryBtn, width: '110px', marginLeft: '10px' }}
                                onClick={handleCancel}>
                                <Typography sx={styles.btnText}>CANCEL</Typography>
                            </Button>
                        </Box>

                    </Box>

                    <Box marginTop={'20px'}>
                        <Box>
                            <TextField
                                id="space-name"
                                variant="outlined"
                                placeholder='Space Name'
                                size='small'
                                sx={{ ...componentsStyles.textField, width: '40%' }}
                                value={spaceData.spaceName}
                                onChange={(e) => setSpaceData({ ...spaceData, spaceName: e.target.value })}
                            />
                            {formErrors.spaceName && <Typography sx={styles.errorText}>{formErrors.spaceName}</Typography>}
                        </Box>
                        <Box marginTop={'20px'}>
                            <TextField
                                id="spaces-description"
                                variant="outlined"
                                placeholder='Spaces Description'
                                size='medium'
                                sx={{ ...componentsStyles.textField, width: '40%' }}
                                value={spaceData.spaceDescription}
                                onChange={(e) => setSpaceData({ ...spaceData, spaceDescription: e.target.value })}
                            />
                            {formErrors.spaceDescription && <Typography sx={styles.errorText}>{formErrors.spaceDescription}</Typography>}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </div >
    )
}

export default CreateSpace;