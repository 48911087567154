export const Styles = {
    mainContainer: {
        width: "100%",
        height: '100vh',
    },
    cardContainer: {
        padding: '20px 20px 0px 20px'
    },
    sourceCard: {
        height: '100%',
        borderRadius: '10px',
        backgroundColor: '#F3F5F7',
        cursor: 'pointer',
        transition: 'transform 0.2s, background-color 0.2s',
        '&:hover': {
            backgroundColor: '#E0E5EB',
            transform: 'scale(1.05)'
        }
    },
    relQuestions: {
        maxWidth: '600px',
        textAlign: 'left',
        wordWrap: 'break-word',
        padding: '0 10px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',
        '&:hover': {
            color: 'red',
        }
    }
}
