import React, { useEffect, useState } from 'react';
import { Box, Button, Checkbox, CircularProgress, Divider, FormControl, IconButton, LinearProgress, Modal, TextField, Tooltip, Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { getStyles } from './styles.js';
import { CancelOutlined, CheckCircleOutlineOutlined, CloudUpload, ErrorOutlineOutlined, QueryBuilderOutlined } from '@mui/icons-material';
import { IconSvg } from '../../../../utils/globalIcons.js';
import { ReactSVG } from 'react-svg';
import { tostAlert } from '../../../../utils/AlertToast.js';
import { getComponentsStyles } from '../../../../styles/componentsStyles.js';
import { buttonStyles } from '../../../../styles/buttonStyles.js';
import { AlertMessages } from '../../../../utils/AlertMessages.js';
import { fileRealmExcistenceCheck } from '../../../../utils/FileHandling.js';
import { getSessionData } from '../../../../utils/sessionDataHandle.js';
import { apiErrorHandler } from '../../../../utils/ApiErrorHandler.js';
import moment from 'moment';
import AnalyticsApi from '../../../../Api/Services/AmeyaBackend/AnalyticsToolApi.js';
import FilesApi from '../../../../Api/Services/AppflyteBackend/FileServiceApi.js';
import LoadBar from '../../../../utils/LoadBar.js';
import "./styles.css";
import GeneralTable from '../../../../components/GeneralTable.js';
import getAmeyaSettingsData from '../../../../utils/ApiFunctions/AmeyaSettingsData.js';
import { getMainStyles } from '../../../../styles/styles.js';
import AnalyticsFilesData from '../../../../utils/ApiFunctions/AnalyticsFilesData.js';

let resJsonVar = []
let uploadProgress = []

function FileSource() {
    const theme = useTheme();
    const styles = getStyles(theme);
    const mainStyles = getMainStyles(theme);
    const componentsStyles = getComponentsStyles(theme);

    // Doc Search Files
    const [analyticsFilesInfo, setAnalyticsFilesInfo] = useState([]);
    const [analyticsFileLoading, setAnalyticsFilesLoading] = useState(false);

    // Realm and llm Configurations
    const [initialLoading, setInitialLoading] = useState(false)
    const [realmSettings, setRealmSettings] = useState([]);
    const [llmSettings, setLLMSettings] = useState({ api_keys: "", llm_model: "" })
    const [values, setValues] = useState({});

    // Files Upload
    const filePermission = "PUBLIC"
    const [uploadJson, setUploadJson] = useState([])
    const [selectedFiles, setSelectedFiles] = useState([]);
    const deleteDetails = { existingfiles: [], fileName: [], modalstatus: false };
    const [deleteData, setDeleteData] = useState(deleteDetails);
    const [uploadProgressState, setUploadProgress] = useState([])
    const [loading, setLoading] = useState(false);
    const [loadingSave, SetLoadingSave] = useState(false);

    // File Selection
    const [formErrors, setFormErrors] = useState({});
    const [sourceName, setSourceName] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);

    useEffect(() => {
        initializeData();
        //eslint-disable-next-line
    }, [])

    const initializeData = async () => {
        try {
            setInitialLoading(true)
            setSelectedFiles([]);
            const selected_project = getSessionData("selected_project")
            const response = await getAmeyaSettingsData(selected_project?.payload?.__auto_id__);
            if (response?.length > 0) {
                const responseData = response?.[0] ?? [];
                const realm_settings = responseData?.payload?.settings?.realm_attributes ?? [];
                const llm_settings = responseData?.payload?.settings?.llm_settings ?? {};
                if (realm_settings?.length > 0 || llm_settings) {
                    setRealmSettings(realm_settings);
                    setLLMSettings({
                        ...llmSettings,
                        api_keys: typeof llm_settings?.api_keys === "string" ? llm_settings.api_keys : "",
                        llm_model: typeof llm_settings?.llm_model === "string" ? llm_settings.llm_model : ""
                    });
                }
            }
            setInitialLoading(false)
            getAllAnalyticsSourceFiles(false);
        } catch (error) {
            console.error("Error initializing data:", error);
        }
    };

    const getAllAnalyticsSourceFiles = async (is_filter) => {
        setAnalyticsFilesLoading(true)
        try {
            let doc_search_files = []

            const response = await AnalyticsFilesData()
            doc_search_files = response

            const updatedData = doc_search_files?.length > 0 ? doc_search_files?.map(item => {
                const file_format = item?.document_name?.includes('.')
                    ? item.document_name.split('.').pop()
                    : null;

                const fixedDate = item?.created_at?.replace(/:(\d{2}):(\d{2})$/, (match, mins, secs) => {
                    return `:${mins}:${Math.min(Number(secs), 59)}`;
                });
                return { ...item, file_format, fixedDate };
            }) : []

            const sorted_doc_search_files = updatedData?.length > 0
                ? [...updatedData]?.sort((a, b) => {
                    const dateA = Date.parse(a?.fixedDate) ? new Date(a?.fixedDate) : null;
                    const dateB = Date.parse(b?.fixedDate) ? new Date(b?.fixedDate) : null;
                    if (!dateA || !dateB) return !dateA ? 1 : -1;
                    return dateB - dateA;
                })
                : [];
            setAnalyticsFilesInfo(sorted_doc_search_files)
        } catch (error) {
            console.log(error)
        } finally {
            setAnalyticsFilesLoading(false)
        }
    }

    const handleFileDrop = async (event) => {
        try {
            event.preventDefault();
            event.stopPropagation();
            const files = Array.from(event.dataTransfer.files);
            const maxFileSize = 50 * 1024 * 1024;
            const validFiles = files.filter(file => file.size <= maxFileSize) ?? []
            if (validFiles.length < files.length) {
                tostAlert('File Size Exceeds 50 MB', 'warning')
                return
            }
            event.target.value = null;
            setSelectedFiles(files);
        } catch (error) {
            console.log(error)
        }
    };

    const handleFileChange = async (event) => {
        try {
            event.preventDefault();
            event.stopPropagation();

            const files = Array.from(event.target.files);
            const maxFileSize = 50 * 1024 * 1024;
            const validFiles = files.filter(file => file.size <= maxFileSize) ?? []
            if (validFiles.length < files.length) {
                tostAlert('File Size Exceeds 50 MB', 'warning')
                return
            }
            event.target.value = null;
            setSelectedFiles(files);
        } catch (error) {
            console.log(error)
        }
    };

    const handleFileRemove = (index) => {
        const updatedFiles = [...selectedFiles];
        updatedFiles.splice(index, 1);
        setSelectedFiles(updatedFiles);
    }

    // Handling Realm Config
    const handleValueChange = (event) => {
        const { name, value } = event.target;
        setValues((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const markIndexFailed = (index, errorMessage) => {
        const tempLoadArr = [...uploadProgress]
        tempLoadArr[index] = { status: 'failed', message: errorMessage };
        uploadProgress = tempLoadArr
        setUploadProgress([...tempLoadArr])
    }

    const FileUploadChecks = async () => {
        try {
            if (selectedFiles?.length === 0) {
                const message = await AlertMessages('warning', 'Files');
                tostAlert(message, 'warning')
                return
            }

            const missingFields = realmSettings?.filter((key) => !values[key]);
            if (missingFields.length > 0) {
                tostAlert(`Please fill in all fields: ${missingFields.join(', ')}`, 'warning')
                return
            }
            const realm_settings = Object.keys(values)?.length > 0 ? values : {}
            const existingfiles = await fileRealmExcistenceCheck(analyticsFilesInfo, selectedFiles, realm_settings)

            if (existingfiles.length > 0) {
                const existing_files_names = existingfiles?.map(item => item?.file_name) ?? []
                setDeleteData({ ...deleteData, existingfiles: existingfiles, fileName: existing_files_names, modalstatus: true });
            }
            else {
                startUploading()
            }

        } catch (error) {
            console.log(error)
        }
    }

    const startUploading = async () => {
        setLoading(true)
        let uploadedFilesData = [];
        try {
            setDeleteData({ ...deleteData, modalstatus: false });
            const tempArr = selectedFiles.map(file => ({ status: 'pending', message: "Waiting to upload" }));
            setUploadProgress([...tempArr]);
            uploadProgress = tempArr;


            for (const [index, file] of selectedFiles.entries()) {
                const tempLoadArr = [...uploadProgress];
                tempLoadArr[index] = { status: 'uploading', message: "uploading the file" };
                setUploadProgress(tempLoadArr);
                uploadProgress = tempLoadArr;

                try {
                    const file_upload_response = await handleDocumentUpload(file);
                    console.log("File Upload Response: ", file_upload_response);
                    if (file_upload_response.fileUrl && file_upload_response.fileId) {
                        uploadedFilesData.push({
                            fileUrl: file_upload_response.fileUrl,
                            fileId: file_upload_response.fileId
                        });
                    } else {
                        markIndexFailed(index, 'Upload failed');
                    }
                } catch (err) {
                    markIndexFailed(index, 'Upload failed');
                    console.error(`Failed to upload file at index ${index}`, err);
                }
            }

            if (uploadedFilesData.length > 0) {
                try {

                    const response = await saveInAmeyaBackend(uploadedFilesData);
                    if (response.status === 200) {
                        for (let index = 0; index < uploadedFilesData?.length; index++) {
                            const tempLoadArr = [...uploadProgress]
                            tempLoadArr[index] = { status: 'success', message: "upload success" };
                            uploadProgress = tempLoadArr
                            setUploadProgress([...tempLoadArr])
                        }
                        return
                    } else {
                        for (let index = 0; index < uploadedFilesData?.length; index++) {
                            markIndexFailed(index, 'Upload failed')
                        }
                        return
                    }
                } catch (error) {
                    for (let index = 0; index < uploadedFilesData?.length; index++) {
                        markIndexFailed(index, 'Upload failed')
                    }
                }
            }

            if (uploadedFilesData.length === 0) {
                markIndexFailed(0, 'Upload failed');
            }

            // else {
            //     markIndexFailed(0, 'Upload failed')
            // }

        } catch (error) {
            console.log(error)
            apiErrorHandler(error)
        } finally {
            setDeleteData(deleteDetails);
            setLoading(false)
        }
    }

    const saveInAmeyaBackend = async (fileData) => {
        try {

            const documents_urls = fileData?.map((item) => item?.fileUrl) ?? [];
            const mongodb_url = window?.process?.env?.REACT_APP_ANALYTICS_MONGO_DB_URL || process.env.REACT_APP_ANALYTICS_MONGO_DB_URL
            const reqObj = {
                "job_id": "123456-34566-13455-13455",
                "file_path": documents_urls,
                "settings": {
                    "ocr": true,
                    "llm": {
                        "provider": "openai",
                        "model": "gpt-4o",
                        "api_key": "sk-proj-67gj7v9tdOrJKkamVcux98BqkDX5CpQH3IheDivn26jUaM5oyebcGH-LpxIgGP_EtoDSIElHRrT3BlbkFJBqH24Dwgo5_y7F9TqCgg_lWzxX89gTxeArYi_yxG2wgSn8L6-9lr2pYIu0GS0PQXGflvEzgD8"
                    },
                    "embedding": {
                        "provider": "openai",
                        "model": "text-embedding-3-small",
                        "api_key": "sk-proj-67gj7v9tdOrJKkamVcux98BqkDX5CpQH3IheDivn26jUaM5oyebcGH-LpxIgGP_EtoDSIElHRrT3BlbkFJBqH24Dwgo5_y7F9TqCgg_lWzxX89gTxeArYi_yxG2wgSn8L6-9lr2pYIu0GS0PQXGflvEzgD8"
                    },
                    "llama_api_key": "llx-xZ4bljwF9BDiGfUtjSw8eJG3oRLp4E7U4SeVMUFfYI37FKvF",
                    "extraction_type": "llama_parse"
                },

                "db_config": {
                    "db_provider": "mongo",
                    "db_name": "ameya-analytics-pdf",
                    "db_url": mongodb_url,
                    "collection_name": "index_collection"
                },
                "priority": 10,
                "service_name": "test_tool",
                "extra_meta": {
                    "realm": Object.keys(values)?.length > 0 ? values : {},
                },
                "plan": "premium +"
            }
            const upload_reponse = await AnalyticsApi.uploadFile(JSON.stringify(reqObj));
            return upload_reponse
        } catch (error) {
            return error
        }
    }

    const handleDeleteAlertModalClose = () => {
        setDeleteData(deleteDetails);
    }

    const uploadFile = async (blob, reqBody) => {
        try {
            const resData = await FilesApi.getPresignedURL(JSON.stringify(reqBody))
            if (resData.status === 200) {
                const urlFields = JSON.parse(resData.data.url_fields)
                let formData = new FormData();

                formData.append('key', urlFields.key);
                formData.append('AWSAccessKeyId', urlFields.AWSAccessKeyId);
                formData.append('policy', urlFields.policy);
                formData.append('signature', urlFields.signature);
                formData.append('file', blob);

                const resUpload = await FilesApi.uploadFile(resData.data.url, formData)
                if (resUpload.status === 200 || resUpload.status === 204) {
                    return resData?.data?.file_id
                }
                return null
            }
            return null
        } catch (e) {
            console.log('Err ' + e)
            return null
        }
    }

    const uploadAndAppendForPost = async (file, fileType, thumbnailFileId) => {
        try {

            const reqBodyFile = {
                file_context: 'bucket_dpod_user_file',
                content_type: file?.name?.split('.')[1],
                file_type: '',
                file_name: file?.name
            }

            const uploadedFileId = await uploadFile(file, reqBodyFile)
            if (uploadedFileId) {
                const uploadedFileUrl = await FilesApi.getUploadedFileUrls(uploadedFileId)
                if (uploadedFileUrl.status === 200) {
                    const tempJsonArr = [...resJsonVar]
                    const uploadResObj = {
                        file_id: uploadedFileId,
                        folder_id: null,
                        access_type: filePermission,
                        file_type: fileType,
                        file_attributes: {
                            file_name: file?.name,
                            file_url: uploadedFileUrl?.data?.[0]?.download_url,
                            created_on: moment().format("DD-MM-YYYY"),
                            file_extension: file?.name?.split('.')[1]
                        },
                        thumbnail_file_id: thumbnailFileId,
                        is_hidden: true
                    }
                    tempJsonArr.push(uploadResObj)
                    resJsonVar = tempJsonArr
                    setUploadJson(tempJsonArr)
                    return { fileId: uploadedFileId, fileUrl: uploadedFileUrl?.data?.[0]?.download_url }
                }
                return { fileId: null, fileUrl: null }
            }
            return { fileId: null, fileUrl: null }
        }
        catch (err) {
            console.log("ERROR: ", err);
            return { fileId: null, fileUrl: null }
        }
    }

    const handleDocumentUpload = async (file) => {
        try {
            const uploadResponse = await uploadAndAppendForPost(file, 'Document', null)
            if (uploadResponse.fileUrl && uploadResponse.fileId) {
                return { fileUrl: uploadResponse.fileUrl, fileId: uploadResponse.fileId }
            }
            return { fileUrl: null, fileId: null }
        }
        catch (err) {
            console.log("ERROR: ", err);
            return { fileUrl: null, fileId: null }
        }
    }


    const closeUploadIndicator = () => {
        setLoading(false)
        setUploadProgress([])
        uploadProgress = []
        resJsonVar = []
        setSelectedFiles([])
    }

    const saveUploadChanges = async () => {
        SetLoadingSave(true)
        try {
            const res = await FilesApi.createFile(uploadJson)
            if (res.status === 200 || res.status === 204) {
                initializeData()
            }
        } catch (error) {
            console.log(error)
            apiErrorHandler(error)
        } finally {
            SetLoadingSave(false)
            setLoading(false)
            uploadProgress = []
            resJsonVar = []
            setUploadProgress([])
            setSelectedFiles([])
        }
    }

    useEffect(() => {
        if (uploadProgressState && uploadProgressState.length && uploadProgressState.length > 0) {
            let i
            let flagToSave = true
            for (i = 0; i < uploadProgressState.length; i++) {
                if (!(uploadProgressState[i]?.status === "success" || uploadProgressState[i]?.status === "failed")) {
                    flagToSave = false
                    break
                }
            }
            if (flagToSave) {
                saveUploadChanges()
            }
        }
        //eslint-disable-next-line
    }, [uploadProgressState])

    const handleRowSelect = (id) => {
        setSelectedRows((prev) =>
            prev.includes(id) ? prev.filter((rowId) => rowId !== id) : [...prev, id]
        );
    };

    const handleSelectAll = (event) => {
        if (event.target.checked) {
            setSelectedRows(analyticsFilesInfo?.map((row) => row?.checksum));
        } else {
            setSelectedRows([]);
        }
    };

    useEffect(() => {
        const errors = { ...formErrors };
        if (formErrors?.sourceName && sourceName?.trim()) { delete errors?.sourceName }
        if (JSON.stringify(errors) !== JSON.stringify(formErrors)) { setFormErrors(errors) }
    }, [sourceName, formErrors]);

    const handleFileSourceCreation = async () => {
        setLoading(true)
        try {
            const errors = {};
            if (selectedRows?.length < 1) {
                tostAlert('Please select file and try again', 'warning')
                return
            }
            if (!sourceName?.trim()) { errors.sourceName = 'This field is required' }
            if (Object.keys(errors).length === 0) {
                const reqObj = {
                    "source_name": sourceName,
                    "document_checksums": selectedRows
                }
                const response = await AnalyticsApi.fileGrouping(JSON.stringify(reqObj))
                if (response.status === 200) {
                    tostAlert('Souce Created Successfully', 'success')
                }
            } else {
                setFormErrors(errors);
            }
        } catch (error) {
            console.log(error)
            apiErrorHandler(error)
        } finally {
            setLoading(false)
        }
    }

    const handleSourceFileDeletion = async (item) => {
        setLoading(true)
        try {
            const response = await AnalyticsApi.deleteFile(item);
            if (response.status === 200) {
                tostAlert('File Deleted Successfully', 'success');
                initializeData();
            }
        } catch (error) {
            console.log(error);
            apiErrorHandler(error)
        } finally {
            setLoading(false)
        }
    }

    const columns = [
        {
            id: 'checkbox',
            label: (
                <Checkbox
                    sx={componentsStyles.checkbox}
                    onChange={handleSelectAll}
                    checked={selectedRows?.length === analyticsFilesInfo?.length}
                    indeterminate={selectedRows?.length > 0 && selectedRows?.length < analyticsFilesInfo?.length}
                />
            ),
            width: '5%',
            sortable: false,
            renderCell: (row) => (
                <Checkbox
                    sx={{ ...componentsStyles.checkbox }}
                    checked={selectedRows.includes(row?.checksum)}
                    onChange={() => handleRowSelect(row?.checksum)}
                />
            ),
        },
        {
            id: 'document_name',
            label: 'Document',
            width: '25%',
            sortable: true,
            renderCell: (row) => (
                <Box display={'flex'} alignItems={'center'}>
                    <span style={{ marginRight: '10px', whiteSpace: 'nowrap' }}>
                        <ReactSVG src={IconSvg.filesIcon} className='files_icon' />
                    </span>
                    <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        <Tooltip
                            title={
                                <div style={{ padding: '8px' }}>
                                    <Typography sx={{ ...styles.paraText }}>{row.document_name}</Typography>
                                </div>
                            }
                            arrow
                        >
                            {row.document_name?.length > 30
                                ? `${row.document_name.substring(0, 50)}...`
                                : row.document_name}
                        </Tooltip>
                    </span>
                </Box>
            ),
        },
        {
            id: 'created_at',
            label: 'Created On',
            width: '20%',
            sortable: true,
            renderCell: (row) => row?.created_at?.substring(0, 10)
        },
        {
            id: 'updated_at',
            label: 'Updated On',
            width: '20%',
            sortable: true,
            renderCell: (row) => row?.updated_at?.substring(0, 10)
        },
        {
            id: 'realm',
            label: 'Realms',
            width: '20%',
            sortable: false,
            renderCell: (row) =>
                row?.realm && (
                    <Tooltip
                        title={
                            <div style={{ padding: '8px', fontSize: '14px' }}>
                                {Object.entries(row.realm).map(([key, value]) => (
                                    <Typography sx={{ ...styles.paraText }} key={key}>
                                        {`${key}: ${value}`}
                                    </Typography>
                                ))}
                            </div>
                        }
                        arrow
                    >
                        <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            {Object.entries(row.realm)
                                .slice(0, 1)
                                .map(([key, value]) => `${key}: ${value}`)
                                .join(', ')}
                            {Object.keys(row?.realm).length > 1 ? ', ...' : ''}
                        </span>
                    </Tooltip>
                )
        },
        {
            id: '',
            label: '',
            width: '10%',
            sortable: false,
            renderCell: (row) => (
                <ReactSVG
                    src={IconSvg.deleteIcon}
                    className="delete_icon"
                    onClick={() => handleSourceFileDeletion(row?.checksum)}
                />
            ),
        },
    ];

    return (
        <Box sx={styles.mainContainer}>
            <Box sx={styles.cardContainer}>

                <Typography sx={styles.mainHeadingText}>File Source - Connection</Typography>
                <Typography sx={{ ...styles.paraText, marginTop: '10px' }}>
                    Upload documents to index
                </Typography>

                {(loading || analyticsFileLoading) && (<LoadBar />)}

                {
                    uploadProgressState && uploadProgressState.length > 0
                        ?
                        <Box sx={styles.uploadLoadingBox}>
                            <Box sx={styles.uploadingHeaderBox}>
                                {
                                    loadingSave
                                        ?
                                        <Typography sx={styles.uploadingText}>Saving changes</Typography>
                                        :
                                        <Typography sx={styles.uploadingText}>Uploading files …</Typography>
                                }
                                {
                                    !loadingSave ? <CancelOutlined sx={styles.closeIcon} onClick={closeUploadIndicator} /> : ''
                                }
                            </Box>
                            {
                                loadingSave ? <LinearProgress width="100%" /> : ''
                            }
                            <Box sx={styles.fileListContainer}>
                                {
                                    selectedFiles.map((file, index) =>
                                        <Box key={"upload_progress_ind_key_" + index}>
                                            {
                                                index > 0 ? <Divider width="100%" /> : ''
                                            }
                                            <Box display="flex" height="40px" width="100%" alignItems="center" paddingX="10px">
                                                {
                                                    ({
                                                        'uploading': <CircularProgress size="18px" sx={{ ...styles.tickIcon, color: '#0B51C5' }} />,
                                                        'pending': <QueryBuilderOutlined sx={{ ...styles.tickIcon, color: '#1976d2' }} />,
                                                        'success': <CheckCircleOutlineOutlined sx={{ ...styles.tickIcon, color: '#1976d2' }} />,
                                                        'failed': <ErrorOutlineOutlined sx={styles.failedIcon} />
                                                    }[uploadProgressState[index]?.status])
                                                }
                                                <Box display="flex" flexDirection="column" width="100%">
                                                    <Typography noWrap sx={styles.dropZoneFont}>{file?.name}</Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    )
                                }
                            </Box>
                        </Box>
                        :
                        ''
                }


                <Box marginTop={'20px'} sx={styles.fileUploadContainer}>
                    <Box
                        onDrop={handleFileDrop}
                        onDragOver={(e) => e.preventDefault()}
                    >
                        <input
                            type="file"
                            accept=".pdf"
                            onChange={handleFileChange}
                            style={{ display: 'none', cursor: 'pointer' }}
                            id="fileInput"
                            multiple
                        />
                        <label
                            id='fileInput'
                            htmlFor="fileInput">
                            {selectedFiles.length === 0 &&
                                <Box>
                                    <Typography sx={styles.fileDropLabelText}>Drag and drop files or&nbsp;<span style={{ color: '#0B51C5', cursor: 'pointer' }}>click to upload</span> </Typography>
                                    <Typography sx={styles.paraText}>Upto 50 MB in size (.pdf) </Typography>
                                </Box>
                            }
                            <IconButton component="span">
                                <CloudUpload style={{ color: "black", width: "32px", height: "32px" }} />
                            </IconButton>
                        </label>
                    </Box>
                    <Box display={'flex'} justifyContent={'center'} alignItems={'center'} flexWrap="wrap" >
                        {
                            selectedFiles.length > 0 && selectedFiles.map((file, index) => (
                                < Box key={index} display={'flex'} justifyContent={'center'} alignItems={'center'} alignContent={'center'} textAlign={'center'} padding={'3px 15px 3px 15px'} margin={'5px'} backgroundColor={'#FFFFFF'} borderRadius={'20px'}>
                                    <Typography sx={styles.paraText}>{file.name}</Typography>
                                    <Box marginLeft={'10px'} onClick={() => handleFileRemove(index)}>
                                        <ReactSVG src={IconSvg.deleteIcon} className='delete_icon' />
                                    </Box>
                                </Box>
                            ))
                        }
                    </Box>
                </Box >

                {(!initialLoading && realmSettings?.length > 0) &&
                    (<Box marginTop={'20px'} >
                        <Typography sx={styles.realmHeadingText}>Realm Settings</Typography>
                        <Box>
                            {realmSettings?.length > 0 ?
                                (<Box display={'flex'} alignItems={'center'} flexWrap="wrap" >
                                    {realmSettings.map((key, index) => (
                                        <Box key={index} marginRight={'15px'} marginTop={'10px'}>
                                            <Typography sx={{ ...styles.paraText, width: '150px', marginBottom: '10px' }}>{key}</Typography>
                                            <TextField
                                                value={values?.[key] || ''}
                                                onChange={handleValueChange}
                                                placeholder={`Enter ${key}`}
                                                size="small"
                                                sx={{ ...componentsStyles.textField, width: '200px' }}
                                                name={key}
                                            />
                                        </Box>
                                    ))}
                                </Box>)
                                :
                                (<Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                    <Typography sx={styles.paraText}>No Records To Display</Typography>
                                </Box>)}
                        </Box>
                    </Box>)
                }


                {initialLoading && (
                    <Box display={'flex'} justifyContent={'center'} alignItems={'center'} marginTop={'20px'}>
                        <CircularProgress />
                    </Box>
                )}

                <Box marginTop={'30px'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                    <Button
                        sx={{ ...buttonStyles.primaryBtn, width: '180px', height: '40px' }}
                        disabled={analyticsFileLoading || loading}
                        onClick={() => FileUploadChecks()}
                    >
                        <Typography style={styles.btnText}>Upload</Typography>
                    </Button>
                </Box>

                <Box marginTop={'20px'} paddingBottom={'20px'}>
                    <Box marginTop={'10px'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                        <Typography sx={styles.mainHeadingText}>Files List And Source Creation </Typography>
                        {analyticsFilesInfo?.length > 0 &&
                            <Box >
                                <Box display={'flex'} alignItems={'center'}>
                                    <FormControl>
                                        <TextField
                                            value={sourceName}
                                            onChange={(e) => setSourceName(e.target.value)}
                                            placeholder={'Source Name'}
                                            size="small"
                                            sx={{ ...componentsStyles.textField, width: '200px' }}
                                        />
                                    </FormControl >
                                    <Box marginLeft={'10px'}>
                                        <Button
                                            sx={styles.saveBtn}
                                            disabled={loading}
                                            onClick={handleFileSourceCreation}
                                        >
                                            <Typography sx={styles.btnText}>SAVE</Typography>
                                        </Button>
                                    </Box>
                                </Box>
                                {formErrors.sourceName && <Typography sx={{ ...styles.paraText, color: 'red' }}>{formErrors.sourceName}</Typography>}
                            </Box>
                        }
                    </Box>


                    <Box marginTop={'20px'}>
                        <GeneralTable
                            data={analyticsFilesInfo}
                            columns={columns}
                            loading={analyticsFileLoading}
                        />
                    </Box>
                </Box>

                <Modal open={deleteData.modalstatus} onClose={handleDeleteAlertModalClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                    <Box sx={{ ...mainStyles.modalcontainer, width: '40%' }}>
                        <Box sx={mainStyles.mainWarning}>Do You Want To Replace{('\n')}</Box>
                        <Box sx={{ ...mainStyles.subWarning, whiteSpace: 'pre-line' }}>
                            {deleteData.existingfiles.length > 0 && `${deleteData?.fileName.join('\n')}`}
                        </Box>
                        <Box sx={mainStyles.subWarning}>There is Already a file with same name is present</Box>
                        <Box style={mainStyles.confirmButtonGroup}>
                            <Button
                                onClick={handleDeleteAlertModalClose}
                                sx={{ ...mainStyles.cancelDelete, textTransform: 'none' }}
                            >
                                <Typography sx={styles.btnText}>Cancel</Typography>
                            </Button>
                            <Button
                                onClick={() => { startUploading() }}
                                sx={{ ...mainStyles.confirmDelete, textTransform: 'none' }}
                            >
                                <Typography sx={styles.btnText}>Upload</Typography>
                            </Button>
                        </Box>
                    </Box>
                </Modal >

            </Box>
        </Box>
    )
}

export default FileSource;