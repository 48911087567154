import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { getComponentsStyles } from '../../../styles/componentsStyles';
import moment from 'moment';

import { TextField, Button, Box, Typography, CircularProgress, IconButton, Modal } from '@mui/material';
import { Styles } from './Styles';
import "./Styles.css"
import { buttonStyles } from '../../../styles/buttonStyles';
import { fontStyles } from '../../../styles/fontStyles';
import { getUserName } from '../../../utils/GetAccountDetails';
import AmeyaSettingsApi from '../../../Api/Services/AppflyteBackend/AmeyaSettings';
import { tostAlert } from '../../../utils/AlertToast';
import LoadBar from '../../../utils/LoadBar';
import getAmeyaSettingsData from '../../../utils/ApiFunctions/AmeyaSettingsData';
import { ReactSVG } from 'react-svg';
import { IconSvg } from '../../../utils/globalIcons';
import { Add } from '@mui/icons-material';
import DocumentSearchApi from '../../../Api/Services/AmeyaBackend/DocumentSearchApi';
import { getMainStyles } from '../../../styles/styles';
import LLMDocumentSearchApi from '../../../Api/Services/AmeyaBackend/LLMDocumentSearchApi';
import { getSessionData } from '../../../utils/sessionDataHandle';

function RealmSettings() {

    const theme = useTheme();
    const mainStyles = getMainStyles(theme);
    const componentsStyles = getComponentsStyles(theme);

    const [loading, setLoading] = useState(false);
    const [initialLoading, setInitialLoading] = useState(true);

    const [newKey, setNewKey] = useState("");

    const realmFileInfo = { realm_key: "", file_count: 0 }
    const [realmFiles, setRealmFiles] = useState([realmFileInfo]);
    const deleteDetails = { fileCount: 0, keyToRemove: '', modalstatus: false };
    const [deleteData, setDeleteData] = useState(deleteDetails);

    const [realmAttributes, setRealmAttributes] = useState([]);
    const [isExistingData, setIsExistingData] = useState(false);

    const settingsDetails = { item_id: null, update_key: null };
    const [settingsData, setSettingsData] = useState(settingsDetails);
    const [llmSettings, setLLMSettings] = useState({ api_keys: "", llm_model: 'openai' })

    const selected_space = getSessionData("selected_space")
    const selected_project = getSessionData("selected_project")

    useEffect(() => {
        getSettingsDetails();
        // eslint-disable-next-line
    }, []);

    const fetchRealmFileCounts = async () => {
        try {
            const reqObj = {
                project_id: selected_project?.payload?.__auto_id__
            }
            const engine_name = selected_project?.payload?.configuration?.engine_name
            let response;

            if (engine_name === "doc_search") {
                response = await DocumentSearchApi.getDocsearchFilesCounts(JSON.stringify(reqObj));
            }
            if (engine_name === "llm_doc_search") {
                response = await LLMDocumentSearchApi.getLLMDocsearchFilesCounts(JSON.stringify(reqObj));
            }

            if (response?.status === 200) {
                const responseData = response.data ?? [];
                const resultData = responseData?.result ?? [];

                if (resultData?.length > 0) {
                    const promises = resultData.map(async (item) => {
                        let realmFilesData = []

                        const relam_data = {
                            realm_key: item?.r_key,
                            file_count: item?.document_count
                        }
                        realmFilesData.push(relam_data)
                        return realmFilesData
                    });
                    const realmFilesData = await Promise.all(promises);
                    setRealmFiles(realmFilesData.flat());
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const fetchSettingsData = async () => {
        try {
            const response = await getAmeyaSettingsData(selected_project?.payload?.__auto_id__);
            if (response?.length > 0) {
                const responseData = response?.[0] ?? [];
                const realm_settings = responseData?.payload?.settings?.realm_attributes ?? [];
                const llm_settings = responseData?.payload?.settings?.llm_settings ?? {};
                if (realm_settings?.length > 0 || llm_settings) {
                    setRealmAttributes(realm_settings || []);
                    setLLMSettings({ ...llmSettings, api_keys: llm_settings?.api_keys ?? "", llm_model: llm_settings?.llm_model ?? "" })
                    setSettingsData({ ...settingsData, item_id: responseData?.payload.__auto_id__, update_key: responseData?.update_key });
                    setIsExistingData(true);
                }
            }
        } catch (error) {
            console.error('Error fetching settings data:', error);
        }
    };

    const getSettingsDetails = async () => {
        setInitialLoading(true);
        try {
            const [settingsResponse, realmResponse] = await Promise.all([
                fetchSettingsData(),
                fetchRealmFileCounts()
            ])
            if (settingsResponse && realmResponse) {
                return {
                    settingsResponse,
                    realmResponse
                }
            }
        } catch (error) {
            console.log(error)
        } finally {
            setInitialLoading(false)
        }
    }

    const addKey = () => {
        if (!newKey.trim()) {
            tostAlert('Key cannot be empty', 'warning');
            return;
        }
        if (realmAttributes.includes(newKey.trim())) {
            tostAlert('Key already exists', 'warning');
            return;
        }
        const updatedKeys = [...realmAttributes, newKey.trim()];
        setRealmAttributes(updatedKeys);
        setNewKey("");
    };

    const handleFilesCountCheck = (keyToRemove) => {
        if (realmFiles.length > 0) {
            const fileCount = realmFiles.filter(file => file?.realm_key === keyToRemove).map(file => file?.file_count) ?? [];
            if (fileCount?.length > 0) {
                setDeleteData({ ...deleteData, fileCount: fileCount[fileCount?.length - 1], keyToRemove: keyToRemove, modalstatus: true });
                return
            }
            handleRelamKeyDelete(keyToRemove);
            return
        }
        handleRelamKeyDelete(keyToRemove);
    };

    const handleDeleteAlertModalClose = () => {
        setDeleteData(deleteDetails);
    }

    const handleRelamKeyDelete = (keyToRemove) => {
        const updatedKeys = realmAttributes.filter(key => key !== keyToRemove);
        setDeleteData(deleteDetails);
        setRealmAttributes(updatedKeys);
    };

    const saveSettings = async () => {
        setLoading(true);
        try {
            const userName = await getUserName();
            const settingsObject = {
                collection_item: {
                    workspace_id: selected_space?.payload?.__auto_id__,
                    project_id: selected_project?.payload?.__auto_id__,
                    created_by: userName,
                    created_on: moment().format("DD-MM-YYYY HH:mm:ss"),
                    settings: {
                        "realm_attributes": realmAttributes,
                        "llm_settings": {
                            "llm_model": llmSettings.llm_model,
                            "api_keys": llmSettings.api_keys
                        }
                    }
                }
            };

            let response;
            if (isExistingData && settingsData.item_id && settingsData.update_key) {
                const dataObject = {
                    id: settingsData.item_id,
                    fields: [
                        {
                            path: '$.settings',
                            value: {
                                "realm_attributes": realmAttributes,
                                "llm_settings": {
                                    "llm_model": llmSettings.llm_model,
                                    "api_keys": llmSettings.api_keys
                                }
                            }
                        }
                    ]
                };
                response = await AmeyaSettingsApi.updateSettings(JSON.stringify(dataObject), settingsData.item_id, settingsData.update_key);
            } else {
                response = await AmeyaSettingsApi.addSettings(JSON.stringify(settingsObject));
            }

            if (response.status === 200) {
                tostAlert('Realm Configured Successfully', 'success');
                getSettingsDetails();
            } else {
                tostAlert('Realm Configuration Failed', 'error');
            }
        } catch (error) {
            tostAlert(error?.message, 'error');
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box sx={Styles.mainContainer}>
            <Box sx={Styles.cardContainer}>
                {(loading || initialLoading) && <LoadBar />}

                <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                    <Typography sx={fontStyles.largeText}>Realm Settings</Typography>
                    <Button
                        onClick={saveSettings}
                        variant="contained"
                        color="primary"
                        disabled={loading || initialLoading}
                        sx={{ ...buttonStyles.primaryBtn, width: '100px' }}>
                        SAVE
                    </Button>
                </Box>

                {initialLoading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'calc(100vh - 100px)' }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        <Typography sx={{ ...fontStyles.smallText, marginTop: '10px' }}>
                            Add field and Configure
                        </Typography>

                        <Box display="flex" alignItems="center" marginBottom="20px">
                            <TextField
                                value={newKey}
                                onChange={(e) => setNewKey(e.target.value)}
                                placeholder="Enter a new key (e.g., ClientId,SiteId)"
                                size="small"
                                sx={{ ...componentsStyles.textField, width: '50%', marginRight: '10px' }}
                            />
                            <IconButton
                                onClick={addKey}
                                variant="contained"
                                color="primary"
                                size="small"
                                sx={{
                                    marginLeft: '10px',
                                    backgroundColor: "#0B51C5",
                                    color: "#FFFFFF",
                                    '&:hover': {
                                        backgroundColor: "#0B51C5",
                                        color: "#FFFFFF",
                                    }
                                }}>
                                <Add />
                            </IconButton>
                        </Box>

                        <Box display={'flex'} alignItems={'center'} flexWrap="wrap">
                            {realmAttributes.length > 0 ? (realmAttributes.map((key, index) => (

                                < Box key={index} marginTop="20px" display={'flex'} alignItems={'center'} textAlign={'center'} padding={'3px 15px 3px 15px'} margin={'5px'} backgroundColor={'lightgray'} color={'white'} borderRadius={'20px'}>
                                    <Typography sx={{ ...fontStyles.smallText, color: 'black' }}>{key}</Typography>
                                    <Box marginLeft={'10px'}
                                        onClick={() => handleFilesCountCheck(key)}>
                                        <ReactSVG src={IconSvg.deleteIcon} className='delete_icon' />
                                    </Box>
                                </Box>

                            )))
                                : (
                                    <Typography sx={{ ...fontStyles.smallText, color: 'gray' }}>
                                        No Data added yet.
                                    </Typography>
                                )}
                        </Box>
                    </>
                )}
            </Box>

            <Modal open={deleteData.modalstatus} onClose={handleDeleteAlertModalClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={{ ...mainStyles.modalcontainer, width: '40%' }}>
                    <Box sx={mainStyles.mainWarning}>Are you sure you want to delete this realm?</Box>
                    <Box sx={mainStyles.subWarning}>There are {deleteData.fileCount} files associated with this realm</Box>

                    <Box style={mainStyles.confirmButtonGroup}>
                        <Button onClick={handleDeleteAlertModalClose} sx={{ ...mainStyles.cancelDelete, textTransform: 'none' }}>Cancel</Button>
                        <Button onClick={() => { handleRelamKeyDelete(deleteData.keyToRemove) }} sx={{ ...mainStyles.confirmDelete, textTransform: 'none' }}>Delete</Button>
                    </Box>
                </Box>
            </Modal >

        </Box >
    );

}

export default RealmSettings;
