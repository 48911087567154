import React from 'react';

import { Box, Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useStyles } from './Styles';
import AgentApiToken from '../../Api/Services/AppflyteBackend/AgentApiToken';

function InsightsHome() {

    const theme = useTheme();
    const styles = useStyles(theme);

    // const handleDelete = async () => {
    //     try {
    //         const allTokens = []

    //         for (const [index, item] of allTokens.entries()) {
    //             console.log("index:", index)
    //             const response = await AgentApiToken.deleteToken(item);
    //             console.log(response.data);
    //         }
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };

    return (
        <div style={styles.mainContainer}>
            <Box sx={styles.cardContainer}>
                <Box
                    display="flex"
                    justifyContent={"center"}
                    alignItems={"center"}
                    alignSelf={"center"}
                    height="100%"
                    width="100%"
                >
                    <Typography sx={styles.paraText}>coming soon</Typography>

                    {/* <Button sx={{ color: "black" }} onClick={() => handleDelete()}>
                        delete
                    </Button> */}
                </Box>

            </Box>
        </div >

    )
}

export default InsightsHome;