import { AxiosObj } from "../../Configurations/axios-setup";
import { fetchAccountId } from "../../../utils/GetAccountDetails"
import UpdateHeaders from "../../Configurations/UpdateHeaders";
import axios from "axios";

class dpodappFlyteApi {

    constructor() {
        this.accountId = null;
    }

    async initialize() {
        this.accountId = await fetchAccountId();
    }

    checkNameExistence = async (project_name) => {
        await this.initialize();
        const queryObj = [{
            field_name: "payload.name",
            field_value: project_name,
            operator: "eq"
        }]
        const queyString = encodeURIComponent(JSON.stringify(queryObj))
        return AxiosObj.get(`/${this.accountId}/api/collection/${this.accountId}/user/private/cm/v7/appflyte_projects?filters=${queyString}&include_detail=false`)
    }

    createProject = async (reqObj) => {
        await this.initialize();
        return AxiosObj.post(`/${this.accountId}/api/collection/${this.accountId}/user/private/cm/v1/appflyte_projects`, reqObj)
    }

    getAllProjects = async (last_evaluated_key) => {
        await this.initialize();
        return AxiosObj.get(`/${this.accountId}/api/collection/${this.accountId}/user/private/cm/v1/appflyte_projects?filters=null&last_evaluated_key=${last_evaluated_key}&page_size=50&include_detail=false`)
    }

    updateProject = async (reqObj, item_Id, updatekey) => {
        await this.initialize();
        const { hashHex, etagRandomNumber } = await UpdateHeaders(updatekey);
        return AxiosObj.put(`/${this.accountId}/api/collection/${this.accountId}/user/private/cm/v1/appflyte_project/${item_Id}`, reqObj, {
            headers: {
                'etag-hash': hashHex,
                'etag-random-number': etagRandomNumber,
            }
        })
    }
}

const ProjectsApi = new dpodappFlyteApi();
export default ProjectsApi;


