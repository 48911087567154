import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    space_changed: false,
    project_changed: false
};

const currentSelectedDataSlice = createSlice({
    name: 'selected_data',
    initialState,
    reducers: {
        setSpaceChanged(state, action) { state.space_changed = action.payload },
        setProjectChanged(state, action) { state.project_changed = action.payload }
    },
});

export const {
    setSpaceChanged,
    setProjectChanged
} = currentSelectedDataSlice.actions;

export default currentSelectedDataSlice.reducer;
