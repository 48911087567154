export const useStyles = (theme) => ({
    mainContainer: {
        width: "100%",
        height: '100vh',
    },
    cardContainer: {
        padding: '20px 20px 0px 20px'
    },
    fileUploadContainer: {
        padding: '20px',
        textAlign: 'center',
        cursor: 'pointer',
        backgroundColor: '#F3F5F7',
        borderRadius: '20px'
    },
    paraText: {
        fontSize: theme.typography.h6,
        fontWeight: 400,
    },
    mainHeadingText: {
        fontSize: theme.typography.h4,
        fontWeight: 600
    },
})