export const Styles = {
    mainContainer: {
        width: "100%",
        height: '100vh',
    },
    cardContainer: {
        padding: '20px 20px 0px 20px'
    },
    searchHighlight: {
        color: 'yellow'
    },
}
