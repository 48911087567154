import { AxiosAnalyticsObj } from "../../Configurations/axios-ameya-setup";
import { fetchAccountId, fetchSubscriberId, fetchSubscriptionId } from "../../../utils/GetAccountDetails"

class ameyaBackendApi {

    constructor() {
        this.accountId = null;
        this.subscribeId = null;
        this.subscriptionId = null;
    }

    async initialize() {
        this.accountId = await fetchAccountId();
        this.subscriberId = await fetchSubscriberId();
        this.subscriptionId = await fetchSubscriptionId();
    }

    // Mongo SourceApi
    mongoConnectionDBLists = async (reqObj) => {
        await this.initialize();
        return AxiosAnalyticsObj.post(`/subscriber/${this.subscriberId}/subscription/${this.subscriptionId}/function/user-management/event/mongo-list-connection-dbs`, reqObj)
    }

    mongoConnectionCollectionsLists = async (reqObj) => {
        await this.initialize();
        return AxiosAnalyticsObj.post(`/subscriber/${this.subscriberId}/subscription/${this.subscriptionId}/function/user-management/event/mongo-list-connection-collections`, reqObj)
    }

    mongoUserConnectionRegister = async (reqObj) => {
        await this.initialize();
        return AxiosAnalyticsObj.post(`/subscriber/${this.subscriberId}/subscription/${this.subscriptionId}/function/user-management/event/mongo-user-connection-register`, reqObj)
    }

    getSourceDetails = async () => {
        await this.initialize();
        return AxiosAnalyticsObj.get(`/subscriber/${this.subscriberId}/subscription/${this.subscriptionId}/function/query-engine/event/source-details`)
    }

    getListOfConnetions = async () => {
        await this.initialize();
        return AxiosAnalyticsObj.get(`/subscriber/${this.subscriberId}/subscription/${this.subscriptionId}/function/user-management/event/get-list-of-connections`)
    }

    updateSourceConnetion = async (reqObj) => {
        await this.initialize();
        return AxiosAnalyticsObj.put(`/subscriber/${this.subscriberId}/subscription/${this.subscriptionId}/function/user-management/event/update-connection-status`, reqObj)
    }

    deleteSourceConnetion = async (source_id) => {
        await this.initialize();
        return AxiosAnalyticsObj.delete(`/subscriber/${this.subscriberId}/subscription/${this.subscriptionId}/function/user-management/event/delete-connection?source_id=${source_id}`)
    }

    // FilesSourceApi
    uploadFile = async (reqObj) => {
        await this.initialize();
        return AxiosAnalyticsObj.post(`/subscriber/${this.subscriberId}/subscription/${this.subscriptionId}/function/data-processing/event/file-upload`, reqObj)
    }

    getFileList = async () => {
        await this.initialize();
        return AxiosAnalyticsObj.get(`/subscriber/${this.subscriberId}/subscription/${this.subscriptionId}/function/data-processing/event/get-files-list`)
    }

    fileGrouping = async (reqObj) => {
        await this.initialize();
        return AxiosAnalyticsObj.post(`/subscriber/${this.subscriberId}/subscription/${this.subscriptionId}/function/data-processing/event/file-grouping`, reqObj)
    }

    deleteFile = async (file_id) => {
        await this.initialize();
        return AxiosAnalyticsObj.post(`/subscriber/${this.subscriberId}/subscription/${this.subscriptionId}/${file_id}/function/data-processing/event/file-delete`)
    }

}

const AnalyticsApi = new ameyaBackendApi();
export default AnalyticsApi;


