import { getStyles } from './Styles';
import { Box, Button, FormControl, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react';

import { useTheme } from '@mui/material/styles';
import { getComponentsStyles } from '../../../styles/componentsStyles';
import { buttonStyles } from '../../../styles/buttonStyles';
import { AlertMessages } from '../../../utils/AlertMessages'
import { tostAlert } from '../../../utils/AlertToast'
import LoadBar from '../../../utils/LoadBar'
import ProjectsApi from '../../../Api/Services/AppflyteBackend/ProjectsApi'
import { getSessionData } from '../../../utils/sessionDataHandle';

function GeneralSettings() {
  const theme = useTheme();
  const styles = getStyles(theme);
  const componentsStyles = getComponentsStyles(theme);

  const project_info = { projectName: '', projectDescription: '' }
  const [projectdetails, setProjectDetails] = useState(project_info)
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false)

  const selected_project = getSessionData("selected_project")

  useEffect(() => {
    setProjectDetails({
      ...projectdetails,
      projectName: selected_project?.payload?.name,
      projectDescription: selected_project?.payload?.description
    });
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    const errors = { ...formErrors };
    if (formErrors?.projectName && projectdetails?.projectName?.trim()) { delete errors?.projectName }
    if (formErrors?.projectDescription && projectdetails?.projectDescription?.trim()) { delete errors?.projectDescription }
    if (JSON.stringify(errors) !== JSON.stringify(formErrors)) { setFormErrors(errors) }
  }, [projectdetails, formErrors]);

  const handleProjectSettings = async () => {
    setLoading(true)
    try {
      const errors = {};
      if (!projectdetails?.projectName?.trim()) { errors.projectName = 'Project name is required' }
      if (!projectdetails?.projectDescription?.trim()) { errors.projectDescription = 'Project description is required' }
      if (Object.keys(errors).length === 0) {

        const EditProjectObject = {
          id: selected_project?.payload?.__auto_id__,
          fields: [
            {
              "path": '$.name',
              "value": projectdetails.projectName
            },
            {
              "path": '$.description',
              "value": projectdetails.projectDescription
            }
          ]
        }
        const response = await ProjectsApi.updateProject(JSON.stringify(EditProjectObject), selected_project?.payload?.__auto_id__, selected_project?.update_key)
        if (response.status === 200) {
          const message = await AlertMessages('update', 'project');
          tostAlert(message, 'success')
        } else {
          const message = await AlertMessages('error', 'project');
          tostAlert(message, 'error')
        }
      }
      else {
        setFormErrors(errors);
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }


  return (
    <Box sx={styles.mainContainer}>
      <Box sx={styles.cardContainer}>

        {loading && (<LoadBar />)}

        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
          <Typography sx={styles.mainHeadingText}>General Settings</Typography>
          <Box display={'flex'} alignItems={'center'}>
            <Button
              sx={{ ...buttonStyles.primaryBtn, width: '100px' }}
              disabled={selected_project === null}
              onClick={() => handleProjectSettings()}
            >
              <Typography sx={styles.btnText}>SAVE</Typography>
            </Button>
            <Button sx={{ ...buttonStyles.secondaryBtn, width: '100px', marginLeft: '10px' }}>
              <Typography sx={styles.btnText}>CANCEL</Typography>
            </Button>
          </Box>
        </Box>

        <Typography sx={{ ...styles.paraText, marginTop: '10px' }}>
          Configure you app name and icon
        </Typography>

        <Box marginTop={'20px'}>
          <Box>
            <FormControl>
              <Typography sx={{ ...styles.paraText, marginBottom: '6px' }}>Project Name</Typography>
              <TextField
                id="project-name"
                placeholder='Project Name'
                size='small'
                sx={{ ...componentsStyles.textField, width: '600px' }}
                name="project_name"
                value={projectdetails.projectName}
                onChange={(e) => setProjectDetails({ ...projectdetails, projectName: e.target.value })}
              />
            </FormControl>
            {formErrors.projectName && <Typography sx={{ ...styles.paraText, color: 'red' }}>{formErrors.projectName}</Typography>}
          </Box>
          <Box>
            <FormControl sx={{ marginTop: '20px' }}>
              <Typography sx={{ ...styles.paraText, marginBottom: '6px' }}>Description</Typography>
              <TextField
                id="project-descriptions"
                placeholder='Description'
                size='medium'
                sx={{ ...componentsStyles.textField, width: '600px' }}
                name="project_descriptions"
                value={projectdetails.projectDescription}
                onChange={(e) => setProjectDetails({ ...projectdetails, projectDescription: e.target.value })}
              />
            </FormControl>
            {formErrors.projectDescription && <Typography sx={{ ...styles.paraText, color: 'red' }}>{formErrors.projectDescription}</Typography>}
          </Box>
        </Box>

      </Box>
    </Box>
  )
}

export default GeneralSettings