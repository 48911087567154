import { jwtDecode } from "jwt-decode";
import RefreshTokenApi from "./RefreshTokenApi";

const isTokenExpiredOrAboutToExpire = (jwtToken) => {
    try {
        const token = jwtDecode(jwtToken);
        const tokenExpireTime = token.exp;
        const currentTime = Math.floor(Date.now() / 1000);

        if (currentTime >= tokenExpireTime) {
            return "expired";
        }

        if ((tokenExpireTime - currentTime) <= 600) {
            return "about-to-expire";
        }

        return "valid";
    } catch (error) {
        console.error("Invalid token:", error);
        return "expired";
    }
};

const refreshAccessToken = async (refreshtoken, navigate) => {
    try {
        const response = await RefreshTokenApi.getRefreshToken(refreshtoken)
        const jwtToken = await response.json();
        if (jwtToken) {
            localStorage.setItem('dpod-token', JSON.stringify(jwtToken.token));
            localStorage.setItem('refresh-token', JSON.stringify(jwtToken.refresh_token));
            return;
        } else {
            navigate("/login")
            localStorage.clear();
        }
    }
    catch (error) {
        navigate("/login")
        localStorage.clear();
        console.log(error)
    }
}

export const handleCheckLoginToken = async (navigate) => {
    try {
        const jwtToken = JSON.parse(localStorage.getItem("dpod-token"));
        const refreshToken = JSON.parse(localStorage.getItem("refresh-token"));

        if (!jwtToken || !refreshToken) {
            navigate("/login");
            localStorage.clear();
            return;
        }

        const tokenStatus = isTokenExpiredOrAboutToExpire(jwtToken);

        if (tokenStatus === "expired") {
            console.log("Token is expired, refreshing...");
            await refreshAccessToken(refreshToken, navigate);
        } else if (tokenStatus === "about-to-expire") {
            console.log("Token is about to expire, refreshing...");
            await refreshAccessToken(refreshToken, navigate);
        }
    } catch (error) {
        console.error("Error checking token:", error);
    }
};