import React, { useEffect, useState } from 'react';
import { Autocomplete, Box, Button, FormControl, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { getComponentsStyles } from '../../../../styles/componentsStyles';
import { getStyles } from './styles';
import { buttonStyles } from '../../../../styles/buttonStyles';
import AnalyticsApi from '../../../../Api/Services/AmeyaBackend/AnalyticsToolApi';
import { tostAlert } from '../../../../utils/AlertToast';
import { ReactSVG } from 'react-svg';
import LoadBar from '../../../../utils/LoadBar';
import { apiErrorHandler } from '../../../../utils/ApiErrorHandler';
import { IconSvg } from '../../../../utils/globalIcons';

function MongoDb() {

    const theme = useTheme();
    const styles = getStyles(theme);
    const componentsStyles = getComponentsStyles(theme);

    const [loading, setLoading] = useState(false);
    const [existingConnections, setExistingConnections] = useState([])
    const [addNewConnection, setAddNewConnection] = useState(false);
    const [editConnection, setEditConnection] = useState(false)

    const [connectionLoading, setConnectionLoading] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [connectionStatus, setConnectionStatus] = useState('');
    const [sourceSelected, setSourceSelected] = useState(null);

    const [mongoSettingsStep, setMongoSettingsStep] = useState(1);
    const connectionDetails = { connection_source_name: "", connection_mongo_uri: "", connection_mongo_db: null, connection_collection: null }
    const [connectionData, setConnectionData] = useState(connectionDetails);
    const [connectShow, setConnectShow] = useState(false);
    const [mongoUri, setMongoUri] = useState('')
    const [mongodbs, setMongodbs] = useState([]);
    const [mongoCollections, setMongoCollection] = useState([]);

    useEffect(() => {
        fetchSourceDetails()
    }, [])

    const fetchSourceDetails = async () => {
        setConnectionLoading(true);
        try {
            const response = await AnalyticsApi.getListOfConnetions();
            if (response.status === 200) {
                const responseData = response?.data?.data ?? [];
                setExistingConnections(responseData)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setConnectionLoading(false);
        }
    }

    const handleSourceSelection = async (event, connection) => {
        setConnectionLoading(true);

        try {
            setSourceSelected(connection);
            setAddNewConnection(false);
            setConnectShow(false);
            if (!connection) {
                setConnectionData(connectionDetails);
                setMongoCollection([]);
                setEditConnection(false);
                setAddNewConnection(false);
                setSourceSelected(null);
                setMongoSettingsStep(1);
                setConnectionStatus('');
                return;
            }
            setMongoUri(connection?.db_connection?.uri);
            let updatedData = {
                connection_source_name: connection?.source_name ?? "",
                connection_mongo_uri: connection?.db_connection?.uri,
                connection_mongo_db: null,
                connection_collection: null
            };

            try {
                const databaseReqObj = { uri: connection?.db_connection?.uri };
                const database_response = await AnalyticsApi.mongoConnectionDBLists(JSON.stringify(databaseReqObj));

                if (database_response.status === 200) {
                    const connectionDbs = database_response?.data?.dbs ?? [];
                    setMongodbs(connectionDbs);
                    const filtered_db = connectionDbs.find(item => item === connection?.db_connection?.db) ?? null;
                    updatedData.connection_mongo_db = filtered_db;

                    if (filtered_db) {
                        try {
                            const colReqObj = { uri: connection?.db_connection?.uri, db: filtered_db };
                            const collection_response = await AnalyticsApi.mongoConnectionCollectionsLists(JSON.stringify(colReqObj));
                            if (collection_response.status === 200) {
                                const connectionCollections = collection_response?.data?.collections ?? [];
                                setMongoCollection(connectionCollections);
                                const filtered_collection = connectionCollections.find(item => item === connection?.db_connection?.collection) ?? null;
                                updatedData.connection_collection = filtered_collection;
                            }
                        } catch (collectionError) {
                            console.error("Error fetching collections:", collectionError);
                        }
                    }
                }
            } catch (dbError) {
                console.error("Error fetching databases:", dbError);
            }
            setConnectionData(prev => ({ ...prev, ...updatedData }));
            setEditConnection(true);
            setMongoSettingsStep(3);
        } catch (error) {
            console.error("Error in handleSourceSelection:", error);
        } finally {
            setConnectionLoading(false);
        }
    };

    function handleAddNewConnection() {
        setEditConnection(false)
        setAddNewConnection(true);
        setSourceSelected(null);
        setMongoSettingsStep(1);
        setConnectShow(true)
        setConnectionStatus('')
        setConnectionData(connectionDetails)
        setMongodbs([])
        setMongoCollection([])
    }

    function handleExistingConnections() {
        setEditConnection(true)
        setAddNewConnection(false);
        setSourceSelected(null);
        setMongoSettingsStep(1);
        setConnectionStatus('')
        setConnectionData(connectionDetails)
        setMongodbs([])
        setMongoCollection([])
    }

    useEffect(() => {
        const errors = { ...formErrors };
        if (formErrors?.connection_source_name && connectionData?.connection_source_name?.trim()) { delete errors?.connection_source_name }
        if (formErrors?.connection_mongo_uri && connectionData?.connection_mongo_uri?.trim()) { delete errors?.connection_mongo_uri }
        if (formErrors?.connection_collection && connectionData?.connection_collection) { delete errors?.connection_collection }
        if (formErrors?.connection_mongo_db && connectionData?.connection_mongo_db) { delete errors?.connection_mongo_db }
        if (JSON.stringify(errors) !== JSON.stringify(formErrors)) { setFormErrors(errors) }
    }, [connectionData, formErrors]);

    const handleUriChange = (e) => {
        const newUri = e.target.value
        if (newUri !== mongoUri) {
            setConnectShow(true)
            setMongoSettingsStep(1)
            setConnectionStatus('')
            setConnectionData({ ...connectionData, connection_mongo_uri: newUri })
            return
        }
        setMongoSettingsStep(3)
        setConnectShow(false)
    }

    const handleMongouriSelection = async () => {
        setLoading(true)
        try {
            const errors = {};
            if (!connectionData?.connection_source_name.trim()) { errors.connection_source_name = 'This field is required' }
            if (!connectionData?.connection_mongo_uri?.trim()) { errors.connection_mongo_uri = 'This field is required' }

            if (Object.keys(errors).length === 0) {

                setConnectShow(false)
                setMongoUri(connectionData.connection_mongo_uri)
                setMongodbs([])
                setMongoCollection([])

                const reqObj = {
                    "uri": connectionData.connection_mongo_uri
                }

                const response = await AnalyticsApi.mongoConnectionDBLists(JSON.stringify(reqObj));
                if (response.status === 200) {
                    setConnectionStatus("success")

                    const connectionDbs = response?.data?.dbs ?? [];
                    const connectionURI = response?.data?.uri ?? "";
                    setMongodbs(connectionDbs)
                    setConnectionData({ ...connectionData, connection_mongo_uri: connectionURI })
                    setMongoSettingsStep(2)
                } else {
                    setConnectionStatus("failed")
                }
            } else {
                setFormErrors(errors);
            }
        } catch (error) {
            console.log(error);
            setConnectionStatus("failed")
            tostAlert('Request Failed Try Again', 'error');
        } finally {
            setLoading(false)
        }
    }

    const handleMongoDataBaseSelection = async (event, value) => {
        setMongoSettingsStep(2);
        setMongoCollection([])
        setConnectionData({ ...connectionData, connection_mongo_db: value || null, connection_collection: null });
        setLoading(true)
        try {
            const errors = {};
            if (!connectionData?.connection_source_name.trim()) { errors.connection_source_name = 'This field is required' }
            if (!connectionData?.connection_mongo_uri?.trim()) { errors.connection_mongo_uri = 'This field is required' }
            if (!value) { errors.connection_mongo_db = 'This field is required' }

            if (Object.keys(errors).length === 0) {
                const reqObj = {
                    "uri": connectionData.connection_mongo_uri,
                    "db": value
                }
                const response = await AnalyticsApi.mongoConnectionCollectionsLists(JSON.stringify(reqObj));
                if (response.status === 200) {
                    const connectionURI = response?.data?.uri ?? "";
                    const connectionDb = response?.data?.db ?? null;
                    const connectionCollections = response?.data?.collections ?? []
                    setMongoCollection(connectionCollections)
                    setConnectionData({
                        ...connectionData,
                        connection_mongo_uri: connectionURI,
                        connection_mongo_db: connectionDb
                    })
                    setMongoSettingsStep(3)
                } else {
                    tostAlert('Invalid Database', 'warning');
                }
            } else {
                setFormErrors(errors);
            }
        } catch (error) {
            console.log(error);
            setConnectionData({ ...connectionData, connection_mongo_db: null })
            tostAlert('Request Failed Try Again', 'error');
        } finally {
            setLoading(false)
        }
    }

    const handleCollectionSelection = (event, value) => {
        setConnectionData({ ...connectionData, connection_collection: value || null });
    };

    const handleMongoConnectionRegister = async () => {

        if (!connectionData?.connection_mongo_db) {
            tostAlert('Please Select Database and try again.', 'warning')
            return
        }
        setLoading(true)
        try {
            const errors = {};
            if (!connectionData?.connection_source_name.trim()) { errors.connection_source_name = 'This field is required' }
            if (!connectionData?.connection_mongo_uri?.trim()) { errors.connection_mongo_uri = 'This field is required' }
            if (!connectionData?.connection_mongo_db) { errors.connection_mongo_db = 'This field is required' }
            if (!connectionData?.connection_collection) { errors.connection_collection = 'This field is required' }

            if (Object.keys(errors).length === 0) {
                const reqObj = {
                    "connection_details": {
                        "uri": connectionData.connection_mongo_uri,
                        "db": connectionData.connection_mongo_db,
                        "collection": connectionData.connection_collection
                    },
                    "source_details": {
                        "source_name": connectionData.connection_source_name
                    }
                }
                const response = await AnalyticsApi.mongoUserConnectionRegister(JSON.stringify(reqObj));
                if (response.status === 200) {
                    tostAlert('Connection registered Successfully', 'success');
                    fetchSourceDetails()
                    handleExistingConnections()
                } else {
                    tostAlert('Invalid Details', 'warning');
                }
            } else {
                setFormErrors(errors);
            }
        } catch (error) {
            console.log(error)
            tostAlert('Request Failed Try Again', 'error');
        } finally {
            setLoading(false)
        }
    }

    const handleMongoConnectionUpdate = async (item) => {

        if (!connectionData?.connection_mongo_db) {
            tostAlert('Please Select Database and try again.', 'warning')
            return
        }
        setLoading(true)
        try {
            const errors = {};
            if (!connectionData?.connection_source_name.trim()) { errors.connection_source_name = 'This field is required' }
            if (!connectionData?.connection_mongo_uri?.trim()) { errors.connection_mongo_uri = 'This field is required' }
            if (!connectionData?.connection_mongo_db) { errors.connection_mongo_db = 'This field is required' }
            if (!connectionData?.connection_collection) { errors.connection_collection = 'This field is required' }

            if (Object.keys(errors).length === 0) {
                const source_id = item?.source_id ?? null;
                const reqObj = {
                    "db_connection": {
                        "uri": connectionData.connection_mongo_uri,
                        "db": connectionData.connection_mongo_db,
                        "collection": connectionData.connection_collection
                    },
                    "source_id": source_id
                }
                console.log("reqObj:", reqObj)
                // const response = await AnalyticsApi.updateSourceConnetion(JSON.stringify(reqObj));
                // if (response.status === 200) {
                //     tostAlert('Connection Updated Successfully', 'success');
                //     fetchSourceDetails()
                //     setMongoSettingsStep(1)
                //     setConnectionData(connectionDetails)
                // } else {
                //     tostAlert('Invalid Details', 'warning');
                // }
            } else {
                setFormErrors(errors);
            }
        } catch (error) {
            console.log(error)
            tostAlert('Request Failed Try Again', 'error');
        } finally {
            setLoading(false)
        }
    }

    const handleMongoConnectionDeletion = async (item) => {
        try {
            const check = window.confirm("Are you sure you want to delete the connection?");
            if (check) {
                const source_id = item?.source_id ?? null;
                const response = await AnalyticsApi.deleteSourceConnetion(source_id);
                if (response.status === 200) {
                    tostAlert('Connection Deleted Successfully', 'success');
                    fetchSourceDetails()
                } else {
                    tostAlert('Connection Deletion Failed', 'error')
                }
            }
        } catch (error) {
            console.log(error)
            apiErrorHandler(error)
        }
    }

    return (
        <Box >

            {(loading || connectionLoading) && <LoadBar />}

            {!addNewConnection &&
                <Box marginTop={'25px'} >
                    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} width={'750px'}>
                        <Typography sx={{ ...styles.mainHeadingText, marginBottom: '6px' }}>Existing Connections</Typography>

                        <Button
                            sx={styles.addConnectionBtn}
                            onClick={handleAddNewConnection}
                        >
                            <Box display={'flex'} alignItems={'center'}>
                                <ReactSVG src={IconSvg.sourceDatabaseIcon} style={{ height: '24px', marginRight: '10px' }} />
                                <Typography sx={styles.addConnectionBtnText}>NEW CONNECTION</Typography>
                            </Box>
                        </Button>
                    </Box>

                    <Box display={'flex'} alignItems={'center'} marginTop={'10px'}>
                        <FormControl>
                            <Autocomplete
                                sx={{ ...componentsStyles.autocomplete, width: '750px' }}
                                options={existingConnections}
                                getOptionLabel={(option) => option?.source_name || ""}
                                id="clear-on-escape"
                                clearOnEscape
                                size='small'
                                disabled={loading}
                                value={sourceSelected}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" placeholder="Database" />
                                )}
                                onChange={handleSourceSelection}
                            />
                        </FormControl>
                    </Box>
                </Box>
            }

            {addNewConnection && (
                <Box marginTop={'25px'} display={'flex'} justifyContent={'space-between'} alignItems={'center'} width={'750px'}>
                    <Typography sx={{ ...styles.mainHeadingText, marginBottom: '6px' }}>Add Connections</Typography>
                    <Button
                        sx={styles.addConnectionBtn}
                        onClick={handleExistingConnections}
                    >
                        <Box display={'flex'} alignItems={'center'}>
                            <ReactSVG src={IconSvg.soucelistIcon} style={{ height: '24px', marginRight: '10px' }} />
                            <Typography sx={styles.addConnectionBtnText}>Existing Connections</Typography>
                        </Box>
                    </Button>
                </Box>
            )}

            <Box paddingBottom={'30px'}>

                {addNewConnection &&
                    <Box marginTop={'20px'}>
                        <FormControl>
                            <Typography sx={{ ...styles.paraText, marginBottom: '6px' }}>Connection Name</Typography>
                            <TextField
                                id="source_name"
                                placeholder='Enter Source Name'
                                size='small'
                                sx={{ ...componentsStyles.textField, width: '600px' }}
                                value={connectionData.connection_source_name}
                                onChange={(e) => setConnectionData({ ...connectionData, connection_source_name: e.target.value })}
                            />
                        </FormControl>
                        {formErrors.connection_source_name && <Typography sx={{ ...styles.paraText, color: 'red' }}>{formErrors.connection_source_name}</Typography>}
                    </Box>
                }

                {(addNewConnection || sourceSelected) && (<Box marginTop={'20px'}>
                    <FormControl>
                        <Typography sx={{ ...styles.paraText, marginBottom: '6px' }}>Connection String</Typography>
                        <TextField
                            id="mongo-uri"
                            placeholder='Enter Mongo Uri'
                            size='medium'
                            disabled={loading}
                            sx={{ ...componentsStyles.textField, width: '800px' }}
                            value={connectionData.connection_mongo_uri}
                            onChange={(e) => handleUriChange(e)}
                        />
                    </FormControl>
                    {formErrors.connection_mongo_uri && <Typography sx={{ ...styles.paraText, color: 'red' }}>{formErrors.connection_mongo_uri}</Typography>}
                </Box>)}


                <Box marginTop={'10px'} >
                    {connectionStatus === "success" &&
                        (<Box display={'flex'} alignItems={'center'} flexDirection={'row'}>
                            <ReactSVG style={styles.connectionIcon} src={IconSvg.plugConnectedIcon} />
                            <Typography sx={styles.paraText}>Connection successful</Typography>
                        </Box>)
                    }
                    {connectionStatus === "failed" &&
                        (<Box display={'flex'} alignItems={'center'} flexDirection={'row'}>
                            <ReactSVG style={styles.connectionIcon} src={IconSvg.plugNotConnectedIcon} />
                            <Typography sx={styles.paraText}>Unable to connect</Typography>
                        </Box>)
                    }
                </Box>

                {((mongoSettingsStep === 1 || connectionStatus === "failed") && connectShow) &&
                    <Box marginTop={'20px'}>
                        <Button
                            sx={{ ...buttonStyles.primaryBtn, width: '100px', }}
                            disabled={loading || mongoSettingsStep === 2 || mongoSettingsStep === 3}
                            onClick={() => handleMongouriSelection()}
                        >
                            <Typography sx={styles.btnText}>CONNECT</Typography>
                        </Button>
                    </Box>
                }

                {(mongoSettingsStep === 2 || mongoSettingsStep === 3) &&
                    <Box marginTop={'20px'}>
                        <FormControl>
                            <Typography sx={{ ...styles.paraText, marginBottom: '6px' }}>Select Database</Typography>
                            <Autocomplete
                                sx={{ ...componentsStyles.autocomplete, width: '200px' }}
                                options={mongodbs}
                                getOptionLabel={(option) => option || ""}
                                id="clear-on-escape"
                                clearOnEscape
                                size='small'
                                disabled={loading}
                                value={mongodbs.find((option) => option === connectionData.connection_mongo_db) || null}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" placeholder="Database" />
                                )}
                                onChange={handleMongoDataBaseSelection}
                            />
                            {formErrors.connection_mongo_db && <Typography sx={{ ...styles.paraText, color: 'red' }}>{formErrors.connection_mongo_db}</Typography>}
                        </FormControl>
                    </Box>
                }

                {mongoSettingsStep === 3 &&
                    <Box marginTop={'20px'}>
                        <FormControl>
                            <Typography sx={{ ...styles.paraText, marginBottom: '6px' }}>Select Collection</Typography>
                            <Autocomplete
                                sx={{ ...componentsStyles.autocomplete, width: '200px' }}
                                options={mongoCollections}
                                getOptionLabel={(option) => option || ""}
                                id="clear-on-escape"
                                clearOnEscape
                                size='small'
                                value={mongoCollections.find((option) => option === connectionData.connection_collection) || null}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" placeholder="Collections" />
                                )}
                                onChange={handleCollectionSelection}
                            />
                            {formErrors.connection_collection && <Typography sx={{ ...styles.paraText, color: 'red' }}>{formErrors.connection_collection}</Typography>}
                        </FormControl>
                    </Box>
                }


                {(addNewConnection && mongoSettingsStep === 3) &&
                    <Button
                        sx={{ ...buttonStyles.primaryBtn, width: '178px', marginTop: '20px' }}
                        disabled={loading}
                        onClick={() => handleMongoConnectionRegister()}
                    >
                        <Typography sx={styles.btnText}>SAVE CONNECTION</Typography>
                    </Button>
                }

                {(mongoSettingsStep === 3 && editConnection) &&
                    <Box marginTop={'20px'} display={'flex'} alignItems={'center'}>
                        <Button
                            sx={{ ...buttonStyles.primaryBtn, width: '178px', }}
                            disabled={loading}
                            onClick={() => handleMongoConnectionUpdate(sourceSelected)}
                        >
                            <Typography sx={styles.btnText}>SAVE CONNECTION</Typography>
                        </Button>

                        <Button
                            sx={{ ...buttonStyles.primaryBtn, width: '178px', marginLeft: '20px', bgcolor: "#E00000" }}
                            disabled={loading}
                            onClick={() => handleMongoConnectionDeletion(sourceSelected)}
                        >
                            <Typography sx={styles.btnText}>DELETE CONNECTION</Typography>
                        </Button>
                    </Box>
                }

            </Box>
        </Box>
    )
}

export default MongoDb