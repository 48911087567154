import React, { useEffect, useState } from 'react';
import {
    Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel,
    Box, Pagination, Select, MenuItem, Typography, CircularProgress,
    Checkbox
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { tableStyles } from '../styles/tableStyles';
import { getComponentsStyles } from '../styles/componentsStyles';


const GeneralTable = ({ data, columns, rowsPerPageOptions = [5, 10, 20, 50], defaultRowsPerPage = 20, loading = false, is_checkBox = true }) => {

    const theme = useTheme();
    const componentsStyles = getComponentsStyles(theme);

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState(columns[0].id);
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
    const [displayData, setDisplayData] = useState([]);
    const [userTriggeredSort, setUserTriggeredSort] = useState(false);

    useEffect(() => {
        setDisplayData(data);
        setPage(1);
        setUserTriggeredSort(false);
    }, [data]);

    const handleSortRequest = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        setUserTriggeredSort(true);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
    };

    const getNestedValue = (obj, path) => {
        return path?.split('.')?.reduce((acc, key) => acc?.[key], obj) || '';
    }

    const sortedData = userTriggeredSort
        ? [...displayData]?.sort((a, b) => {
            const aValue = getNestedValue(a, orderBy)?.toString()?.toLowerCase();
            const bValue = getNestedValue(b, orderBy)?.toString()?.toLowerCase();

            if (aValue < bValue) return order === 'asc' ? -1 : 1;
            if (aValue > bValue) return order === 'asc' ? 1 : -1;
            return 0;
        })
        : displayData;

    const paginatedData = sortedData?.slice((page - 1) * rowsPerPage, page * rowsPerPage);

    return (
        <Box width={'100%'} marginBottom={'20px'}>
            <Paper sx={{ bgcolor: 'transparent' }}>
                <TableContainer component={Paper} sx={tableStyles.tcontainer}>
                    <Table stickyHeader
                        sx={{ height: displayData.length <= 0 ? '400px' : `${paginatedData.length}px` }}
                    >

                        <TableHead sx={tableStyles.thead}>
                            <TableRow>
                                {columns?.map((column) => (
                                    <TableCell key={column.id}
                                        sx={{
                                            ...tableStyles.thcell,
                                            width: column.width || 'auto',
                                        }}
                                    >
                                        {column.sortable ? (
                                            <TableSortLabel sx={tableStyles.sortlabel}
                                                active={orderBy === column.id}
                                                direction={orderBy === column.id ? order : 'asc'}
                                                onClick={() => handleSortRequest(column.id)}
                                            >
                                                <Typography sx={tableStyles.theadText}>
                                                    {column.label}
                                                </Typography>
                                            </TableSortLabel>
                                        ) : (
                                            <Typography sx={tableStyles.theadText}>
                                                {column.label}
                                            </Typography>
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>


                        {/* <TableHead sx={tableStyles.thead}>
                            <TableRow>
                                {columns?.map((column) => (
                                    <TableCell key={column.id}
                                        sx={{
                                            ...tableStyles.thcell,
                                            width: column.width || 'auto',
                                        }}
                                    >
                                        <TableSortLabel sx={tableStyles.sortlabel}
                                            active={orderBy === column.id}
                                            direction={orderBy === column.id ? order : 'asc'}
                                            onClick={() => handleSortRequest(column.id)}
                                        >
                                            <Typography sx={tableStyles.theadText}>
                                                {column.label}
                                            </Typography>
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead> */}
                        <TableBody>

                            {!loading && displayData?.length === 0 && (
                                <TableRow sx={tableStyles.emptytr}>
                                    <TableCell colSpan={8} sx={tableStyles.emptytdcell}>
                                        No records to display
                                    </TableCell>
                                </TableRow>
                            )}

                            {displayData?.length === 0 && loading ?
                                (<TableRow sx={tableStyles.emptytr}>
                                    <TableCell colSpan={7} sx={tableStyles.emptytdcell}>
                                        <CircularProgress sx={{ color: '#0B51C5' }} />
                                    </TableCell>
                                </TableRow>)
                                :
                                (displayData?.length > 0 && paginatedData?.map((row, index) => (
                                    <TableRow key={index} sx={{ ...tableStyles.trow }}>
                                        {columns.map((column) => (
                                            <TableCell key={column.id} style={{
                                                ...tableStyles.tdcell,
                                                borderBottom: index < paginatedData.length - 1 && row.temp_layout_id !== paginatedData[index + 1]?.temp_layout_id
                                                    ? '1.5px solid #c7cbd4'
                                                    : undefined,
                                            }}>
                                                <Typography>
                                                    {column.renderCell
                                                        ? column.renderCell(row)
                                                        : row[column.id]}
                                                </Typography>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                )))
                            }

                        </TableBody>
                    </Table>
                </TableContainer>

                {displayData?.length > 0 &&
                    (<Box display={'flex'} justifyContent={'space-between'} padding={'10px'} alignItems={'center'} bgColor={"#FFFFFF"}>
                        <Box display={'flex'} alignItems={'center'}>
                            <Box marginLeft={'20px'}>
                                <Select
                                    value={rowsPerPage} onChange={handleChangeRowsPerPage}
                                    sx={{
                                        ...componentsStyles.selectField,
                                        width: '110px',
                                        height: '35px',
                                    }}
                                >
                                    {rowsPerPageOptions?.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option} per page
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Box>
                        </Box>
                        <Pagination
                            count={Math.ceil(displayData.length / rowsPerPage)}
                            page={page}
                            onChange={handleChangePage}
                            color="standard"
                        />
                    </Box>)
                }
            </Paper>
        </Box>
    );
};

export default GeneralTable;