import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';

import { Box, Button, InputAdornment, MenuItem, Select, TextField, Tooltip, Typography } from '@mui/material';
import { Search } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { getComponentsStyles } from '../../../styles/componentsStyles';
import { Styles } from './Styles';
import "./Styles.css"
import { fontStyles } from '../../../styles/fontStyles';

import { buttonStyles } from '../../../styles/buttonStyles';
import LoadBar from '../../../utils/LoadBar';
import { IconSvg } from '../../../utils/globalIcons';
import DocumentSearchApi from '../../../Api/Services/AmeyaBackend/DocumentSearchApi';
import getAmeyaSettingsData from '../../../utils/ApiFunctions/AmeyaSettingsData';
import { tostAlert } from '../../../utils/AlertToast';
import GeneralTable from '../../../components/GeneralTable';
import { getSessionData } from '../../../utils/sessionDataHandle';

function DocSearchPlayground() {

    const theme = useTheme();
    const componentsStyles = getComponentsStyles(theme);

    const [initialLoading, setInitialLoading] = useState(false)
    const [realmSettings, setRealmSettings] = useState([]);
    const [selectedKey, setSelectedKey] = useState('');
    const [values, setValues] = useState({});
    const [savedValues, setSavedValues] = useState({});

    const [docSearchItem, setDocSearchItem] = useState('');
    const [docSearchData, setDocSearchData] = useState([])
    const [docSearchLoading, setDocSearchLoading] = useState(false)


    useEffect(() => {
        fetchSettingsData();
        //eslint-disable-next-line
    }, []);

    const fetchSettingsData = async () => {
        setInitialLoading(true);
        try {
            const selected_project = getSessionData("selected_project")
            const response = await getAmeyaSettingsData(selected_project?.payload?.__auto_id__);
            if (response?.length > 0) {
                const responseData = response?.[0] ?? [];
                const realm_settings = responseData?.payload?.settings?.realm_attributes ?? [];

                if (realm_settings?.length > 0) {
                    setRealmSettings(realm_settings);
                }
            }
        } catch (error) {
            console.error('Error fetching settings data:', error);
        } finally {
            setInitialLoading(false);
        }
    };

    const handleSelectChange = (event) => {
        setSelectedKey(event.target.value);
    };

    const handleValueChange = (event) => {
        setValues((prev) => ({
            ...prev,
            [selectedKey]: event.target.value,
        }));
    };

    const handleSaveValue = () => {
        if (selectedKey && values[selectedKey]) {
            setSavedValues((prev) => ({
                ...prev,
                [selectedKey]: values[selectedKey],
            }));
            return
        }
        tostAlert("Value not added", 'warning')
    };

    const handleRemoveValue = (remove_key) => {
        if (remove_key) {
            setSavedValues((prev) => {
                const newSavedValues = { ...prev };
                delete newSavedValues[remove_key];
                return newSavedValues;
            });
        }
    };


    const handleDocumentSearch = async (searchQuery) => {

        if (!searchQuery.trim()) {
            setDocSearchData([]);
            return;
        }

        try {
            setDocSearchLoading(true)
            const selected_project = getSessionData("selected_project")

            const reqObj = {
                "query": searchQuery,
                "realm": Object.keys(savedValues)?.length > 0 ? savedValues : {},
                "project_id": selected_project?.payload?.__auto_id__,
                "state": "Draft"
            }

            const response = await DocumentSearchApi.documentSearchQuery(JSON.stringify(reqObj));
            if (response.status === 200) {
                const responseData = response?.data
                const result = responseData?.result ?? [];
                const sorted_doc_result = result
                    .map(item => {
                        const relavence_score = parseFloat(item.relavence_score?.toString().trim());
                        return { ...item, relavence_score, current_query: searchQuery };
                    })
                    .filter(item => !isNaN(item.relavence_score))
                    .sort((a, b) => b.relavence_score - a.relavence_score);
                setDocSearchData(sorted_doc_result);
                return result;
            }
        } catch (error) {
            console.log(error)
        } finally {
            setDocSearchLoading(false)
        }
    }

    const handleDocSearchChange = (e) => {
        setDocSearchItem(e.target.value);
    }

    const columns = [
        {
            id: 'document_name',
            label: 'Document',
            width: '30%',
            sortable: true,
            renderCell: (row) => {
                const items = row?.preview || [];
                return (<Box display="flex" flexDirection="column">
                    <Box display={'flex'} alignItems={'center'}>
                        <span style={{ marginRight: '10px', whiteSpace: 'nowrap' }}>
                            <ReactSVG src={IconSvg.filesIcon} className='files_icon' />
                        </span>
                        <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            <Tooltip placement='right-start'
                                title={
                                    <div style={{ padding: "10px" }}>
                                        <p>{row?.document_name}</p>
                                    </div>
                                }
                                arrow PopperProps={{
                                    style: { width: '250px' }
                                }}>
                                {row?.document_name?.length > 30 ? `${row?.document_name?.substring(0, 70)}...` : row?.document_name}
                            </Tooltip>
                        </span>
                    </Box>
                    <Box display="flex" flexDirection="column" marginLeft={'30px'}>
                        {items.map((item, index) => {
                            const highlightedItem = item.replace(
                                new RegExp(`(${row?.current_query})`, "gi"),
                                `<Typography variant="body2" style="background-color:orange">$1</Typography>`
                            );

                            return (
                                <Box key={index} display="flex" alignItems="flex-start">
                                    <span style={{ marginRight: "5px" }}>•</span>
                                    <span
                                        style={{ whiteSpace: "nowrap" }}
                                        dangerouslySetInnerHTML={{ __html: highlightedItem }}
                                    />
                                </Box>
                            );
                        })}
                    </Box>
                </Box>)
            }
        },
        {
            id: 'relavence_score',
            label: 'Relevance Score',
            width: '40%',
            sortable: true,
            renderCell: (row) => `${row?.relavence_score}%`
        }
    ];

    return (
        <Box sx={Styles.mainContainer}>
            <Box sx={Styles.cardContainer}>
                {(docSearchLoading || initialLoading) && <LoadBar />}

                <Typography sx={fontStyles.largeText}>
                    Playground
                </Typography>
                <Typography sx={{ ...fontStyles.smallText, marginTop: '10px' }}>
                    Test Doc Search project
                </Typography>

                <Box marginTop={'20px'} display={'flex'} alignItems={'center'}>
                    <TextField
                        placeholder='Search'
                        variant="outlined"
                        size='small'
                        sx={{ ...componentsStyles.textField, width: '35%' }}
                        value={docSearchItem}
                        onChange={handleDocSearchChange}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' && docSearchItem.trim()) {
                                handleDocumentSearch(docSearchItem);
                            }
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Button
                        sx={{ ...buttonStyles.primaryBtn, width: '100px', marginLeft: '10px' }}
                        disabled={docSearchLoading || initialLoading}
                        onClick={() => handleDocumentSearch(docSearchItem)}
                    >
                        <Typography sx={fontStyles.btnText}>Search</Typography>
                    </Button>
                </Box>

                <Box marginTop={'20px'}>
                    <Box>
                        <Typography sx={{ ...fontStyles.smallText, marginTop: '10px' }}>Realm Configurations</Typography>

                        <Box marginTop={'5px'}>
                            <Box display="flex" alignItems="center">
                                <Box>
                                    <Select
                                        size="small"
                                        sx={{ ...componentsStyles.selectField, width: 350, backgroundColor: '#ffffff' }}
                                        value={selectedKey || ""}
                                        onChange={handleSelectChange}
                                        displayEmpty
                                    >
                                        <MenuItem value="" disabled>
                                            All Configurations
                                        </MenuItem>
                                        {realmSettings.map((key, index) => (
                                            <MenuItem key={index} value={key}>
                                                {key}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Box>
                                <Box marginLeft={'10px'}>
                                    {selectedKey && (
                                        <Box>
                                            <TextField
                                                value={values[selectedKey] || ''}
                                                onChange={handleValueChange}
                                                placeholder={`Enter value for ${selectedKey}`}
                                                size="small"
                                                sx={{ ...componentsStyles.textField, width: '200px' }}
                                            />
                                        </Box>
                                    )}
                                </Box>

                                <Box marginLeft={'10px'}>
                                    <Button
                                        variant="contained"
                                        onClick={handleSaveValue}
                                        sx={{ ...buttonStyles.primaryBtn, width: '80px', marginLeft: '10px' }}
                                    // disabled={!selectedKey || !values[selectedKey]}
                                    >
                                        Add
                                    </Button>
                                </Box>
                            </Box>


                            <Box sx={{ marginTop: '20px', width: '100%' }} display={'flex'} alignItems={'center'} flexWrap="wrap">
                                {Object.entries(savedValues).map(([key, value], index) => (
                                    < Box key={index} marginTop="20px" display={'flex'} alignItems={'center'} textAlign={'center'} padding={'3px 15px 3px 15px'} margin={'5px'} backgroundColor={'lightgray'} color={'white'} borderRadius={'20px'}>
                                        <Typography variant='body1' color='#000000'>{key}:</Typography>
                                        <Typography variant='body1' sx={{ marginLeft: "5px", color: "#000000" }}>{value}</Typography>
                                        <Box marginLeft={'10px'}
                                            onClick={() => handleRemoveValue(key)}>
                                            <ReactSVG src={IconSvg.deleteIcon} className='delete_icon' />
                                        </Box>
                                    </Box>))}
                            </Box>

                        </Box>
                    </Box>
                </Box>

                <Box marginTop={'10px'} paddingBottom={'20px'}>
                    <GeneralTable
                        data={docSearchData}
                        columns={columns}
                        loading={docSearchLoading}
                    />
                </Box>
            </Box>
        </Box >

    )
}

export default DocSearchPlayground;