import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    workspace_added: false,
    appflyte_engine_added: false,
    appflyte_functions_added: false,
    appflyte_event_types_added: false,
    project_added: false,
    agent_api_token_added: false,
    projects_source_folder_added: false,
    coa_files_added: false,
    specification_files_added: false,
    audit_test_history_added: false,
    doc_search_files_added: false,
    llm_doc_search_files_added: false,
    relam_settings_added: false,
    agent_logs_added: false
};

const newDataSlice = createSlice({
    name: 'new_data',
    initialState,
    reducers: {

        // Spaces
        setWorkspaceAddedAdded(state, action) {
            state.workspace_added = action.payload;
        },

        // Engines
        setAppflyteEngineAdded(state, action) {
            state.appflyte_engine_added = action.payload;
        },

        // Functions
        setAppflyteFunctionAdded(state, action) {
            state.appflyte_functions_added = action.payload;
        },

        // Event Types
        setAppflyteEventTypesAdded(state, action) {
            state.appflyte_event_types_added = action.payload;
        },

        // Projects
        setProjectAdded(state, action) {
            state.project_added = action.payload;
        },

        // AgentAPiToken
        setAgentApiTokenAdded(state, action) {
            state.agent_api_token_added = action.payload;
        },

        // SourceFiles
        setProjectSourceFolderAdded(state, action) {
            state.projects_source_folder_added = action.payload;
        },
        setCOAFilesAdded(state, action) {
            state.coa_files_added = action.payload;
        },
        setSpecificationFilesAdded(state, action) {
            state.specification_files_added = action.payload;
        },
        setAuditTestHistoryAdded(state, action) {
            state.audit_test_history_added = action.payload;
        },

        // Doc Search Files
        setDocSearchFilesAdded(state, action) {
            state.doc_search_files_added = action.payload;
        },
        setLLMDocSearchFilesAdded(state, action) {
            state.llm_doc_search_files_added = action.payload;
        },
        setRelamSettingsAdded(state, action) {
            state.relam_settings_added = action.payload;
        },

        // Agent Logs
        setAgentLogsAdded(state, action) {
            state.agent_logs_added = action.payload;
        },
    }
});

export const {
    setWorkspaceAddedAdded,
    setAppflyteEngineAdded,
    setAppflyteFunctionAdded,
    setAppflyteEventTypesAdded,
    setProjectAdded,
    setAgentApiTokenAdded,
    setProjectSourceFolderAdded,
    setCOAFilesAdded,
    setSpecificationFilesAdded,
    setAuditTestHistoryAdded,
    setDocSearchFilesAdded,
    setLLMDocSearchFilesAdded,
    setRelamSettingsAdded,
    setAgentLogsAdded
} = newDataSlice.actions;

export default newDataSlice.reducer;