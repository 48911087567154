export const useStyles = (theme) => ({
    mainContainer: {
        width: "100%",
        height: '100%',
        padding: "0px 15px 10px 15px",
        marginBottom: "10px"
    },
    cardContainer: {
        backgroundColor: "#FFFFFF",
        borderRadius: "20px",
        height: "100%",
        overflowY: "auto",
    },
    paraText: {
        fontSize: theme.typography.h6,
        fontWeight: 400,
    }
})