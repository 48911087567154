import React, { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { useStyles } from './styles.js';
import { Box } from '@mui/material';
import LogsSidebar from './Sidebar.js';
import AgentLogs from './AgentLogs/index.js';
import ApiLogs from './ApiLogs/index.js';
import { getSessionData, InvalidAccess } from '../../utils/sessionDataHandle.js';

function LogsHome() {

    const theme = useTheme();
    const styles = useStyles(theme);
    const navigate = useNavigate();
    const location = useLocation();

    const handleMenuChange = (selectedPage) => {
        navigate(`/logs/${selectedPage?.toLowerCase()}`);
    };

    useEffect(() => {

        const selected_space = getSessionData("selected_space")
        const selected_project = getSessionData("selected_project")

        if (!selected_space || !selected_project) {
            InvalidAccess(navigate);
            return
        }

        if (location.pathname === '/logs') {
            navigate('/logs/agents-logs');
        }
    }, [location.pathname, navigate]);

    return (
        <Box sx={styles.mainContainer}>
            <Box sx={styles.sidebar}>
                <LogsSidebar handleMenuChange={handleMenuChange} />
            </Box>
            <main style={styles.componentContainer}>
                <Box sx={styles.cardContainer}>
                    <Routes>
                        <Route path="agents-logs" element={<AgentLogs />} />
                        <Route path="api-logs" element={<ApiLogs />} />
                    </Routes>
                </Box >
            </main>
        </Box>

    )
}

export default LogsHome;