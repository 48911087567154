import { Box, Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { useStyles } from "./styles";
import { fontStyles } from "../../../styles/fontStyles";

function Alerts() {
    const theme = useTheme();
    const style = useStyles(theme);

    return (
        <Box sx={style.mainContainer}>
            <Box sx={style.cardContainer}>
                <Typography sx={fontStyles.largeText}>Alert Settings</Typography>

                <Typography sx={{ ...fontStyles.smallText, marginTop: '10px' }}>
                    Configure webhooks to receive alerts when agent fails
                </Typography>

            </Box>
        </Box>
    )

} export default Alerts;