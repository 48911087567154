export const getStyles = (theme, databaseType) => ({
    mainContainer: {
        width: "100%",
        height: '100vh',
    },
    cardContainer: {
        padding: '20px 20px 0px 20px'
    },
    sourceListMainContainer: {
        marginTop: "20px",
        display: 'flex',
        alignItems: "center",
        flexWrap: "wrap",
        gap: "20px"
    },
    sourceListContainer: (dbType) => ({
        display: 'flex',
        width: '120px',
        height: '100px',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        padding: '20px',
        border: '1.5px solid #D9D9D9',
        borderRadius: '5px',
        cursor: 'pointer',
        backgroundColor: databaseType === dbType ? "#D9D9D9" : "#FFFFFF",
    }),
    connectionIcon: {
        color: '#000000',
        marginRight: '10px',
        fontWeight: 700,
        height: '18px',
        width: '18px',
        fontSize: theme.typography.h6
    },
    mainHeadingText: {
        fontSize: theme.typography.h4,
        fontWeight: 600
    },
    btnText: {
        fontSize: theme.typography.h6,
        fontWeight: 600,
        textTransform: 'none'
    },
    paraText: {
        fontSize: theme.typography.h6,
        fontWeight: 400,
    },
    addConnectionBtn: {
        borderRadius: '20px',
        border: '1px solid #0B51C5',
        backgroundColor: ' #FFFFFF',
        color: '#0B51C5',
        height: "35px",
        width: '215px',
        display: 'flex',
        alignItems: 'center',
    },
    addConnectionBtnText: {
        fontSize: theme.typography.h6,
        fontWeight: 600,
        textTransform: 'none'
    }
})