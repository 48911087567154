import React, { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { Styles } from './Styles';
import { getSessionData, InvalidAccess } from '../../utils/sessionDataHandle';

import SubSidebar from './Sidebar';
import APiKeys from './APIKeys/APiKeys';
import LLMSettings from './LLMSettings/LLMSettings';
import CreateKey from './APIKeys/CreateKey';
import RealmSettings from './RealmSettings/RealmSettings';
import Alerts from './Alerts';
import GeneralSettings from './GeneralSettings/GeneralSettings';
import Styling from './Styling';
import ChatAuthentication from './ChatAuthentication';
import Defaults from './Defaults';


function SettingsHome() {

    const navigate = useNavigate();
    const location = useLocation();

    const handleMenuChange = (selectedPage) => {
        navigate(`/settings/${selectedPage.toLowerCase()}`);
    };

    useEffect(() => {

        const selected_space = getSessionData("selected_space")
        const selected_project = getSessionData("selected_project")

        if (!selected_space || !selected_project) {
            InvalidAccess(navigate);
            return
        }

        if (location.pathname === '/settings') {
            navigate('/settings/general');
        }
    }, [location.pathname, navigate]);

    return (
        <Box sx={Styles.mainContainer}>
            <Box sx={Styles.sidebar}>
                <SubSidebar handleMenuChange={handleMenuChange} />
            </Box>
            <main style={Styles.componentContainer}>
                <Box sx={Styles.cardContainer}>
                    <Routes>
                        <Route path="general" element={<GeneralSettings />} />
                        <Route path="llm" element={<LLMSettings />} />
                        <Route path="api_keys" element={<APiKeys />} />
                        <Route path="api_keys/create-api-keys" element={<CreateKey />} />
                        <Route path="realm" element={<RealmSettings />} />
                        <Route path="defaults" element={<Defaults />} />
                        <Route path="authentication" element={<ChatAuthentication />} />
                        <Route path="alerts" element={<Alerts />} />
                        <Route path="styling" element={<Styling />} />
                    </Routes>
                </Box >
            </main>
        </Box>

    )
}

export default SettingsHome;