import LLMDocumentSearchApi from "../../Api/Services/AmeyaBackend/LLMDocumentSearchApi";

const LLMDocSearchFilesData = async (reqObj) => {

    const getAllLLMDocSearchFiles = async (reqObj) => {
        const tempArr = []
        try {
            const response = await LLMDocumentSearchApi.fetchUploadedFiles(reqObj);
            if (response.data) {
                const response_data = response?.data ?? []
                const result = response_data?.result ?? []
                tempArr.push(...result)
            }
            return tempArr;
        }
        catch (error) {
            console.error(error)
        }
    }

    return getAllLLMDocSearchFiles(reqObj);
};

export default LLMDocSearchFilesData;
