import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, Button, FormControl, FormControlLabel, MenuItem, Radio, RadioGroup, Select, Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { useStyles } from "./styles";
import { buttonStyles } from "../../../styles/buttonStyles";
import { getComponentsStyles } from "../../../styles/componentsStyles";

import { setAgentApiTokenState } from "../../../Redux/slice/dataSlice";
import { setAgentApiTokenAdded } from "../../../Redux/slice/newDataSlice";
import FilesApi from "../../../Api/Services/AppflyteBackend/FileServiceApi";
import getAgentAPiTokenData from "../../../utils/ApiFunctions/AgentAPiTokenData";
import { fetchAccountId, fetchSubscriberId, fetchSubscriptionId } from "../../../utils/GetAccountDetails";
import { getSessionData } from "../../../utils/sessionDataHandle";
import LoadBar from "../../../utils/LoadBar";
import { tostAlert } from "../../../utils/AlertToast";
import { apiErrorHandler } from "../../../utils/ApiErrorHandler";
import handleDocumentUpload from "../../../components/FileUpload";

let s3_foldername = window.env.REACT_APP_ANALYTICS_S3_BUCKET_FOLDER || process.env.REACT_APP_ANALYTICS_S3_BUCKET_FOLDER
let s3_file_name = window.env.REACT_APP_ANALYTICS_S3_BUCKET_SCRIPT_FILE || process.env.REACT_APP_ANALYTICS_S3_BUCKET_SCRIPT_FILE

const ChatAuthentication = () => {

    const theme = useTheme();
    const dispatch = useDispatch();
    const styles = useStyles(theme);
    const componentsStyles = getComponentsStyles(theme);
    const selected_project = getSessionData("selected_project");

    const all_agent_api_tokens = useSelector(state => state.all_data.agent_api_tokens)
    const agent_api_token_added = useSelector(state => state.data_added.agent_api_token_added)

    const [loading, setLoading] = useState(false)
    const [allTokens, setAllTokens] = useState([]);

    const AuthConfigDetails = {
        auth_type: "auth",
        service_agent_token: ""
    }
    const [authConfigData, setAuthConfigData] = useState(AuthConfigDetails);

    // All Chatbot Configs:
    const chatbot_configs = {
        welcome_message: "",
        appflyte_account_configs: {},
        api_url_config: {},
        authentication_configs: {},
        source_details: {},
        ui_configs: {}
    }
    const [chatBotData, setChatBotData] = useState(chatbot_configs);

    useEffect(() => {
        fetchAllData();
    }, [])

    const fetchAllData = async () => {
        setLoading(true)
        try {

            const account_id = await fetchAccountId();
            const subscriber_id = await fetchSubscriberId();
            const subscription_id = await fetchSubscriptionId();
            const file_name = `${s3_foldername}/${s3_file_name}_${subscriber_id}_${subscription_id}.js`;

            const [configResponse, tokenResponse] = await Promise.all([
                fetchConfigData(file_name),
                getAllTokensDetails()
            ])

            if (configResponse && tokenResponse) {

                setChatBotData((prev) => ({
                    ...prev,
                    welcome_message: configResponse?.WELCOME_MESSAGE,
                    appflyte_account_configs: {
                        account_id: account_id,
                        subscriber_id: subscriber_id,
                        subscription_id: subscription_id
                    },
                    api_url_config: {
                        appflyte_backend_base_url: window.env.REACT_APP_APPFLYTE_BACKEND_URL || process.env.REACT_APP_APPFLYTE_BACKEND_URL,
                        analytics_base_url: window.env.REACT_APP_ANALYTICS_BASE_URL || process.env.REACT_APP_ANALYTICS_BASE_URL
                    },
                    authentication_configs: configResponse?.AUTHENTICATION_CONFIGS,
                    source_details: configResponse?.SOURCE_DETAILS,
                    ui_configs: configResponse?.UI_CONFIGS
                }));

                const filtered_token = tokenResponse?.find(
                    item => item?.payload?.__auto_id__ === configResponse?.AUTHENTICATION_CONFIGS?.service_agent_user_token_id
                )?.payload;

                const selectedToken = filtered_token ?
                    {
                        token: filtered_token.token,
                        __auto_id__: filtered_token.__auto_id__,
                        name: filtered_token.name
                    }
                    : null;

                setAuthConfigData((prev) => ({
                    ...prev,
                    auth_type: configResponse?.AUTHENTICATION_CONFIGS?.auth_type ?? 'no_auth',
                    service_agent_token: selectedToken
                }))
                setAllTokens(tokenResponse)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const fetchConfigData = async (file_name) => {
        try {
            const response = await FilesApi.getUploadedFileUrls(file_name)
            if (response.status === 200) {
                const responseData = response?.data ?? [];
                const download_url = responseData[responseData?.length - 1]?.download_url ?? null;
                const configData = await fetch(download_url);
                const scriptText = await configData.text();
                const configMatch = scriptText.match(/const chatbotConfig = ({[\s\S]*?});/);

                if (configMatch && configMatch[1]) {
                    const chatbotConfig = JSON.parse(configMatch[1]) ?? {};
                    return chatbotConfig;
                } else {
                    console.log("chatbotConfig not found!");
                    return {};
                }
            }
        } catch (error) {
            console.log(error)
            return {}
        }
    }

    const getAllTokensDetails = async () => {
        let all_tokens = []
        try {
            if (all_agent_api_tokens?.length > 0 && agent_api_token_added === false) {
                all_tokens = all_agent_api_tokens
            } else {
                const response = await getAgentAPiTokenData();
                all_tokens = response
                dispatch(setAgentApiTokenState(response))
                dispatch(setAgentApiTokenAdded(false))
            }
            const filtered_tokens = all_tokens?.filter(token => token?.payload?.project_id === selected_project?.payload?.__auto_id__) ?? [];
            return filtered_tokens;
        } catch (error) {
            console.log(error)
            return []
        }
    }

    const handleSave = async () => {
        setLoading(true)
        try {

            if (!authConfigData?.service_agent_token) {
                tostAlert("Select Api key and try again..", 'warning')
                return
            }

            const chatEmbedd_file = `(function () {
                function initChatbot() {

                    console.log("Initializing chatbot...");

                    function cleanupExistingChatbot() {
                        const existingIframe = document.getElementById("ameya-chatbot-iframe");
                        const existingCloseBtn = document.getElementById("chatbot-close-btn");
                        if (existingIframe) existingIframe.remove();
                        if (existingCloseBtn) existingCloseBtn.remove();
                    }
                    cleanupExistingChatbot();

                    function handleCloseButton() {
                        const iframe = document.getElementById("ameya-chatbot-iframe");
                        const closeBtn = document.getElementById("chatbot-close-btn");
                        const chatBotButton = document.getElementById("chatBotButton");

                        if (iframe) iframe.style.display = "none";
                        if (closeBtn) closeBtn.style.display = "none";

                        if (chatBotButton) {
                            chatBotButton.style.display = "block";
                            console.log("Chatbot closed, button made visible");
                        }
                    }

                    window.toggleChatbot = (params) => {
                        
                        // START chatbotConfig
                        const chatbotConfig = {
                            "WELCOME_MESSAGE": ${JSON.stringify(chatBotData?.welcome_message || "")},
                            "APPFLYTE_ACCOUNT_CONFIGS": ${JSON.stringify(chatBotData?.appflyte_account_configs || {})},
                            "API_URL_CONFIGS": ${JSON.stringify(chatBotData?.api_url_config || {})},
                            "SOURCE_DETAILS": ${JSON.stringify(chatBotData?.source_details || {})},
                            "UI_CONFIGS": ${JSON.stringify(chatBotData?.ui_configs || {})},
                            "AUTHENTICATION_CONFIGS": {
                                "auth_type": ${JSON.stringify(authConfigData?.auth_type || "")},
                                "service_agent_user_token_id": ${JSON.stringify(authConfigData?.service_agent_token?.__auto_id__ || "")},
                                "service_agent_user_token": ${JSON.stringify(authConfigData?.service_agent_token?.token || "")}
                            }
                        };
                        // END chatbotConfig

                        const realm_attributes = chatbotConfig?.SOURCE_DETAILS?.realm_attributes ?? [];
                        const realm_details = params?.realm_details ?? {};
                        if (realm_attributes?.length !== Object.keys(realm_details)?.length) {
                            alert("Invalid Realm Details");
                            return
                        } else {
                            const allKeysMatch = realm_attributes.every(attr => realm_details.hasOwnProperty(attr));
                            if (!allKeysMatch) {
                                alert("Invalid Realm Details");
                                return
                            }
                        }

                        let iframe = document.getElementById("ameya-chatbot-iframe");
                        let closeBtn = document.getElementById("chatbot-close-btn");
                        const chatBotButton = document.getElementById("chatBotButton");

                        if (iframe && closeBtn) {
                            const isHidden = iframe.style.display === "none";
                            iframe.style.display = isHidden ? "block" : "none";
                            closeBtn.style.display = isHidden ? "block" : "none";

                            if (chatBotButton) {
                                chatBotButton.style.display = isHidden ? "none" : "block";
                            }
                            return;
                        }

                        if (chatBotButton) chatBotButton.style.display = "none";

                        iframe = document.createElement("iframe");
                        iframe.id = "ameya-chatbot-iframe";
                        iframe.style.position = "fixed";
                        iframe.style.top = "0";
                        iframe.style.left = "0";
                        iframe.style.width = "100vw";
                        iframe.style.height = "100vh";
                        iframe.style.border = "none";
                        iframe.style.zIndex = "9999";
                        iframe.style.display = "block";
                        document.body.appendChild(iframe);

                        closeBtn = document.createElement("button");
                        closeBtn.id = "chatbot-close-btn";
                        closeBtn.innerText = "✖";
                        closeBtn.style.position = "fixed";
                        closeBtn.style.top = "18px";
                        closeBtn.style.right = "20px";
                        closeBtn.style.color = "black";
                        closeBtn.style.border = "none";
                        closeBtn.style.borderRadius = "5px";
                        closeBtn.style.fontSize = "16px";
                        closeBtn.style.cursor = "pointer";
                        closeBtn.style.zIndex = "10000";
                        closeBtn.style.display = "block";
                        document.body.appendChild(closeBtn);

                        const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
                        const script = document.createElement("script");
                        script.src = "/dist/ameya-chatbot-v0.1.0.js";
                        // script.src = "https://ameyachatbotsettingsconfig.s3.us-east-1.amazonaws.com/newtestingfolder/ameya-chatbot-v0.1.0.js"
                        script.async = true;

                        function evalPlaceholder(expression) {
                            return new Function("params", \`return \${expression}\`)(params);
                        }

                        const userConfigs={
                            "realm_details": params?.realm_details ?? {},
                            "third_party_token_provider": evalPlaceholder('params?.token_provider ?? null'),
                            "third_party_token": evalPlaceholder('params?.third_party_token ?? null')
                        }

                        const finalConfig = { ...chatbotConfig, ...userConfigs }; 
                        script.onload = () => {
                            iframe.contentWindow.AmeayaChatBot?.("init", finalConfig);
                        };

                        iframeDoc.body.appendChild(script);
                    };

                    document.addEventListener("click", (event) => {
                        if (event.target.id === "chatbot-close-btn") {
                            handleCloseButton();
                        }
                    });

                }
                initChatbot();
            })()`

            const subscriber_id = await fetchSubscriberId();
            const subscription_id = await fetchSubscriptionId();
            const blob = new Blob([chatEmbedd_file], { type: "application/javascript" });
            const filename = `${s3_file_name}_${subscriber_id}_${subscription_id}.js`;
            const file = new File([blob], filename, { type: "application/javascript" });
            const response = await handleDocumentUpload(filename, s3_foldername, file);
            if (response.status === 200) {
                tostAlert('Configuration saved successfully', 'success');
                fetchAllData();
            }
        } catch (error) {
            console.log(error)
            apiErrorHandler(error)
        } finally {
            setLoading(false)
        }
    }

    return (<Box sx={styles.mainContainer}>
        <Box sx={styles.cardContainer}>

            {loading && <LoadBar />}

            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <Typography sx={styles.mainHeadingText}>Authentication</Typography>
                <Button
                    onClick={handleSave}
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    sx={{ ...buttonStyles.primaryBtn, width: '100px' }}>
                    SAVE
                </Button>
            </Box>

            <Typography sx={{ ...styles.paraText, marginTop: '10px' }}>Configure authentication, API keys</Typography>

            <Box marginTop={'20px'} display={'flex'} alignItems={'center'}>
                <Typography sx={styles.paraText}>Authentication Required?</Typography>
                <Box marginLeft={'20px'}>
                    <FormControl>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            value={authConfigData?.auth_type}
                            onChange={(e) => setAuthConfigData({ ...authConfigData, auth_type: e.target.value })}
                            style={{ display: 'flex', flexDirection: 'row' }}
                        >
                            <FormControlLabel value="auth" control={<Radio color='#59A292' />} label="Yes" />
                            <FormControlLabel value="no_auth" control={<Radio color='#59A292' />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </Box>
            </Box>

            <Box marginTop={'20px'}>
                <Box sx={{ marginTop: '20px' }}>
                    <FormControl >
                        <Typography sx={{ ...styles.paraText, marginBottom: '6px' }}>Select API Key</Typography>
                        <Select
                            labelId="expiration-label"
                            id="expiration"
                            value={authConfigData.service_agent_token ? JSON.stringify(authConfigData.service_agent_token) : ""}
                            onChange={(e) => {
                                if (!e.target.value) {
                                    setAuthConfigData(prev => ({ ...prev, service_agent_token: null }));
                                    return;
                                }
                                const selectedToken = JSON.parse(e.target.value);
                                setAuthConfigData(prev => ({ ...prev, service_agent_token: selectedToken }));
                            }}
                            size='small'
                            displayEmpty
                            sx={{ ...componentsStyles.selectField, width: '300px' }}
                        >
                            <MenuItem value="">
                                <Typography variant="body1">Select API Key</Typography>
                            </MenuItem>
                            {Array.isArray(allTokens) && allTokens.length > 0 ? (
                                allTokens.map((item, index) => {
                                    if (!item?.payload?.name || !item?.payload?.token || !item?.payload?.__auto_id__) return null;

                                    const tokenData = {
                                        token: item.payload.token,
                                        __auto_id__: item.payload.__auto_id__,
                                        name: item.payload.name
                                    };

                                    return (
                                        <MenuItem key={index} value={JSON.stringify(tokenData)}>
                                            <Typography variant="body1">{item.payload.name}</Typography>
                                        </MenuItem>
                                    );
                                })
                            ) : (
                                <MenuItem value="">
                                    <Typography variant="body1">No Token Available</Typography>
                                </MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </Box>

            </Box>

        </Box>
    </Box>)
}
export default ChatAuthentication;

// const chatEmbedd_file = `(function () {
//     function initChatbot() {
//         window.toggleChatbot = (params) => {
//             if (!params) return
//             let iframe = document.getElementById("ameya-chatbot-iframe");
//             let closeBtn = document.getElementById("chatbot-close-btn");
//             const chatBotButton = document.getElementById("chatBotButton");

//             if (iframe && closeBtn) {
//                 const isHidden = iframe.style.display === "none";
//                 iframe.style.display = isHidden ? "block" : "none";
//                 closeBtn.style.display = isHidden ? "block" : "none";

//                 if (chatBotButton) {
//                     chatBotButton.style.display = isHidden ? "none" : "block";
//                 }
//                 return;
//             }

//             if (chatBotButton) {
//                 chatBotButton.style.display = "none";
//             }

//             iframe = document.createElement("iframe");
//             iframe.id = "ameya-chatbot-iframe";
//             iframe.style.position = "fixed";
//             iframe.style.top = "0";
//             iframe.style.left = "0";
//             iframe.style.width = "100vw";
//             iframe.style.height = "100vh";
//             iframe.style.border = "none";
//             iframe.style.zIndex = "9999";
//             iframe.style.display = "block";
//             document.body.appendChild(iframe);

//             closeBtn = document.createElement("button");
//             closeBtn.id = "chatbot-close-btn";
//             closeBtn.innerText = "✖";
//             closeBtn.style.position = "fixed";
//             closeBtn.style.top = "18px";
//             closeBtn.style.right = "20px";
//             closeBtn.style.color = "black";
//             closeBtn.style.border = "none";
//             closeBtn.style.borderRadius = "5px";
//             closeBtn.style.fontSize = "16px";
//             closeBtn.style.cursor = "pointer";
//             closeBtn.style.zIndex = "10000";
//             closeBtn.style.display = "block";
//             document.body.appendChild(closeBtn);

//             closeBtn.onclick = () => {
//                 iframe.style.display = "none";
//                 closeBtn.style.display = "none";
//                 if (chatBotButton) {
//                     chatBotButton.style.display = "block";
//                 }
//             };

//             const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
//             const script = document.createElement("script");
//             script.src = "/dist/ameya-chatbot-v0.1.0.js";
//             // script.src = "https://ameyachatbotsettingsconfig.s3.us-east-1.amazonaws.com/newtestingfolder/ameya-chatbot-v0.1.0.js"
//             script.async = true;


//             function evalPlaceholder(expression) {
//                 return new Function("params", \`return \${expression}\`)(params);
//             }

//             const config = {
//                 "WELCOME_MESSAGE":${JSON.stringify(chatBotData?.welcome_message)},
//                 "APPFLYTE_ACCOUNT_CONFIGS":${JSON.stringify(chatBotData?.appflyte_account_configs)},
//                 "API_URL_CONFIGS":${JSON.stringify(chatBotData?.api_url_config)},
//                 "SOURCE_DETAILS":${JSON.stringify(chatBotData?.source_details)},
//                 "UI_CONFIGS":${JSON.stringify(chatBotData?.ui_configs)},
//                 "AUTHENTICATION_CONFIGS": {
//                     "auth_type":${JSON.stringify(authConfigData?.auth_type)},
//                     "service_agent_user_token_id": ${JSON.stringify(authConfigData?.service_agent_token?.__auto_id__)},
//                     "service_agent_user_token": ${JSON.stringify(authConfigData?.service_agent_token?.token)},
//                     "third_party_token_provider": evalPlaceholder('params?.token_provider ?? null'),
//                     "third_party_token": evalPlaceholder('params?.third_party_token ?? null')
//                 }
//             }
//         }
//     };
//     script.onload = () => {
//         iframe.contentWindow.AmeayaChatBot?.("init", config);
//     };
//     iframeDoc.body.appendChild(script);
//     initChatbot();
// })()`