export const getStyles = (theme) => ({
    mainContainer: {
        width: "100%",
        height: '100%',
        paddingLeft: "15px",
        // marginBottom: "10px"
    },
    cardContainer: {
        backgroundColor: "#FFFFFF",
        borderRadius: "20px",
        padding: '20px',
        height: "100%",
        overflowY: "auto",
    },
    paraText: {
        fontSize: theme.typography.h6,
        fontWeight: 400,
    },
    mainHeadingText: {
        fontSize: theme.typography.h4,
        fontWeight: 600
    },
    sourceListMainContainer: {
        marginTop: "20px",
        display: 'flex',
        alignItems: "center",
        flexWrap: "wrap",
        gap: "20px"
    },
    sourceListContainer: (dataSourceType, dsType) => ({
        display: 'flex',
        width: '120px',
        height: '100px',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        padding: '20px',
        border: '1.5px solid #D9D9D9',
        borderRadius: '5px',
        cursor: 'pointer',
        backgroundColor: dataSourceType === dsType ? "#D9D9D9" : "#FFFFFF",
        '&:hover': {
            backgroundColor: theme.palette.secondary.main,
        }
    }),
})