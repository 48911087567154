import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ReactSVG } from "react-svg";
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import './Styles.css';
import { getStyles } from "./Styles";
import { IconSvg } from '../../utils/globalIcons';
import { routesConfig } from '../../Routes/Routes';
import { getSessionData } from '../../utils/sessionDataHandle';

function Sidebar() {

  const theme = useTheme();
  const styles = getStyles(theme);
  const location = useLocation();
  const currentPath = location.pathname;
  const navigate = useNavigate()

  const selected_space = getSessionData("selected_space")
  const selected_project = getSessionData("selected_project")
  const space_changed = useSelector(state => state.current_selected_data.space_changed)
  const project_changed = useSelector(state => state.current_selected_data.project_changed)
  const [currentspace, setCurrentSpace] = useState(null)
  const [selectedMenuItem, setSelectedMenuItem] = useState(1);

  useEffect(() => {
    const selected_space_id = selected_space?.payload?.__auto_id__ ?? null
    setCurrentSpace(selected_space_id)
    if (!selected_space || !selected_space_id) {
      sessionStorage.removeItem("selected_space")
      sessionStorage.removeItem("selected_project")
      navigate('/')
    }
    //eslint-disable-next-line
  }, [space_changed])

  useEffect(() => {
    const selected_space_id = selected_space?.payload?.__auto_id__ ?? null
    const selected_project_id = selected_project?.payload?.__auto_id__ ?? null

    if (!selected_space || !selected_space_id || !selected_project || !selected_project_id) {
      sessionStorage.removeItem("selected_space")
      sessionStorage.removeItem("selected_project")
      navigate('/')
    }
    //eslint-disable-next-line
  }, [project_changed])


  const matchRoute = (route) => {
    let regexPath = route.path
      .replace(/:\w+/g, '[^/]+')
      .replace(/\/\*$/, '(?:/.*)?');

    const regex = new RegExp(`^${regexPath}$`);
    return regex.test(currentPath);
  };

  const menuItems = [
    { icon: IconSvg.spaceIcon, label: 'Spaces' },
    { icon: IconSvg.projectIcon, label: 'Projects' },
    { icon: IconSvg.insightIcon, label: 'Insights' },
    { icon: IconSvg.sourcesIcon, label: 'Sources' },
    { icon: IconSvg.settingsIcon, label: 'Settings' },
    { icon: IconSvg.launchIcon, label: 'Launch' },
    { icon: IconSvg.logsIcon, label: 'Logs' }
  ];

  const filteredMenuItems = () => {

    let items = menuItems.filter(item => item.label === '' || item.label === 'Spaces');

    if (selected_space && !selected_project) {
      items.push(menuItems.find(item => item.label === 'Projects'));
    }

    if (selected_space && selected_project) {
      const selected_sidebar_items = selected_project?.payload?.configuration?.engine_config?.sidebar_items ?? []

      const allowedLabels = new Set(
        Object.keys(selected_sidebar_items).map(label => label.charAt(0).toUpperCase() + label.slice(1))
      );

      const filteredMenuItems = menuItems.filter(item => allowedLabels.has(item.label));

      const main_sidebar_order = ['Insights', 'Sources', 'Settings', 'Logs', 'Launch'];
      filteredMenuItems.sort((a, b) => main_sidebar_order.indexOf(a.label) - main_sidebar_order.indexOf(b.label));
      items = filteredMenuItems;
    }
    return items;
  };

  useEffect(() => {
    const currentRoute = routesConfig.find(matchRoute)
    const label = currentRoute ? currentRoute?.component : '';
    const componentIndex = filteredMenuItems()?.findIndex(item => item?.label === label);
    setSelectedMenuItem(componentIndex);
    //eslint-disable-next-line
  }, [location])

  const handlePageRouting = (label) => {
    switch (label) {
      case 'Spaces':
        navigate('/')
        break;

      case 'Projects':
        currentspace ? navigate(`/spaces/${currentspace}/projects`) : navigate('/projects')
        break;

      case 'Sources':
        navigate('/datasource')
        break;

      case 'Insights':
        const selected_project_id = selected_project?.payload?.__auto_id__ ?? null
        navigate(`/spaces/${currentspace}/projects/${selected_project_id}/insights`)
        break;

      case 'Settings':
        navigate('/settings')
        break;

      case 'Launch':
        navigate('/launch')
        break;

      case 'Logs':
        navigate('/logs')
        break;

      default:
        navigate('/')
    }
  };

  const handleMenuItemClick = (index, label) => {
    if (!label) {
      setSelectedMenuItem(index);
      navigate('/')
      return
    }
    if (label === 'Spaces') {
      setSelectedMenuItem(index);
      sessionStorage.removeItem("selected_space")
      setCurrentSpace(null)
      navigate('/')
      return
    }
    setSelectedMenuItem(index);
    handlePageRouting(label)
  };

  return (

    <nav style={styles.sidebar}>
      <ul className="menu">
        {filteredMenuItems()?.map((item, index) => (
          <li key={index}
            className={`menu-item ${selectedMenuItem === index ? 'active' : ''}`}
            onClick={() => handleMenuItemClick(index, item.label)}
          >
            <span className="icon">
              <ReactSVG
                src={item.icon}
                className={`subsidebar_icon ${selectedMenuItem === index ? 'active' : ''}`}
              />
            </span>
            <Typography className="label" variant="body1">{item.label}</Typography>
          </li>
        ))}

      </ul>
    </nav>
  );
}

export default Sidebar;