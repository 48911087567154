import { useEffect } from "react";

const ChatPreview = () => {

    useEffect(() => {

        function initChatbot() {

            function cleanupExistingChatbot() {
                const existingIframe = document.getElementById("ameya-chatbot-iframe");
                const existingCloseBtn = document.getElementById("chatbot-preview-close-btn");
                if (existingIframe) existingIframe.remove();
                if (existingCloseBtn) existingCloseBtn.remove();
            }
            cleanupExistingChatbot();

            function handleCloseButton() {
                const iframe = document.getElementById("ameya-chatbot-iframe");
                const closeBtn = document.getElementById("chatbot-preview-close-btn");
                const chatBotButton = document.getElementById("chatBotPreviewButton");

                if (iframe) iframe.remove();
                if (closeBtn) closeBtn.remove();

                if (chatBotButton) {
                    chatBotButton.style.display = "block";
                    console.log("Chatbot closed, button made visible");
                }
            }

            window.toggleChatbotPreview = (chatBotData, chatStylesData, token_provider, third_party_token, brand_image) => {

                let iframe = document.getElementById("ameya-chatbot-iframe");
                let closeBtn = document.getElementById("chatbot-preview-close-btn");
                const chatBotButton = document.getElementById("chatBotPreviewButton");

                if (iframe && closeBtn) {
                    const isHidden = iframe.style.display === "none";
                    iframe.style.display = isHidden ? "block" : "none";
                    closeBtn.style.display = isHidden ? "block" : "none";

                    if (chatBotButton) {
                        chatBotButton.style.display = isHidden ? "none" : "block";
                    }
                    return;
                }

                if (chatBotButton) chatBotButton.style.display = "none";

                iframe = document.createElement("iframe");
                iframe.id = "ameya-chatbot-iframe";
                iframe.style.position = "fixed";
                iframe.style.top = "0";
                iframe.style.left = "0";
                iframe.style.width = "100vw";
                iframe.style.height = "100vh";
                iframe.style.border = "none";
                iframe.style.zIndex = "9999";
                iframe.style.display = "block";
                document.body.appendChild(iframe);

                closeBtn = document.createElement("button");
                closeBtn.id = "chatbot-preview-close-btn";
                closeBtn.innerText = "✖";
                closeBtn.style.position = "fixed";
                closeBtn.style.top = "18px";
                closeBtn.style.right = "20px";
                closeBtn.style.color = "black";
                closeBtn.style.border = "none";
                closeBtn.style.borderRadius = "5px";
                closeBtn.style.fontSize = "16px";
                closeBtn.style.cursor = "pointer";
                closeBtn.style.zIndex = "10000";
                closeBtn.style.display = "block";
                document.body.appendChild(closeBtn);

                const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
                const script = document.createElement("script");
                script.src = "/dist/ameya-chatbot-v0.1.0.js";
                script.async = true;

                var chatbotConfig = {
                    WELCOME_MESSAGE: chatBotData?.welcome_message || "",
                    APPFLYTE_ACCOUNT_CONFIGS: chatBotData?.appflyte_account_configs || {},
                    API_URL_CONFIGS: chatBotData?.api_url_config || {},
                    SOURCE_DETAILS: chatBotData?.source_details || {},
                    AUTHENTICATION_CONFIGS: chatBotData?.authentication_configs,
                    UI_CONFIGS: {
                        brand_logo: brand_image,
                        color: {
                            background_color: chatStylesData?.background_color || "",
                            surface_color: chatStylesData?.surface_color || "",
                            surface_border: chatStylesData?.surface_border || "",
                            button_fill: chatStylesData?.button_fill || "",
                            button_text: chatStylesData?.button_text || "",
                            question_bubble_color: chatStylesData?.question_bubble_color || "",
                            question_bubble_font_color: chatStylesData?.question_bubble_font_color || ""
                        },
                        font: {
                            font_family: chatStylesData?.font_family || "",
                            fallback_font_family: chatStylesData?.font_family || "",
                            font_color: chatStylesData?.font_color || "",
                            font_size: chatStylesData?.font_size || ""
                        },
                        typography: {
                            "h1": {
                                "fontSize": "20px",
                                "fontWeight": 600
                            },
                            "h2": {
                                "fontSize": "14px",
                                "fontWeight": 600
                            },
                            "h3": {
                                "fontSize": "16px",
                                "fontWeight": 600
                            },
                            "h4": {
                                "fontSize": "16px",
                                "fontWeight": 400
                            },
                            "h5": {
                                "fontSize": "14px",
                                "fontWeight": 600
                            },
                            "h6": {
                                "fontSize": "12px",
                                "fontWeight": 400
                            },
                            "body1": {
                                "fontSize": "15px",
                                "fontWeight": 600
                            },
                            "body2": {
                                "fontSize": "12px",
                                "fontWeight": 400
                            }
                        },
                        button: {
                            "primaryButton": {
                                "fontSize": "16px",
                                "fontWeight": 600
                            },
                            "secondaryButton": {
                                "fontSize": "15px",
                                "fontWeight": 600
                            }
                        }
                    }
                }

                const userConfigs = {
                    "realm_details": {},
                    "third_party_token_provider": token_provider,
                    "third_party_token": third_party_token
                }

                const finalConfig = { ...chatbotConfig, ...userConfigs };
                script.onload = () => {
                    iframe.contentWindow.AmeayaChatBot?.("init", finalConfig);
                };

                iframeDoc.body.appendChild(script);
            };

            document.addEventListener("click", (event) => {
                if (event.target.id === "chatbot-preview-close-btn") {
                    handleCloseButton();
                }
            });

        }
        initChatbot();
    }, []);

    return null;
};

export default ChatPreview;
