import React, { useEffect, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { QuestionAnswer } from '@mui/icons-material';
import { useStyles } from './styles';

import { fetchThirdPartyToken } from '../../../../utils/GetAccountDetails';
import LoadBar from '../../../../utils/LoadBar';

function AnalyticsPlayground() {
    const theme = useTheme();
    const styles = useStyles(theme);
    const [loading, setLoading] = useState(true);
    const [usertoken, setUserToken] = useState(null);

    useEffect(() => {
        const fetchToken = async () => {
            setLoading(true)
            try {
                const token = await fetchThirdPartyToken();
                setUserToken(token)
            } catch (error) {
                console.log(error)
            } finally {
                setLoading(false)
            }
        }
        fetchToken();
    }, [])

    const params = {
        realm_details: {},
        token_provider: "google",
        third_party_token: usertoken
    }

    return (
        <div style={styles.mainContainer}>
            <Box sx={styles.cardContainer}>

                {loading && <LoadBar />}
                <Typography sx={styles.mainHeadingText}>
                    Playground
                </Typography>
                <Typography sx={{ ...styles.paraText, marginTop: '10px' }}>
                    Run Analytics Chatbot
                </Typography>

                {!loading && usertoken &&
                    (<Box id="chatBotButton">
                        <Box sx={styles.chatBotButtonContainer}>
                            <Box
                                sx={{
                                    ...styles.chatBotButton, backgroundColor: '#DEDEDE',
                                    '&:hover': {
                                        backgroundColor: '#B0B0B0'
                                    }
                                }}
                                onClick={() => {
                                    if (window.toggleChatbot) {
                                        window.toggleChatbot(params);
                                    } else {
                                        console.log("Not Loaded at")
                                    }
                                }}>
                                <QuestionAnswer />
                            </Box>
                        </Box>
                    </Box>)
                }
            </Box>
        </div >

    )
}

export default AnalyticsPlayground