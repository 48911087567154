import React, { useState } from 'react';
import { Box, Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { getStyles } from './styles.js';
import { ReactSVG } from 'react-svg';
import MongoDb from './MongoDb.js';
import { IconSvg } from '../../../../utils/globalIcons.js';

function DataBaseSource() {
    const theme = useTheme();
    const [databaseType, setDatabaseType] = useState('mongo');
    const styles = getStyles(theme, databaseType);

    return (
        <Box sx={styles.mainContainer}>
            <Box sx={styles.cardContainer}>

                <Typography sx={styles.mainHeadingText}>Sources - Database</Typography>
                <Typography sx={{ ...styles.paraText, marginTop: '5px' }}>
                    Connect to databases, query and visualize your data with natural language
                </Typography>

                <Box sx={styles.sourceListMainContainer}>
                    {[
                        { type: 'mongo', icon: IconSvg.mongoIcon, label: 'Mongo DB' },
                        // { type: 'mysql', icon: IconSvg.mysqlIcon, label: 'My SQL' },
                        // { type: 'snowflake', icon: IconSvg.snowflakeIcon, label: 'Snowflake' }
                    ].map((db) => (
                        <Box
                            key={db.type}
                            sx={styles.sourceListContainer(db.type)}
                            onClick={() => setDatabaseType(db.type)}
                        >
                            <ReactSVG src={db.icon} />
                            <Typography sx={styles.paraText}>{db.label}</Typography>
                        </Box>
                    ))}
                </Box>

                {databaseType === 'mongo' &&
                    <MongoDb />
                }

                {(databaseType === 'mysql' || databaseType === 'snowflake') &&
                    (<Box marginTop={'150px'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                        <Typography sx={styles.paraText}>Coming Soon</Typography>
                    </Box>)
                }

            </Box>
        </Box >
    )
}

export default DataBaseSource;