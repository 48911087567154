import React, { useEffect, useState } from "react";

import { Autocomplete, Box, Button, FormControl, InputAdornment, Popover, TextField, Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { useStyles } from "./styles";
import { buttonStyles } from "../../../styles/buttonStyles";
import { getComponentsStyles } from "../../../styles/componentsStyles";
import { ReactSVG } from "react-svg";
import { IconSvg } from "../../../utils/globalIcons";
import { fetchSubscriberId, fetchSubscriptionId, fetchThirdPartyToken } from "../../../utils/GetAccountDetails";
import FilesApi from "../../../Api/Services/AppflyteBackend/FileServiceApi";

import LoadBar from "../../../utils/LoadBar";
import { apiErrorHandler } from "../../../utils/ApiErrorHandler";
import { tostAlert } from "../../../utils/AlertToast";
import handleDocumentUpload from "../../../components/FileUpload";
import ChatPreview from "../../../components/ChatPreview";
import { SketchPicker } from "react-color";

let s3_foldername = window.env.REACT_APP_ANALYTICS_S3_BUCKET_FOLDER || process.env.REACT_APP_ANALYTICS_S3_BUCKET_FOLDER
let s3_file_name = window.env.REACT_APP_ANALYTICS_S3_BUCKET_SCRIPT_FILE || process.env.REACT_APP_ANALYTICS_S3_BUCKET_SCRIPT_FILE
let s3_logo_name = window.env.REACT_APP_ANALYTICS_S3_BUCKET_BRAND_LOGO || process.env.REACT_APP_ANALYTICS_S3_BUCKET_BRAND_LOGO

function Styling() {
    const theme = useTheme();
    const styles = useStyles(theme);
    const componentsStyles = getComponentsStyles(theme);
    const [loading, setLoading] = useState(false);
    const [formErrors, setFormErrors] = useState({});

    // All Chatbot Configs:
    const chatbot_configs = {
        welcome_message: "",
        appflyte_account_configs: {},
        api_url_config: {},
        authentication_configs: {},
        source_details: {},
        ui_configs: {}
    }
    const [chatBotData, setChatBotData] = useState(chatbot_configs);

    const Font_families = ['Inter', 'Roboto', 'Helvetica', 'Arial', 'Lora', 'Times New Roman', 'Georgia', 'Lato']
    const Font_Sizes = ['12', '13', '14', '15', '16', '17', '18', '19']

    const chatStyleDetails = {
        font_family: null,
        font_size: null,
        font_color: "",
        background_color: "",
        surface_color: "",
        surface_border: "",
        button_fill: "",
        button_text: "",
        question_bubble_color: '',
        question_bubble_font_color: '',
    }
    const [chatStylesData, setChatStylesData] = useState(chatStyleDetails);

    const [previewUrl, setPreviewUrl] = useState(null);
    const [selectedLogo, setSelectedLogo] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedKey, setSelectedKey] = useState(null);

    useEffect(() => {
        fetchAllData();
    }, [])

    const fetchAllData = async () => {
        setLoading(true)
        try {
            const subscriber_id = await fetchSubscriberId();
            const subscription_id = await fetchSubscriptionId();
            const file_name = `${s3_foldername}/${s3_file_name}_${subscriber_id}_${subscription_id}.js`;
            const logo_name = `${s3_foldername}/${s3_logo_name}_${subscriber_id}_${subscription_id}.svg`;

            const [configResponse, logoResponse] = await Promise.all([
                getAnalyticsConfig(file_name),
                getAnalyticsLogo(logo_name)
            ])
            if (configResponse && logoResponse) {
                return
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const getAnalyticsConfig = async (file_name) => {
        try {
            const response = await FilesApi.getUploadedFileUrls(file_name)
            if (response.status === 200) {
                const responseData = response?.data ?? [];
                const download_url = responseData[responseData?.length - 1]?.download_url ?? null;
                const configData = await fetch(download_url);
                const scriptText = await configData.text();
                const configMatch = scriptText.match(/const chatbotConfig = ({[\s\S]*?});/);

                if (configMatch && configMatch[1]) {
                    const chatbotConfig = JSON.parse(configMatch[1]);
                    const ui_config_details = chatbotConfig?.UI_CONFIGS ?? {};
                    const color_config = ui_config_details?.color ?? {};
                    const font_config = ui_config_details?.font ?? {};

                    setChatBotData((prev) => ({
                        ...prev,
                        welcome_message: chatbotConfig?.WELCOME_MESSAGE,
                        appflyte_account_configs: chatbotConfig?.APPFLYTE_ACCOUNT_CONFIGS,
                        api_url_config: chatbotConfig?.API_URL_CONFIGS,
                        authentication_configs: chatbotConfig?.AUTHENTICATION_CONFIGS,
                        source_details: chatbotConfig?.SOURCE_DETAILS,
                        ui_configs: chatbotConfig?.UI_CONFIGS
                    }));

                    setChatStylesData({
                        ...chatStylesData,
                        background_color: color_config?.background_color,
                        surface_color: color_config?.surface_color,
                        surface_border: color_config?.surface_border,
                        button_fill: color_config?.button_fill,
                        button_text: color_config?.button_text,
                        question_bubble_color: color_config?.question_bubble_color,
                        question_bubble_font_color: color_config?.question_bubble_font_color,
                        font_family: font_config?.font_family,
                        font_color: font_config?.font_color,
                        font_size: font_config?.font_size
                    })
                } else {
                    console.log("chatbotConfig not found!");
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getAnalyticsLogo = async (file_name) => {
        try {
            const response = await FilesApi.getUploadedFileUrls(file_name)
            if (response.status === 200) {
                const responseData = response?.data ?? [];
                const logo_url = responseData[responseData?.length - 1]?.download_url ?? null;
                setSelectedLogo('seleted')
                setPreviewUrl(logo_url)
                return
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleClick = (event, key) => {
        setAnchorEl(event.currentTarget);
        setSelectedKey(key);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setSelectedKey(null);
    };

    const handleChange = (newColor) => {
        if (selectedKey) {
            setChatStylesData((prevColors) => ({
                ...prevColors,
                [selectedKey]: newColor.hex,
            }));
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedLogo(file);
            setPreviewUrl(URL.createObjectURL(file));
        }
    };

    const handleFileRemove = () => {
        setSelectedLogo(null);
        setPreviewUrl(null);
    }

    useEffect(() => {
        const errors = { ...formErrors };
        if (formErrors?.font_family && chatStylesData?.font_family?.trim()) { delete errors?.font_family }
        if (formErrors?.font_size && chatStylesData?.font_size?.trim()) { delete errors?.font_size }
        if (formErrors?.font_color && chatStylesData?.font_color?.trim()) { delete errors?.font_color }
        if (formErrors?.background_color && chatStylesData?.background_color?.trim()) { delete errors?.background_color }
        if (formErrors?.surface_color && chatStylesData?.surface_color?.trim()) { delete errors?.surface_color }
        if (formErrors?.surface_border && chatStylesData?.surface_border?.trim()) { delete errors?.surface_border }
        if (formErrors?.button_fill && chatStylesData?.button_fill?.trim()) { delete errors?.button_fill }
        if (formErrors?.button_text && chatStylesData?.button_text?.trim()) { delete errors?.button_text }
        if (formErrors?.question_bubble_color && chatStylesData?.question_bubble_color?.trim()) { delete errors?.question_bubble_color }
        if (formErrors?.question_bubble_font_color && chatStylesData?.question_bubble_font_color?.trim()) { delete errors?.question_bubble_font_color }
        if (JSON.stringify(errors) !== JSON.stringify(formErrors)) { setFormErrors(errors) }
    }, [chatStylesData, formErrors]);

    const toggleChatbot = async () => {
        const errors = {};
        if (!chatStylesData?.font_family?.trim()) { errors.font_family = 'This field is required' }
        if (!chatStylesData?.font_size?.trim()) { errors.font_size = 'This field is required' }
        if (!chatStylesData?.font_color?.trim()) { errors.font_color = 'This field is required' }
        if (!chatStylesData?.background_color?.trim()) { errors.background_color = 'This field is required' }
        if (!chatStylesData?.surface_color?.trim()) { errors.surface_color = 'This field is required' }
        if (!chatStylesData?.surface_border?.trim()) { errors.surface_border = 'This field is required' }
        if (!chatStylesData?.button_fill?.trim()) { errors.button_fill = 'This field is required' }
        if (!chatStylesData?.button_text?.trim()) { errors.button_text = 'This field is required' }
        if (!chatStylesData?.question_bubble_color?.trim()) { errors.question_bubble_color = 'This field is required' }
        if (!chatStylesData?.question_bubble_font_color?.trim()) { errors.question_bubble_font_color = 'This field is required' }

        if (!selectedLogo) {
            tostAlert("Please select logo and try again", 'warning')
            return
        }

        if (Object.keys(errors).length === 0) {
            const user_third_party_token = await fetchThirdPartyToken();
            if (window.toggleChatbotPreview) {
                window.toggleChatbotPreview(
                    chatBotData,
                    chatStylesData, 'google',
                    user_third_party_token, previewUrl
                );
            }
        }
        else {
            setFormErrors(errors);
        }
    };

    const saveSettings = async () => {
        setLoading(true);
        try {

            const errors = {};
            if (!chatStylesData?.font_family?.trim()) { errors.font_family = 'This field is required' }
            if (!chatStylesData?.font_size?.trim()) { errors.font_size = 'This field is required' }
            if (!chatStylesData?.font_color?.trim()) { errors.font_color = 'This field is required' }
            if (!chatStylesData?.background_color?.trim()) { errors.background_color = 'This field is required' }
            if (!chatStylesData?.surface_color?.trim()) { errors.surface_color = 'This field is required' }
            if (!chatStylesData?.surface_border?.trim()) { errors.surface_border = 'This field is required' }
            if (!chatStylesData?.button_fill?.trim()) { errors.button_fill = 'This field is required' }
            if (!chatStylesData?.button_text?.trim()) { errors.button_text = 'This field is required' }
            if (!chatStylesData?.question_bubble_color?.trim()) { errors.question_bubble_color = 'This field is required' }
            if (!chatStylesData?.question_bubble_font_color?.trim()) { errors.question_bubble_font_color = 'This field is required' }

            if (!selectedLogo) {
                tostAlert("Please select logo and try again", 'warning')
                return
            }

            if (Object.keys(errors).length === 0) {

                const chatEmbedd_file = `(function () {
                    function initChatbot() {
                
                        console.log("Initializing chatbot...");
                
                        function cleanupExistingChatbot() {
                            const existingIframe = document.getElementById("ameya-chatbot-iframe");
                            const existingCloseBtn = document.getElementById("chatbot-close-btn");
                            if (existingIframe) existingIframe.remove();
                            if (existingCloseBtn) existingCloseBtn.remove();
                        }
                        cleanupExistingChatbot();
                
                        function handleCloseButton() {
                            const iframe = document.getElementById("ameya-chatbot-iframe");
                            const closeBtn = document.getElementById("chatbot-close-btn");
                            const chatBotButton = document.getElementById("chatBotButton");
                
                            if (iframe) iframe.style.display = "none";
                            if (closeBtn) closeBtn.style.display = "none";
                
                            if (chatBotButton) {
                                chatBotButton.style.display = "block";
                                console.log("Chatbot closed, button made visible");
                            }
                        }
                
                        window.toggleChatbot = (params) => {
                            
                            // START chatbotConfig
                            const chatbotConfig = {
                                "WELCOME_MESSAGE": ${JSON.stringify(chatBotData?.welcome_message || "")},
                                "APPFLYTE_ACCOUNT_CONFIGS": ${JSON.stringify(chatBotData?.appflyte_account_configs || {})},
                                "API_URL_CONFIGS": ${JSON.stringify(chatBotData?.api_url_config || {})},
                                "SOURCE_DETAILS": ${JSON.stringify(chatBotData?.source_details || {})},
                                "UI_CONFIGS":{
                                    "color": {
                                        "background_color": ${JSON.stringify(chatStylesData?.background_color || "")},
                                        "surface_color": ${JSON.stringify(chatStylesData?.surface_color || "")},
                                        "surface_border": ${JSON.stringify(chatStylesData?.surface_border || "")},
                                        "button_fill": ${JSON.stringify(chatStylesData?.button_fill || "")},
                                        "button_text": ${JSON.stringify(chatStylesData?.button_text || "")},
                                        "question_bubble_color": ${JSON.stringify(chatStylesData?.question_bubble_color || "")},
                                        "question_bubble_font_color": ${JSON.stringify(chatStylesData?.question_bubble_font_color || "")}
                                    },
                                    "font": {
                                        "font_family": ${JSON.stringify(chatStylesData?.font_family || "")},
                                        "fallback_font_family": ${JSON.stringify(chatStylesData?.font_family || "")},
                                        "font_color": ${JSON.stringify(chatStylesData?.font_color || "")},
                                        "font_size": ${JSON.stringify(chatStylesData?.font_size || "")}
                                    },
                                    "typography": {
                                        "h1": {
                                            "fontSize": "20px",
                                            "fontWeight": 600
                                        },
                                        "h2": {
                                            "fontSize": "14px",
                                            "fontWeight": 600
                                        },
                                        "h3": {
                                            "fontSize": "16px",
                                            "fontWeight": 600
                                        },
                                        "h4": {
                                            "fontSize": "16px",
                                            "fontWeight": 400
                                        },
                                        "h5": {
                                            "fontSize": "14px",
                                            "fontWeight": 600
                                        },
                                        "h6": {
                                            "fontSize": "12px",
                                            "fontWeight": 400
                                        },
                                        "body1": {
                                            "fontSize": "15px",
                                            "fontWeight": 600
                                        },
                                        "body2": {
                                            "fontSize": "12px",
                                            "fontWeight": 400
                                        }
                                    },
                                    "button": {
                                        "primaryButton": {
                                            "fontSize": "16px",
                                            "fontWeight": 600
                                        },
                                        "secondaryButton": {
                                            "fontSize": "15px",
                                            "fontWeight": 600
                                        }
                                    }
                                },
                                "AUTHENTICATION_CONFIGS": ${JSON.stringify(chatBotData?.authentication_configs || {})}
                            };
                            // END chatbotConfig

                            const realm_attributes = chatbotConfig?.SOURCE_DETAILS?.realm_attributes ?? [];
                            const realm_details = params?.realm_details ?? {};
                            if (realm_attributes?.length !== Object.keys(realm_details)?.length) {
                                alert("Invalid Realm Details");
                                return
                            } else {
                                const allKeysMatch = realm_attributes.every(attr => realm_details.hasOwnProperty(attr));
                                if (!allKeysMatch) {
                                    alert("Invalid Realm Details");
                                    return
                                }
                            }
                
                            let iframe = document.getElementById("ameya-chatbot-iframe");
                            let closeBtn = document.getElementById("chatbot-close-btn");
                            const chatBotButton = document.getElementById("chatBotButton");
                
                            if (iframe && closeBtn) {
                                const isHidden = iframe.style.display === "none";
                                iframe.style.display = isHidden ? "block" : "none";
                                closeBtn.style.display = isHidden ? "block" : "none";
                
                                if (chatBotButton) {
                                    chatBotButton.style.display = isHidden ? "none" : "block";
                                }
                                return;
                            }
                
                            if (chatBotButton) chatBotButton.style.display = "none";
                
                            iframe = document.createElement("iframe");
                            iframe.id = "ameya-chatbot-iframe";
                            iframe.style.position = "fixed";
                            iframe.style.top = "0";
                            iframe.style.left = "0";
                            iframe.style.width = "100vw";
                            iframe.style.height = "100vh";
                            iframe.style.border = "none";
                            iframe.style.zIndex = "9999";
                            iframe.style.display = "block";
                            document.body.appendChild(iframe);
                
                            closeBtn = document.createElement("button");
                            closeBtn.id = "chatbot-close-btn";
                            closeBtn.innerText = "✖";
                            closeBtn.style.position = "fixed";
                            closeBtn.style.top = "18px";
                            closeBtn.style.right = "20px";
                            closeBtn.style.color = "black";
                            closeBtn.style.border = "none";
                            closeBtn.style.borderRadius = "5px";
                            closeBtn.style.fontSize = "16px";
                            closeBtn.style.cursor = "pointer";
                            closeBtn.style.zIndex = "10000";
                            closeBtn.style.display = "block";
                            document.body.appendChild(closeBtn);
                
                            const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
                            const script = document.createElement("script");
                            script.src = "/dist/ameya-chatbot-v0.1.0.js";
                            // script.src = "https://ameyachatbotsettingsconfig.s3.us-east-1.amazonaws.com/newtestingfolder/ameya-chatbot-v0.1.0.js"
                            script.async = true;
                
                            function evalPlaceholder(expression) {
                                return new Function("params", \`return \${expression}\`)(params);
                            }
                
                            const userConfigs={
                                "realm_details": params?.realm_details ?? {},
                                "third_party_token_provider": evalPlaceholder('params?.token_provider ?? null'),
                                "third_party_token": evalPlaceholder('params?.third_party_token ?? null')
                            }
                
                            const finalConfig = { ...chatbotConfig, ...userConfigs }; 
                            script.onload = () => {
                                iframe.contentWindow.AmeayaChatBot?.("init", finalConfig);
                            };
                
                            iframeDoc.body.appendChild(script);
                        };
                
                        document.addEventListener("click", (event) => {
                            if (event.target.id === "chatbot-close-btn") {
                                handleCloseButton();
                            }
                        });
                
                    }
                    initChatbot();
                })()`

                const subscriber_id = await fetchSubscriberId();
                const subscription_id = await fetchSubscriptionId();

                if (selectedLogo) {
                    const logo_name = `${s3_logo_name}_${subscriber_id}_${subscription_id}.svg`;
                    await handleDocumentUpload(logo_name, s3_foldername, selectedLogo);
                }

                const blob = new Blob([chatEmbedd_file], { type: "application/javascript" });
                const filename = `${s3_file_name}_${subscriber_id}_${subscription_id}.js`;
                const file = new File([blob], filename, { type: "application/javascript" });
                const response = await handleDocumentUpload(filename, s3_foldername, file);
                if (response.status === 200) {
                    tostAlert('Configuration saved successfully', 'success');
                    fetchAllData();
                }

            } else {
                setFormErrors(errors);
            }
        } catch (error) {
            console.log(error)
            apiErrorHandler(error)
        } finally {
            setLoading(false)
        }
    }

    return (
        <Box sx={styles.mainContainer}>
            <Box sx={styles.cardContainer}>

                <ChatPreview />

                {loading && <LoadBar />}
                <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                    <Typography sx={styles.mainHeadingText}>Styling</Typography>
                    <Box display={'flex'} justifyContent={'row'} alignItems={'center'}>
                        <Box id="chatBotPreviewButton">
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={loading}
                                onClick={toggleChatbot}
                                sx={styles.previewBtn}>
                                PREVIEW
                            </Button>
                        </Box>
                        <Button
                            onClick={saveSettings}
                            variant="contained"
                            color="primary"
                            disabled={loading}
                            sx={{ ...buttonStyles.primaryBtn, width: '100px', marginLeft: '20px' }}>
                            SAVE
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{ ...buttonStyles.secondaryBtn, width: '100px', marginLeft: '20px' }}>
                            CANCEL
                        </Button>
                    </Box>
                </Box>

                <Typography sx={{ ...styles.paraText, marginTop: '10px' }}>
                    Style your AI chat interface with your brand colours and logo
                </Typography>

                <Box marginTop={'20px'} paddingBottom={'20px'}>

                    <Box className="font_styling" sx={{ display: 'flex', justifyContent: 'row', alignItems: 'center' }}>

                        <Box>
                            <FormControl>
                                <Typography sx={{ ...styles.paraText, marginBottom: '6px' }}>Font Family</Typography>
                                <Autocomplete
                                    size="small"
                                    sx={{ ...componentsStyles.autocomplete, width: '200px' }}
                                    options={Font_families}
                                    getOptionLabel={(option) => option || ""}
                                    id="clear-on-escape"
                                    clearOnEscape
                                    value={Font_families.find((option) => option === chatStylesData.font_family) || null}
                                    renderInput={(params) => (
                                        <TextField {...params} variant="outlined" placeholder="Font Family" />
                                    )}
                                    onChange={(event, value) => setChatStylesData({ ...chatStylesData, font_family: value || null })}
                                />
                                {formErrors.font_family && <Typography sx={{ ...styles.paraText, color: 'red' }}>{formErrors.font_family}</Typography>}
                            </FormControl>
                        </Box>

                        <Box marginLeft={'30px'}>
                            <FormControl>
                                <Typography sx={{ ...styles.paraText, marginBottom: '6px' }}>Font Size</Typography>
                                <Autocomplete
                                    size="small"
                                    sx={{ ...componentsStyles.autocomplete, width: '200px' }}
                                    options={Font_Sizes}
                                    getOptionLabel={(option) => option || ""}
                                    id="clear-on-escape"
                                    clearOnEscape
                                    value={Font_Sizes.find((option) => option === chatStylesData.font_size) || null}
                                    renderInput={(params) => (
                                        <TextField {...params} variant="outlined" placeholder="Font Size" />
                                    )}
                                    onChange={(event, value) => setChatStylesData({ ...chatStylesData, font_size: value || null })}
                                />
                                {formErrors.font_size && <Typography sx={{ ...styles.paraText, color: 'red' }}>{formErrors.font_size}</Typography>}
                            </FormControl>
                        </Box>

                        <Box marginLeft={'30px'}>
                            <FormControl>
                                <Typography sx={{ ...styles.paraText, marginBottom: '6px' }}>Font Color</Typography>
                                <TextField
                                    size="small"
                                    value={chatStylesData.font_color}
                                    onClick={(e) => handleClick(e, 'font_color')}
                                    sx={{ ...componentsStyles.textField, width: '200px' }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <div
                                                    style={{
                                                        width: 20,
                                                        height: 20,
                                                        backgroundColor: chatStylesData.font_color,
                                                        borderRadius: '4px',
                                                        border: '0.5px solid grey'
                                                    }}
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                    readOnly
                                    fullWidth
                                />
                                {formErrors.font_color && <Typography sx={{ ...styles.paraText, color: 'red' }}>{formErrors.font_color}</Typography>}
                            </FormControl>
                        </Box>

                    </Box>

                    <Box className="background_styling" sx={{ display: 'flex', justifyContent: 'row', alignItems: 'center', marginTop: '25px' }}>

                        <Box>
                            <FormControl>
                                <Typography sx={{ ...styles.paraText, marginBottom: '6px' }}>Background Color</Typography>
                                <TextField
                                    size="small"
                                    value={chatStylesData.background_color}
                                    onClick={(e) => handleClick(e, 'background_color')}
                                    sx={{ ...componentsStyles.textField, width: '200px' }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <div
                                                    style={{
                                                        width: 20,
                                                        height: 20,
                                                        backgroundColor: chatStylesData.background_color,
                                                        borderRadius: '4px',
                                                        border: '0.5px solid grey'
                                                    }}
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                    readOnly
                                    fullWidth
                                />
                                {formErrors.background_color && <Typography sx={{ ...styles.paraText, color: 'red' }}>{formErrors.background_color}</Typography>}
                            </FormControl>
                        </Box>

                        <Box marginLeft={'30px'}>
                            <FormControl>
                                <Typography sx={{ ...styles.paraText, marginBottom: '6px' }}>Surface Color</Typography>
                                <TextField
                                    size="small"
                                    value={chatStylesData.surface_color}
                                    onClick={(e) => handleClick(e, 'surface_color')}
                                    sx={{ ...componentsStyles.textField, width: '200px' }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <div
                                                    style={{
                                                        width: 20,
                                                        height: 20,
                                                        backgroundColor: chatStylesData.surface_color,
                                                        borderRadius: '4px',
                                                        border: '0.5px solid grey'
                                                    }}
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                    readOnly
                                    fullWidth
                                />
                                {formErrors.surface_color && <Typography sx={{ ...styles.paraText, color: 'red' }}>{formErrors.surface_color}</Typography>}

                            </FormControl>
                        </Box>

                        <Box marginLeft={'30px'}>
                            <FormControl>
                                <Typography sx={{ ...styles.paraText, marginBottom: '6px' }}>Surface Border</Typography>
                                <TextField
                                    size="small"
                                    value={chatStylesData.surface_border}
                                    onClick={(e) => handleClick(e, 'surface_border')}
                                    sx={{ ...componentsStyles.textField, width: '200px' }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <div
                                                    style={{
                                                        width: 20,
                                                        height: 20,
                                                        backgroundColor: chatStylesData.surface_border,
                                                        borderRadius: '4px',
                                                        border: '0.5px solid grey'
                                                    }}
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                    readOnly
                                    fullWidth
                                />
                                {formErrors.surface_border && <Typography sx={{ ...styles.paraText, color: 'red' }}>{formErrors.surface_border}</Typography>}
                            </FormControl>
                        </Box>

                    </Box>

                    <Box className="button_styling" sx={{ display: 'flex', justifyContent: 'row', alignItems: 'center', marginTop: '25px' }}>

                        <Box>
                            <FormControl>
                                <Typography sx={{ ...styles.paraText, marginBottom: '6px' }}>Button Fill</Typography>
                                <TextField
                                    size="small"
                                    value={chatStylesData.button_fill}
                                    onClick={(e) => handleClick(e, 'button_fill')}
                                    sx={{ ...componentsStyles.textField, width: '200px' }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <div
                                                    style={{
                                                        width: 20,
                                                        height: 20,
                                                        backgroundColor: chatStylesData.button_fill,
                                                        borderRadius: '4px',
                                                        border: '0.5px solid grey'
                                                    }}
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                    readOnly
                                    fullWidth
                                />
                                {formErrors.button_fill && <Typography sx={{ ...styles.paraText, color: 'red' }}>{formErrors.button_fill}</Typography>}
                            </FormControl>
                        </Box>

                        <Box marginLeft={'30px'}>
                            <FormControl>
                                <Typography sx={{ ...styles.paraText, marginBottom: '6px' }}>Button Text</Typography>
                                <TextField
                                    size="small"
                                    value={chatStylesData.button_text}
                                    onClick={(e) => handleClick(e, 'button_text')}
                                    sx={{ ...componentsStyles.textField, width: '200px' }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <div
                                                    style={{
                                                        width: 20,
                                                        height: 20,
                                                        backgroundColor: chatStylesData.button_text,
                                                        borderRadius: '4px',
                                                        border: '0.5px solid grey'
                                                    }}
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                    readOnly
                                    fullWidth
                                />
                                {formErrors.button_text && <Typography sx={{ ...styles.paraText, color: 'red' }}>{formErrors.button_text}</Typography>}
                            </FormControl>
                        </Box>
                    </Box>

                    <Box className="question_bubble" sx={{ display: 'flex', justifyContent: 'row', alignItems: 'center', marginTop: '25px' }}>
                        <Box>
                            <FormControl>
                                <Typography sx={{ ...styles.paraText, marginBottom: '6px' }}>Question Bubble Color</Typography>
                                <TextField
                                    size="small"
                                    value={chatStylesData.question_bubble_color}
                                    onClick={(e) => handleClick(e, 'question_bubble_color')}
                                    sx={{ ...componentsStyles.textField, width: '200px' }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <div
                                                    style={{
                                                        width: 20,
                                                        height: 20,
                                                        backgroundColor: chatStylesData.question_bubble_color,
                                                        borderRadius: '4px',
                                                        border: '0.5px solid grey'
                                                    }}
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                    readOnly
                                    fullWidth
                                />
                                {formErrors.question_bubble_color && <Typography sx={{ ...styles.paraText, color: 'red' }}>{formErrors.question_bubble_color}</Typography>}
                            </FormControl>
                        </Box>

                        <Box marginLeft={'30px'}>
                            <FormControl>
                                <Typography sx={{ ...styles.paraText, marginBottom: '6px' }}>Question Bubble Font Color</Typography>
                                <TextField
                                    size="small"
                                    value={chatStylesData.question_bubble_font_color}
                                    onClick={(e) => handleClick(e, 'question_bubble_font_color')}
                                    sx={{ ...componentsStyles.textField, width: '200px' }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <div
                                                    style={{
                                                        width: 20,
                                                        height: 20,
                                                        backgroundColor: chatStylesData.question_bubble_font_color,
                                                        borderRadius: '4px',
                                                        border: '0.5px solid grey'
                                                    }}
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                    readOnly
                                    fullWidth
                                />
                                {formErrors.question_bubble_font_color && <Typography sx={{ ...styles.paraText, color: 'red' }}>{formErrors.question_bubble_font_color}</Typography>}
                            </FormControl>
                        </Box>

                    </Box>

                    <Box sx={{ marginTop: '20px' }}>
                        <Typography sx={{ ...styles.paraText, marginBottom: '6px' }}>Logo</Typography>
                        <Box
                            onDragOver={(e) => e.preventDefault()}
                            sx={styles.fileUploadContainer(previewUrl)}>

                            {!previewUrl && (
                                <label
                                    style={{
                                        cursor: "pointer",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        width: "100%",
                                        height: "100%",
                                        transition: "background 0.2s ease-in-out",
                                        position: "absolute",
                                        inset: 0,
                                    }}
                                >
                                    <ReactSVG src={IconSvg.fileUploadIcon} />
                                    <Typography>(.svg)</Typography>
                                    <input
                                        type="file"
                                        accept=".svg"
                                        onChange={handleFileChange}
                                        style={{
                                            position: "absolute",
                                            inset: 0,
                                            width: "100%",
                                            height: "100%",
                                            opacity: 0,
                                            cursor: "pointer",
                                        }}
                                    />
                                </label>
                            )}

                            {previewUrl && (
                                <Box sx={{ height: '85%', width: '100%', objectFit: 'contain' }}>
                                    <ReactSVG src={previewUrl} />
                                </Box>
                            )}

                            {(previewUrl && selectedLogo) && (
                                <Box height={'15%'} display={'flex'} justifyContent={'end'} alignItems={'center'}>
                                    <Box onClick={handleFileRemove}>
                                        <ReactSVG src={IconSvg.deleteIcon} className='delete_icon' />
                                    </Box>
                                </Box>)
                            }
                        </Box>

                    </Box>

                </Box>

                <Popover
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                >
                    {selectedKey && (
                        <SketchPicker color={chatStylesData[selectedKey]} onChange={handleChange} />
                    )}
                </Popover>

            </Box>
        </Box>
    )
}

export default Styling;