export const useStyles = (theme) => ({
    mainContainer: {
        width: "100%",
        height: '100vh',
    },
    cardContainer: {
        padding: '20px 20px 0px 20px'
    },

    fileUploadContainer: (previewUrl) => ({
        height: "100px",
        backgroundColor: '#FFFFFF',
        borderRadius: '5px',
        width: '430px',
        textAlign: 'center',
        cursor: 'pointer',
        border: "1px solid #D9D9D9",
        padding: '10px',
        position: "relative"
    }),
    previewBtn: {
        height: '35px',
        width: '100px',
        borderRadius: '20px',
        border: 'none',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#000000',
        color: "#FFFFFF"
    },
    mainHeadingText: {
        fontSize: theme.typography.h4,
        fontWeight: 600
    },
    btnText: {
        fontSize: theme.typography.h6,
        fontWeight: 600,
        textTransform: 'none'
    },
    paraText: {
        fontSize: theme.typography.h6,
        fontWeight: 400,
    }
})