import React, { useEffect, useState } from 'react';
import { ReactSVG } from "react-svg";

import { List, ListItem, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { getStyles } from "../../styles/subsidebar/styles";
import '../../styles/subsidebar/styles.css';

import { IconSvg } from '../../utils/globalIcons';
import { getSessionData } from '../../utils/sessionDataHandle';

function SubSidebar({ handleMenuChange }) {

  const theme = useTheme();
  const styles = getStyles(theme);

  const [selectedMenuItem, setSelectedMenuItem] = useState(0);

  useEffect(() => {
    const selected_project = getSessionData("selected_project")
    const selected_engine_name = selected_project?.payload?.configuration?.engine_name
    handleMenuChange(selected_engine_name)
    //eslint-disable-next-line
  }, [])

  const menuItems = [
    { icon: IconSvg.filesIcon, label: 'Files', engine_name: "", icon_type: "svg", path: "files" },
    { icon: IconSvg.databaseIcon, label: 'Database', engine_name: "", icon_type: "svg", path: "database" }
  ];

  const filteredMenuItems = () => {
    let items = []
    const selected_space = getSessionData("selected_space")
    const selected_project = getSessionData("selected_project")
    if (selected_space && selected_project) {
      const selected_engine_name = selected_project?.payload?.configuration?.engine_name
      const selected_sidebar_items = selected_project?.payload?.configuration?.engine_config?.sidebar_items ?? {}
      console.log("")
      const sub_sidebar_items = selected_sidebar_items?.['sources'] ?? [];
      const filteredMenuItems = menuItems?.filter(item => sub_sidebar_items?.map(item => item?.charAt(0)?.toUpperCase() + item?.slice(1))?.includes(item?.label));
      filteredMenuItems.forEach(item => { item.engine_name = selected_engine_name });
      items = filteredMenuItems;
    }
    return items;
  };

  const handleMenuItemClick = (index, engine, path) => {
    setSelectedMenuItem(index);
    handleMenuChange(engine, path)
  };

  return (
    <nav style={styles.sidebar}>
      <List>
        {filteredMenuItems()?.map((item, index) => (
          <ListItem
            key={index}
            style={styles.siebarItem(selectedMenuItem, index)}
            onClick={() => handleMenuItemClick(index, item.engine_name, item.path)}
          >
            <ReactSVG style={styles.siebarIcon} src={item.icon} className='sidebar_icon' />
            <Typography sx={styles.siebarLabelText}>{item.label}</Typography>
          </ListItem>
        ))}
      </List>
    </nav >
  );
}

export default SubSidebar;