import React, { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';

import { Styles } from './Styles';
import SubSidebar from './Sidebar';

import AuditToolPlaygroundHistory from './AuditTool/AuditToolPlaygroundHistory';
import AuditToolPlayground from './AuditTool/AuditToolPlayground';
import AuditToolApi from './AuditTool/AuditToolApi';

import DocSearchPlayground from './DocumentSearch/DocSearchPlayground';
import DocSearchApi from './DocumentSearch/DocSearchApi';

import LLMDocSearchPlayground from './LLMDocSearch/LLMDocSearchPlayground';
import LLMDocSearchApi from './LLMDocSearch/LLMDocSearchApi';
import { getSessionData, InvalidAccess } from '../../utils/sessionDataHandle';
import Embed from './Analytics/Embed/index.js';
import AnalyticsPlayground from './Analytics/Playground/index.js';

function LaunchHome() {

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {

        const selected_space = getSessionData("selected_space")
        const selected_project = getSessionData("selected_project")

        if (!selected_space || !selected_project) {
            InvalidAccess(navigate);
            return
        }

        const selected_engine_name = selected_project?.payload?.configuration?.engine_name
        if (location.pathname === '/launch') {

            if (selected_engine_name === "qms_audit_tool") {
                navigate('/launch/qms-audit-tool/playground');
            }

            if (selected_engine_name === "doc_search") {
                navigate('/launch/doc-search/playground');
            }

            if (selected_engine_name === "llm_doc_search") {
                navigate('/launch/llm-doc-search/playground');
            }

            if (selected_engine_name === "analytics_tool" || selected_engine_name === "mongodb_analytics_tool") {
                navigate('/launch/analytics-tool/playground');
            }

        }
    }, [location.pathname, navigate]);

    const handleMenuChange = (engine, path) => {
        if (engine && path) {
            navigate(`/launch/${engine?.toLowerCase()?.replace(/_/g, '-')}/${path?.toLowerCase()?.replace(/_/g, '-')}`);
        }
    };

    return (
        <Box sx={Styles.mainContainer}>
            <Box sx={Styles.sidebar}>
                <SubSidebar handleMenuChange={handleMenuChange} />
            </Box>
            <main style={Styles.componentContainer}>
                <Box sx={Styles.cardContainer}>
                    <Routes>
                        <Route path="qms-audit-tool/playground" element={<AuditToolPlaygroundHistory />} />
                        <Route path="qms-audit-tool/playground/test" element={<AuditToolPlayground />} />
                        <Route path="qms-audit-tool/api" element={<AuditToolApi />} />

                        <Route path="doc-search/playground" element={<DocSearchPlayground />} />
                        <Route path="doc-search/api" element={<DocSearchApi />} />

                        <Route path="llm-doc-search/playground" element={<LLMDocSearchPlayground />} />
                        <Route path="llm-doc-search/api" element={<LLMDocSearchApi />} />

                        <Route path="analytics-tool/playground" element={<AnalyticsPlayground />} />
                        <Route path="analytics-tool/embed" element={<Embed />} />

                    </Routes>
                </Box>
            </main>
        </Box>
    )
}

export default LaunchHome;