import React, { useState } from 'react'
import { Box, Tab, Tabs, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles';
import { getStyles } from './Styles';

import { fontStyles } from '../../../styles/fontStyles';
import { getMainStyles } from '../../../styles/styles';
import SpecificationFiles from './SpecificationFiles';
import CoaFiles from './CoaFiles';

function AuditToolFiles() {
    const theme = useTheme();
    const styles = getStyles(theme);
    const mainStyles = getMainStyles(theme);
    const [tabValue, setTabValue] = useState('coa_files');

    return (
        <Box sx={styles.mainContainer}>
            <Box sx={styles.cardContainer}>

                <Typography sx={styles.mainHeadingText}>Sources - Files</Typography>

                <Typography sx={{ ...styles.paraText, marginTop: '10px' }}>
                    Upload documents which can be used to test the AI agent
                </Typography>

                <Box width={'100%'}>
                    <Tabs
                        value={tabValue}
                        onChange={(event, tab) => setTabValue(tab)}
                        textColor="secondary"
                        aria-label="secondary tabs example"
                        sx={mainStyles.activateTab}
                    >
                        <Tab value="coa_files" label="Reports" sx={mainStyles.tabs} />
                        <Tab value="specification_files" label="Specifications" sx={mainStyles.tabs} />
                    </Tabs>
                </Box>

                <Box marginTop={'20px'} width={'100%'} height={'100%'}>
                    {tabValue === "coa_files" && (<CoaFiles />)}
                    {tabValue === "specification_files" && (<SpecificationFiles />)}
                </Box>
            </Box>
        </Box>
    )
}

export default AuditToolFiles