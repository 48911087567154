export const getStyles = (theme) => ({
    mainContainer: {
        width: "100%",
        height: '100vh',
    },
    cardContainer: {
        padding: '20px 20px 0px 20px'
    },
    paraText: {
        fontSize: theme.typography.h6,
        fontWeight: 400,
    },
    mainHeadingText: {
        fontSize: theme.typography.h4,
        fontWeight: 600
    },
    embeddCodeContainer: (copied) => ({
        width: '650px',
        bgcolor: copied ? '#D9D9D9' : '#EEEEEE',
        borderRadius: '10px',
        border: '1px solid #D9D9D9',
        padding: '15px',
        cursor: 'pointer'
    })
})