import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { ReactSVG } from "react-svg";

import { useTheme } from '@mui/material/styles';
import { Box, Button, CircularProgress, Divider, IconButton, LinearProgress, Tooltip, Typography } from '@mui/material'
import { CancelOutlined, CheckCircleOutlineOutlined, CloudUpload, ErrorOutlineOutlined, QueryBuilderOutlined } from '@mui/icons-material';

import { getStyles } from './Styles';
import { buttonStyles } from '../../../styles/buttonStyles';

import LoadBar from '../../../utils/LoadBar';
import { tostAlert } from '../../../utils/AlertToast';
import { AlertMessages } from '../../../utils/AlertMessages';
import { apiErrorHandler } from '../../../utils/ApiErrorHandler';

import { setCOAFilesState } from "../../../Redux/slice/dataSlice";
import { setCOAFilesAdded } from "../../../Redux/slice/newDataSlice";
import { fileExcistenceCheck } from '../../../utils/FileHandling';
import { IconSvg } from '../../../utils/globalIcons';
import PdfSourceFiles from '../../../utils/ApiFunctions/PdfSourceFiles';
import FilesApi from '../../../Api/Services/AppflyteBackend/FileServiceApi';
import AuditToolApi from '../../../Api/Services/AmeyaBackend/AuditToolApi';
import GeneralTable from '../../../components/GeneralTable';

let resJsonVar = []
let uploadProgress = []

function CoaFiles() {
    const theme = useTheme();
    const styles = getStyles(theme);
    const dispatch = useDispatch()

    // Files Upload
    const filePermission = "PUBLIC"
    const [uploadJson, setUploadJson] = useState([])
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [uploadProgressState, setUploadProgress] = useState([])
    const [loading, setLoading] = useState(false);
    const [loadingSave, SetLoadingSave] = useState(false)

    // COA Files
    const [coafiles, setCoaFiles] = useState([]);
    const [coafileloading, setCOAFileLoading] = useState([])

    // Redux States
    const coa_files_added = useSelector(state => state.data_added.coa_files_added)
    const coa_files_data = useSelector(state => state.all_data.coafiles)

    useEffect(() => {
        getCOAFilesDetails()
        //eslint-disable-next-line
    }, [coa_files_added])

    const getCOAFilesDetails = async () => {
        setCOAFileLoading(true)
        try {
            let coa_files = []
            const is_pending_files = coa_files_data?.length > 0 ? coa_files_data?.filter(item => item?.status === 'uploading') : []

            if (coa_files_data?.length > 0 && coa_files_added === false && is_pending_files.length === 0) {
                coa_files = coa_files_data
            } else {
                const response = await PdfSourceFiles();
                coa_files = response
                dispatch(setCOAFilesState(response))
                dispatch(setCOAFilesAdded(false))
            }

            const updatedData = coa_files?.length > 0 ? coa_files?.map(item => {
                const fixedDate = item?.created_on?.replace(/:(\d{2}):(\d{2})$/, (match, mins, secs) => {
                    return `:${mins}:${Math.min(Number(secs), 59)}`;
                });
                return { ...item, fixedDate };
            }) : []

            const sorted_coa_files = updatedData?.length > 0
                ? [...updatedData]?.sort((a, b) => {
                    const dateA = Date.parse(a?.fixedDate) ? new Date(a?.fixedDate) : null;
                    const dateB = Date.parse(b?.fixedDate) ? new Date(b?.fixedDate) : null;
                    if (!dateA || !dateB) return !dateA ? 1 : -1;
                    return dateB - dateA;
                })
                : [];

            setCoaFiles(sorted_coa_files)
        } catch (error) {
            console.log(error)
        } finally {
            setCOAFileLoading(false)
        }
    }

    const columns = [
        {
            id: 'file_name',
            label: 'Document',
            width: '40%',
            sortable: true,
            renderCell: (row) => (
                <Box display={'flex'} alignItems={'center'}>
                    <span style={{ marginRight: '10px', whiteSpace: 'nowrap' }}>
                        <ReactSVG src={IconSvg.pdfIcon} />
                    </span>
                    <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        <Tooltip placement='right-start'
                            title={
                                <div style={{ padding: "10px" }}>
                                    <p>{row?.file_name}</p>
                                </div>
                            }
                            arrow PopperProps={{
                                style: { width: '250px' }
                            }}>
                            {row?.file_name?.length > 30 ? `${row?.file_name?.substring(0, 50)}...` : row?.file_name}
                        </Tooltip>
                    </span>
                </Box>
            ),
        },
        {
            id: 'created_on',
            label: 'Created On',
            width: '25%',
            sortable: true,
            renderCell: (row) => row?.created_on?.substring(0, 10)
        },
        {
            id: 'status',
            label: 'Upload Status',
            width: '25%',
            sortable: true,
            renderCell: (row) => (<Box display={'flex'} alignItems={'center'}>
                {
                    row?.status === "uploading" && (
                        <span style={{ marginRight: '10px' }}>
                            <ReactSVG src={IconSvg.trainedIcon} className='upload_proccess_icon' />
                        </span>
                    )
                }
                {
                    row?.status === "success" && (
                        <span style={{ marginRight: '10px' }}>
                            <ReactSVG src={IconSvg.trainedIcon} className='upload_success_icon' />
                        </span>
                    )
                }
                {
                    row?.status === "fail" && (
                        <span style={{ marginRight: '10px' }}>
                            <ReactSVG src={IconSvg.trainedIcon} className='upload_failed_icon' />
                        </span>
                    )
                }
                {
                    row?.status === "uploading" ?
                        (<span>processing</span>) :
                        (<span>{row?.status}</span>)
                }

            </Box>)
        },
        {
            id: '',
            label: '',
            width: '10%',
            sortable: false,
            renderCell: (row) => (
                <ReactSVG
                    src={IconSvg.deleteIcon}
                    className="delete_icon"
                    onClick={() => handleCoaFileDelete(row?.file_id)}
                />
            ),
        },
    ];

    // Handling File Upload
    const handleFileDrop = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        const files = Array.from(event.dataTransfer.files);
        const maxFileSize = 50 * 1024 * 1024;
        const validFiles = files.filter(file => file.size <= maxFileSize) ?? []
        if (validFiles.length < files.length) {
            tostAlert('File Size Exceeds 50 MB', 'warning')
            return
        }
        event.target.value = null;
        setSelectedFiles(files);
    };

    const handleFileChange = async (event) => {
        event.preventDefault();
        const files = Array.from(event.target.files);
        const maxFileSize = 50 * 1024 * 1024;
        const validFiles = files.filter(file => file.size <= maxFileSize) ?? []
        if (validFiles.length < files.length) {
            tostAlert('File Size Exceeds 50 MB', 'warning')
            return
        }
        event.target.value = null;
        setSelectedFiles(files);
    };

    const handleFileRemove = (index) => {
        const updatedFiles = [...selectedFiles];
        updatedFiles.splice(index, 1);
        setSelectedFiles(updatedFiles);
    }

    const markIndexFailed = (index) => {
        const tempLoadArr = [...uploadProgress]
        tempLoadArr[index] = 'failed'
        uploadProgress = tempLoadArr
        setUploadProgress([...tempLoadArr])
    }

    const saveInAmeyaBackend = async (file, fileType, fileId, fileUrl, index) => {
        try {
            const reqObj = [
                {
                    "file_id": fileId,
                    "file_name": file?.name,
                    "file_extension": fileType,
                    "file_location": fileUrl,
                }
            ]

            const upload_reponse = await AuditToolApi.addPdfFileDetails(JSON.stringify(reqObj));
            if (upload_reponse.status === 200) {
                const tempLoadArr = [...uploadProgress]
                tempLoadArr[index] = 'processed'
                uploadProgress = tempLoadArr
                setUploadProgress([...tempLoadArr])
                return true
            }
            markIndexFailed(index)
            return null
        }
        catch (error) {
            markIndexFailed(index)
            return null
        }
    }

    const uploadFile = async (blob, reqBody) => {
        try {
            const resData = await FilesApi.getPresignedURL(JSON.stringify(reqBody))
            const urlFields = JSON.parse(resData.data.url_fields)
            let formData = new FormData();

            formData.append('key', urlFields.key);
            formData.append('AWSAccessKeyId', urlFields.AWSAccessKeyId);
            // formData.append('x-amz-security-token', urlFields['x-amz-security-token']);
            formData.append('policy', urlFields.policy);
            formData.append('signature', urlFields.signature);
            formData.append('file', blob);

            const resUpload = await FilesApi.uploadFile(resData.data.url, formData)
            if (resUpload.status === 200 || resUpload.status === 204) {
                return resData?.data?.file_id
            }
            return null
        } catch (e) {
            console.log('Err ' + e)
            return null
        }
    }

    const uploadAndAppendForPost = async (file, fileType, thumbnailFileId, index) => {
        try {

            const reqBodyFile = {
                file_context: 'bucket_dpod_user_file',
                content_type: file?.name?.split('.')[1],
                file_type: '',
                file_name: file?.name
            }
            const uploadedFileId = await uploadFile(file, reqBodyFile)
            const uploadedFileUrl = await FilesApi.getUploadedFileUrls(uploadedFileId)
            const tempJsonArr = [...resJsonVar]
            const uploadResObj = {
                file_id: uploadedFileId,
                folder_id: null,
                access_type: filePermission,
                file_type: fileType,
                file_attributes: {
                    file_name: file?.name,
                    file_url: uploadedFileUrl?.data?.[0]?.download_url,
                    created_on: moment().format("DD-MM-YYYY"),
                    file_extension: file?.name?.split('.')[1]
                },
                thumbnail_file_id: thumbnailFileId,
                is_hidden: true
            }
            tempJsonArr.push(uploadResObj)
            resJsonVar = tempJsonArr
            setUploadJson(tempJsonArr)
            return { "fileId": uploadedFileId, "fileUrl": uploadedFileUrl?.data?.[0]?.download_url }
        }
        catch (err) {
            console.log("ERROR: ", err);
            markIndexFailed(index)
        }
    }

    const handleDocumentUpload = async (file, index) => {
        try {
            const { fileId, fileUrl } = await uploadAndAppendForPost(file, 'Document', null, index)
            const save_reponse = await saveInAmeyaBackend(file, '.pdf', fileId, fileUrl, index)
            if (save_reponse) {
                const tempLoadArr = [...uploadProgress]
                tempLoadArr[index] = 'processed'
                uploadProgress = tempLoadArr
                setUploadProgress([...tempLoadArr])
                return
            }
            markIndexFailed(index)
        }
        catch (err) {
            console.log("ERROR: ", err);
            markIndexFailed(index)
        }
    }

    const startUploading = async () => {
        if (selectedFiles.length === 0) {
            const message = await AlertMessages('warning', 'Files');
            tostAlert(message, 'warning')
            return
        }

        const file_exists = await fileExcistenceCheck(coafiles, selectedFiles)
        if (file_exists) return

        setLoading(true)
        const tempArr = selectedFiles.map(file => 'pending')
        setUploadProgress([...tempArr])
        uploadProgress = tempArr

        selectedFiles.forEach((file, index) => {
            handleDocumentUpload(file, index)
            const tempLoadArr = [...uploadProgress]
            tempLoadArr[index] = 'uploading'
            uploadProgress = tempLoadArr
            setUploadProgress([...tempLoadArr])
        })
    }

    const closeUploadIndicator = () => {
        setLoading(false)
        setUploadProgress([])
        uploadProgress = []
        resJsonVar = []
        setSelectedFiles([])
    }

    const saveUploadChanges = async () => {
        SetLoadingSave(true)
        try {

            const failedFiles = uploadProgressState?.filter(status => status === "failed");
            const successFiles = uploadProgressState?.filter(status => status === "processed" || status === "done");

            if (failedFiles?.length > 0) {
                if (failedFiles?.length > 1) {
                    tostAlert(`${failedFiles?.length} files failed to upload. Please try again.`, 'error');
                    if (successFiles?.length > 0) {
                        dispatch(setCOAFilesAdded(true))
                    }
                    return
                }
                tostAlert('File upload failed. Please try again.', 'error');
                return
            }

            const res = await FilesApi.createFile(uploadJson)
            if (res.status === 200 || res.status === 204) {
                dispatch(setCOAFilesAdded(true))
                if (uploadProgressState.length > 1) {
                    tostAlert(uploadProgressState.length + " files uploaded", 'success')
                } else {
                    tostAlert("File uploaded", "success")
                }
            }
        } catch (error) {
            console.log(error)
            apiErrorHandler(error)
        } finally {
            SetLoadingSave(false)
            setLoading(false)
            uploadProgress = []
            resJsonVar = []
            setUploadProgress([])
            setSelectedFiles([])
        }
    }

    useEffect(() => {
        if (uploadProgressState && uploadProgressState.length && uploadProgressState.length > 0) {
            let i
            let flagToSave = true
            for (i = 0; i < uploadProgressState.length; i++) {
                if (!(uploadProgressState[i] === "processed" || uploadProgressState[i] === "done" || uploadProgressState[i] === "failed")) {
                    flagToSave = false
                    break
                }
            }
            if (flagToSave) {
                saveUploadChanges()
            }
        }
        //eslint-disable-next-line
    }, [uploadProgressState])

    const handleCoaFileDelete = async (item) => {
        setLoading(true)
        try {
            const res = await AuditToolApi.deleteUploadedFiles(item)
            if (res.status === 200) {
                dispatch(setCOAFilesAdded(true))
                tostAlert('File Deleted Successfully', 'success')
            }
        } catch (error) {
            tostAlert('File Deletion Failed', 'error')
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    return (
        <Box sx={{ height: '100%', width: '100%' }}>
            {(loading || coafileloading) && (<LoadBar />)}
            {
                uploadProgressState && uploadProgressState.length > 0
                    ?
                    <Box sx={styles.uploadLoadingBox}>
                        <Box sx={styles.uploadingHeaderBox}>
                            {
                                loadingSave
                                    ?
                                    <Typography sx={styles.uploadingText}>Saving changes</Typography>
                                    :
                                    <Typography sx={styles.uploadingText}>Uploading files …</Typography>
                            }
                            {
                                !loadingSave ? <CancelOutlined sx={styles.closeIcon} onClick={closeUploadIndicator} /> : ''
                            }
                        </Box>
                        {
                            loadingSave ? <LinearProgress width="100%" /> : ''
                        }
                        <Box sx={styles.fileListContainer}>
                            {
                                selectedFiles.map((file, index) =>
                                    <Box key={"upload_progress_ind_key_" + index}>
                                        {
                                            index > 0 ? <Divider width="100%" /> : ''
                                        }
                                        <Box display="flex" height="40px" width="100%" alignItems="center" paddingX="10px">
                                            {
                                                ({
                                                    'uploading': <CircularProgress size="18px" sx={{ ...styles.tickIcon, color: '#0B51C5' }} />,
                                                    'pending': <CircularProgress sx={{ ...styles.tickIcon, color: '#0B51C5' }} />,
                                                    'success': <CheckCircleOutlineOutlined sx={{ ...styles.tickIcon, color: '#1976d2' }} />,
                                                    'failed': <ErrorOutlineOutlined sx={styles.failedIcon} />
                                                }[uploadProgressState[index]])
                                            }
                                            <Typography noWrap sx={styles.dropZoneFont}>{file?.name}</Typography>
                                        </Box>
                                    </Box>
                                )
                            }
                        </Box>
                    </Box>
                    :
                    ''
            }

            <Box marginTop={'10px'} sx={styles.fileUploadContainer}>
                <Box
                    onDrop={handleFileDrop}
                    onDragOver={(e) => e.preventDefault()}

                >
                    <input
                        type="file"
                        accept="application/pdf"
                        onChange={handleFileChange}
                        style={{ display: 'none', cursor: 'pointer' }}
                        id="fileInput"
                        multiple
                    />
                    <label htmlFor="fileInput">
                        {selectedFiles.length === 0 &&
                            <Box>
                                <Typography sx={styles.fileDropLabelText}>Drag and drop files or&nbsp;<span style={{ color: '#0B51C5', cursor: 'pointer' }}>click to upload</span> </Typography>
                                <Typography sx={styles.paraText}>Upto 50 MB in size (.pdf) </Typography>
                            </Box>
                        }
                        <IconButton component="span">
                            <CloudUpload style={{ color: "black", width: "32px", height: "32px" }} />
                        </IconButton>
                    </label>
                </Box>
                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} flexWrap="wrap">
                    {
                        selectedFiles.length > 0 && selectedFiles.map((file, index) => (
                            < Box key={index} display={'flex'} justifyContent={'center'} alignItems={'center'} alignContent={'center'} textAlign={'center'} padding={'3px 15px 3px 15px'} margin={'5px'} backgroundColor={'#FFFFFF'} borderRadius={'20px'}>
                                <Typography sx={styles.paraText}>{file.name}</Typography>
                                <Box marginLeft={'10px'} onClick={() => handleFileRemove(index)}>
                                    <ReactSVG src={IconSvg.deleteIcon} className='delete_icon' />
                                </Box>
                            </Box>
                        ))
                    }

                </Box>
            </Box >

            <Box marginTop={'20px'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                <Button
                    sx={{ ...buttonStyles.primaryBtn, width: '180px', height: '40px' }}
                    onClick={() => startUploading()}
                >
                    <Typography style={styles.btnText}>Upload and Train</Typography>
                </Button>
            </Box>

            <Box marginTop={'10px'} paddingBottom={'20px'}>
                <GeneralTable
                    data={coafiles}
                    columns={columns}
                    loading={coafileloading}
                />
            </Box>

        </Box >
    )
}

export default CoaFiles