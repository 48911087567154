import { AxiosObj } from "../../Configurations/axios-setup";
import { fetchAccountId, fetchSubscriberId, fetchSubscriptionId } from "../../../utils/GetAccountDetails";
import axios from "axios";

class dpodappFlyteApi {

    constructor() {
        this.accountId = null;
    }

    async initialize() {
        this.accountId = await fetchAccountId();
        this.subscriberId = await fetchSubscriberId()
        this.subscriptionId = await fetchSubscriptionId()
    }

    getPresignedURL = async (reqBody) => {
        await this.initialize();
        return AxiosObj.post(`/api/media/${this.accountId}/subscriber/${this.subscriberId}/subscription/${this.subscriptionId}/generate-upload-url`, reqBody)
    }

    getPresignedURLByFilename = async (reqBody) => {
        await this.initialize();
        return AxiosObj.post(`/api/media/${this.accountId}/subscriber/${this.subscriberId}/subscription/${this.subscriptionId}/generate-upload-url-by-file`, reqBody)
    }

    getUploadedFileUrls = async (fileIds) => {
        await this.initialize();
        return AxiosObj.get(`/api/media/${this.accountId}/subscriber/${this.subscriberId}/subscription/${this.subscriptionId}/generate-upload-url?fileId=${fileIds}`)
    }

    uploadFile = async (url, reqBody) => {
        return axios.post(url, reqBody)
    }

    createFile = async (reqBody) => {
        await this.initialize();
        return AxiosObj.post(`/api/media/${this.accountId}/subscriber/${this.subscriberId}/subscription/${this.subscriptionId}/file`, reqBody)
    }

    getFile = async (file_id) => {
        await this.initialize();
        return AxiosObj.get(`/api/media/${this.accountId}/subscriber/${this.subscriberId}/subscription/${this.subscriptionId}/file/${file_id}`)
    }

}

const FilesApi = new dpodappFlyteApi();
export default FilesApi;
