import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useTheme } from '@mui/material/styles';
import { Box, Button, Card, CardContent, Grid2 as Grid, Typography, Link, Stack, Breadcrumbs } from '@mui/material';
import { NavigateNextRounded } from '@mui/icons-material';

import { useStyles } from './Styles';
import { buttonStyles } from '../../styles/buttonStyles';
import { fontStyles } from '../../styles/fontStyles';
import { getMainStyles } from '../../styles/styles';
import { breadCrumbsStyles } from '../../styles/breadCrumbs';

import { setProjectsState, setSpacesState } from "../../Redux/slice/dataSlice";
import { setProjectAdded, setWorkspaceAddedAdded } from '../../Redux/slice/newDataSlice';

import LoadBar from '../../utils/LoadBar';
import getSpaceData from '../../utils/ApiFunctions/SpaceData';
import { handleSidebarConfig } from '../../utils/SidebarConfig';
import getProjectData from '../../utils/ApiFunctions/ProjectData';
import { getSessionData, storeSessionData } from '../../utils/sessionDataHandle';
import { setProjectChanged } from '../../Redux/slice/currentSelectedSlice';

function ViewProjects() {

    const theme = useTheme();
    const mainStyles = getMainStyles(theme);
    const styles = useStyles(theme);

    const navigate = useNavigate()
    const { space_id } = useParams()
    const dispatch = useDispatch()
    const [currentspace, setCurrentSpace] = useState(null)

    const all_projects = useSelector(state => state.all_data.projects)
    const all_spaces = useSelector(state => state.all_data.spaces)
    const project_added = useSelector(state => state.data_added.project_added)
    const workspace_added = useSelector(state => state.data_added.workspace_added)

    const [projects, setProjects] = useState([])
    const [projectLoading, setProjectLoading] = useState(false);

    const selected_space = getSessionData("selected_space")

    useEffect(() => {
        const current_space_id = selected_space?.payload?.__auto_id__
        if (!current_space_id) {
            navigate('/')
            return
        }
        getAllData()
        //eslint-disable-next-line
    }, [space_id])

    const getSpaceDetails = async () => {
        let response
        if (all_spaces?.length > 0 && workspace_added === false) {
            response = all_spaces
        } else {
            response = await getSpaceData()
            dispatch(setSpacesState(response))
            dispatch(setWorkspaceAddedAdded(false))
        }
        const filterd_space_id = all_spaces?.filter(space => space?.payload?.__auto_id__ === space_id).map(space => space?.payload?.__auto_id__)?.[0] ?? null;
        return filterd_space_id
    }

    const getProjectDetails = async () => {
        if (all_projects?.length > 0 && project_added === false) {
            return all_projects
        }
        const response = await getProjectData()
        dispatch(setProjectsState(response))
        dispatch(setProjectAdded(false))
        return response
    }

    const getAllData = async () => {
        setProjectLoading(true)
        try {
            const [spaceResponse, projectsResponse] = await Promise.all([
                getSpaceDetails(),
                getProjectDetails(),
            ]);
            if (spaceResponse && projectsResponse) {
                const filterd_projects = projectsResponse?.filter(project => project?.payload?.workspace?.includes(spaceResponse))
                setCurrentSpace(spaceResponse)
                setProjects(filterd_projects)
            }
        } catch (error) {
            console.error(error)
        } finally {
            setProjectLoading(false)
        }
    }

    const handleProjectCreation = async () => {
        navigate(`/spaces/${currentspace}/projects/add`)
    }

    const handleProjectSelection = async (item) => {
        storeSessionData("selected_project", item)
        dispatch(setProjectChanged(true))
        await handleSidebarConfig(selected_space, item, navigate)
    }

    const handleSpaceNavigation = () => {
        sessionStorage.removeItem("selected_space")
        navigate('/')
    }

    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit"
            onClick={() => handleSpaceNavigation()}
            sx={breadCrumbsStyles.linkHeader}>
            <Typography sx={styles.linkText}>All Spaces</Typography>
        </Link>,
        <Typography key="1" sx={styles.mainHeadingText}>
            {selected_space?.payload?.name}
        </Typography>,
    ];

    return (
        <div style={styles.mainContainer}>
            <Box sx={styles.cardContainer}>
                {projectLoading && (<LoadBar />)}
                <Box padding={'20px'}>

                    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>

                        <Stack spacing={2} sx={{ marginLeft: '0' }}>
                            <Breadcrumbs
                                separator={<NavigateNextRounded fontSize="small" />}
                                aria-label="breadcrumb"
                            >
                                {breadcrumbs}
                            </Breadcrumbs>
                        </Stack>

                        <Button
                            sx={{ ...buttonStyles.primaryBtn, width: '130px' }}
                            onClick={() => handleProjectCreation()}
                        >
                            <Typography sx={styles.btnText}>New Project</Typography>
                        </Button>
                    </Box>

                    <Box marginTop={'50px'}>
                        <Grid container spacing={6}>
                            {projects && projects.length > 0 ? projects?.map((project, index) => (
                                <Grid size={{ xs: 6, md: 3 }} key={index} >
                                    <Card sx={styles.card}>
                                        <CardContent sx={{ padding: '0' }} onClick={() => handleProjectSelection(project)}>
                                            <Box sx={styles.cardHeader}>
                                                <Typography sx={styles.cardHeadingText}>{project?.payload?.name}</Typography>
                                            </Box>
                                            <Box sx={styles.cardBody}>
                                                <Typography sx={styles.cardDescriptionText}>{project?.payload?.description}</Typography>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            )) :
                                (projects.length === 0 && !projectLoading) && (
                                    <Box sx={mainStyles.noRecord}>
                                        <Typography sx={styles.noRecordsText}>No Records To Display</Typography>
                                    </Box>
                                )}
                        </Grid>
                    </Box>


                </Box>
            </Box>
        </div>
    )
}

export default ViewProjects;