import { useEffect, useState } from "react";
import { Box, Button, FormControl, FormControlLabel, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { getStyles } from "./styles";
import { fontStyles } from "../../../styles/fontStyles";
import { getComponentsStyles } from "../../../styles/componentsStyles";
import { fetchSubscriberId, fetchSubscriptionId } from "../../../utils/GetAccountDetails";
import FilesApi from "../../../Api/Services/AppflyteBackend/FileServiceApi";
import LoadBar from "../../../utils/LoadBar";
import { tostAlert } from "../../../utils/AlertToast";
import { apiErrorHandler } from "../../../utils/ApiErrorHandler";
import handleDocumentUpload from "../../../components/FileUpload";
import AnalyticsApi from "../../../Api/Services/AmeyaBackend/AnalyticsToolApi";

let s3_foldername = window.env.REACT_APP_ANALYTICS_S3_BUCKET_FOLDER || process.env.REACT_APP_ANALYTICS_S3_BUCKET_FOLDER
let s3_file_name = window.env.REACT_APP_ANALYTICS_S3_BUCKET_SCRIPT_FILE || process.env.REACT_APP_ANALYTICS_S3_BUCKET_SCRIPT_FILE

function Defaults() {
    const theme = useTheme();
    const styles = getStyles(theme);
    const componentsStyles = getComponentsStyles(theme);
    const [loading, setLoading] = useState(false);
    const [formErrors, setFormErrors] = useState({});

    // All Chatbot Configs:
    const chatbot_configs = {
        welcome_message: "",
        appflyte_account_configs: {},
        api_url_config: {},
        authentication_configs: {},
        source_details: {},
        ui_configs: {}
    }
    const [chatBotData, setChatBotData] = useState(chatbot_configs);
    const [datasourceList, setDataSourceList] = useState([])

    const chat_data = {
        is_default_source: "false",
        chat_dataset: null,
        chat_welcome_message: ""
    }
    const [chatConfigData, setChatConfigData] = useState(chat_data);

    useEffect(() => {
        fetchAllData();
    }, [])

    const fetchAllData = async () => {
        setLoading(true)
        try {
            const subscriber_id = await fetchSubscriberId();
            const subscription_id = await fetchSubscriptionId();
            const file_name = `${s3_foldername}/${s3_file_name}_${subscriber_id}_${subscription_id}.js`;

            const [configResponse, sourceResponse] = await Promise.all([
                getAnalyticsConfig(file_name),
                fetchSourceDetails()
            ])

            if (configResponse && sourceResponse) {
                setDataSourceList(sourceResponse);

                const is_default = configResponse?.SOURCE_DETAILS?.default_source ?? false;
                if (is_default) {

                    const selected_soure_id = configResponse?.SOURCE_DETAILS?.source_id ?? "";
                    const filtered_source = Array.isArray(sourceResponse) ? sourceResponse.find(item => item?.source_id === selected_soure_id) : null;

                    const selectedSource = filtered_source ?
                        {
                            source_id: filtered_source.source_id,
                            source_name: filtered_source.source_name,
                            source_type: filtered_source.source_type
                        } : null;

                    setChatConfigData((prev) => ({
                        ...prev,
                        is_default_source: "true",
                        chat_dataset: selectedSource,
                    }))
                }
                return;
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const getAnalyticsConfig = async (file_name) => {
        try {
            const response = await FilesApi.getUploadedFileUrls(file_name)
            if (response.status === 200) {
                const responseData = response?.data ?? [];
                const download_url = responseData[responseData?.length - 1]?.download_url ?? null;
                const configData = await fetch(download_url);
                const scriptText = await configData.text();
                const configMatch = scriptText.match(/const chatbotConfig = ({[\s\S]*?});/);

                if (configMatch && configMatch[1]) {
                    const chatbotConfig = JSON.parse(configMatch[1]) ?? {};
                    setChatBotData((prev) => ({
                        ...prev,
                        welcome_message: chatbotConfig?.WELCOME_MESSAGE,
                        appflyte_account_configs: chatbotConfig?.APPFLYTE_ACCOUNT_CONFIGS,
                        api_url_config: chatbotConfig?.API_URL_CONFIGS,
                        authentication_configs: chatbotConfig?.AUTHENTICATION_CONFIGS,
                        source_details: chatbotConfig?.SOURCE_DETAILS,
                        ui_configs: chatbotConfig?.UI_CONFIGS
                    }));
                    setChatConfigData((prev) => ({ ...prev, chat_welcome_message: chatbotConfig?.WELCOME_MESSAGE ?? "" }))
                    return chatbotConfig;
                } else {
                    console.log("chatbotConfig not found!");
                    return {}
                }
            }
            return;
        } catch (error) {
            console.log(error)
            return {}
        }
    }

    const fetchSourceDetails = async () => {
        try {
            const response = await AnalyticsApi.getListOfConnetions();
            if (response.status === 200) {
                const responseData = response?.data?.data ?? [];
                return responseData;
            }
            return
        } catch (error) {
            console.log(error)
            return
        }

    }

    useEffect(() => {
        const errors = { ...formErrors };
        if (formErrors?.chat_welcome_message && chatConfigData?.chat_welcome_message?.trim()) { delete errors?.chat_welcome_message }
        if (JSON.stringify(errors) !== JSON.stringify(formErrors)) { setFormErrors(errors) }
    }, [chatConfigData, formErrors]);

    const saveSettings = async () => {
        setLoading(true);
        try {
            const errors = {};
            if (!chatConfigData?.chat_welcome_message?.trim()) { errors.chat_welcome_message = 'This field is required' }
            if (chatConfigData?.is_default_source === "true") {
                if (!chatConfigData?.chat_dataset) {
                    tostAlert("Select Api key and try again..", "warning");
                    return;
                }
            }

            if (Object.keys(errors).length === 0) {
                const chatEmbedd_file = `(function () {
                    function initChatbot() {

                        console.log("Initializing chatbot...");

                        function cleanupExistingChatbot() {
                            const existingIframe = document.getElementById("ameya-chatbot-iframe");
                            const existingCloseBtn = document.getElementById("chatbot-close-btn");
                            if (existingIframe) existingIframe.remove();
                            if (existingCloseBtn) existingCloseBtn.remove();
                        }
                        cleanupExistingChatbot();

                        function handleCloseButton() {
                            const iframe = document.getElementById("ameya-chatbot-iframe");
                            const closeBtn = document.getElementById("chatbot-close-btn");
                            const chatBotButton = document.getElementById("chatBotButton");

                            if (iframe) iframe.style.display = "none";
                            if (closeBtn) closeBtn.style.display = "none";

                            if (chatBotButton) {
                                chatBotButton.style.display = "block";
                                console.log("Chatbot closed, button made visible");
                            }
                        }

                        window.toggleChatbot = (params) => {

                            // START chatbotConfig
                            const chatbotConfig = {
                                "WELCOME_MESSAGE": ${JSON.stringify(chatConfigData?.chat_welcome_message || "")},
                                "APPFLYTE_ACCOUNT_CONFIGS": ${JSON.stringify(chatBotData?.appflyte_account_configs || {})},
                                "API_URL_CONFIGS": ${JSON.stringify(chatBotData?.api_url_config || {})},
                                "SOURCE_DETAILS": {
                                    "default_source": ${JSON.stringify(chatConfigData?.is_default_source === "true" ? true : false)},
                                    "source_type": ${JSON.stringify(chatConfigData?.is_default_source === "true" ? chatConfigData?.chat_dataset?.source_type || "" : "")},
                                    "source_id": ${JSON.stringify(chatConfigData?.is_default_source === "true" ? chatConfigData?.chat_dataset?.source_id || "" : "")},
                                    "source_name": ${JSON.stringify(chatConfigData?.is_default_source === "true" ? chatConfigData?.chat_dataset?.source_name || "" : "")},
                                    "realm_attributes": ${JSON.stringify(chatBotData?.source_details?.realm_attributes || [])}
                                },
                                "UI_CONFIGS":${JSON.stringify(chatBotData?.ui_configs || {})},
                                "AUTHENTICATION_CONFIGS": ${JSON.stringify(chatBotData?.authentication_configs || {})}
                            };
                            // END chatbotConfig

                            const realm_attributes = chatbotConfig?.SOURCE_DETAILS?.realm_attributes ?? [];
                            const realm_details = params?.realm_details ?? {};
                            if (realm_attributes?.length !== Object.keys(realm_details)?.length) {
                                alert("Invalid Realm Details");
                                return
                            } else {
                                const allKeysMatch = realm_attributes.every(attr => realm_details.hasOwnProperty(attr));
                                if (!allKeysMatch) {
                                    alert("Invalid Realm Details");
                                    return
                                }
                            }

                            let iframe = document.getElementById("ameya-chatbot-iframe");
                            let closeBtn = document.getElementById("chatbot-close-btn");
                            const chatBotButton = document.getElementById("chatBotButton");

                            if (iframe && closeBtn) {
                                const isHidden = iframe.style.display === "none";
                                iframe.style.display = isHidden ? "block" : "none";
                                closeBtn.style.display = isHidden ? "block" : "none";

                                if (chatBotButton) {
                                    chatBotButton.style.display = isHidden ? "none" : "block";
                                }
                                return;
                            }

                            if (chatBotButton) chatBotButton.style.display = "none";

                            iframe = document.createElement("iframe");
                            iframe.id = "ameya-chatbot-iframe";
                            iframe.style.position = "fixed";
                            iframe.style.top = "0";
                            iframe.style.left = "0";
                            iframe.style.width = "100vw";
                            iframe.style.height = "100vh";
                            iframe.style.border = "none";
                            iframe.style.zIndex = "9999";
                            iframe.style.display = "block";
                            document.body.appendChild(iframe);

                            closeBtn = document.createElement("button");
                            closeBtn.id = "chatbot-close-btn";
                            closeBtn.innerText = "✖";
                            closeBtn.style.position = "fixed";
                            closeBtn.style.top = "18px";
                            closeBtn.style.right = "20px";
                            closeBtn.style.color = "black";
                            closeBtn.style.border = "none";
                            closeBtn.style.borderRadius = "5px";
                            closeBtn.style.fontSize = "16px";
                            closeBtn.style.cursor = "pointer";
                            closeBtn.style.zIndex = "10000";
                            closeBtn.style.display = "block";
                            document.body.appendChild(closeBtn);

                            const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
                            const script = document.createElement("script");
                            script.src = "/dist/ameya-chatbot-v0.1.0.js";
                            // script.src = "https://ameyachatbotsettingsconfig.s3.us-east-1.amazonaws.com/newtestingfolder/ameya-chatbot-v0.1.0.js"
                            script.async = true;

                            function evalPlaceholder(expression) {
                                return new Function("params", \`return \${expression}\`)(params);
                            }

                            const userConfigs={
                                "realm_details":  params?.realm_details ?? {},
                                "third_party_token_provider": evalPlaceholder('params?.token_provider ?? null'),
                                "third_party_token": evalPlaceholder('params?.third_party_token ?? null')
                            }

                            const finalConfig = { ...chatbotConfig, ...userConfigs }; 
                            script.onload = () => {
                                iframe.contentWindow.AmeayaChatBot?.("init", finalConfig);
                            };

                            iframeDoc.body.appendChild(script);
                        };

                        document.addEventListener("click", (event) => {
                            if (event.target.id === "chatbot-close-btn") {
                                handleCloseButton();
                            }
                        });

                    }
                    initChatbot();
                })()`

                const subscriber_id = await fetchSubscriberId();
                const subscription_id = await fetchSubscriptionId();
                const blob = new Blob([chatEmbedd_file], { type: "application/javascript" });
                const filename = `${s3_file_name}_${subscriber_id}_${subscription_id}.js`;
                const file = new File([blob], filename, { type: "application/javascript" });
                const response = await handleDocumentUpload(filename, s3_foldername, file);
                if (response.status === 200) {
                    tostAlert('Configuration saved successfully', 'success');
                    fetchAllData();
                }

            } else {
                setFormErrors(errors);
            }
        } catch (error) {
            console.log(error)
            apiErrorHandler(error)
        } finally {
            setLoading(false)
        }
    }

    return (
        <Box sx={styles.mainContainer}>
            <Box sx={styles.cardContainer}>

                {loading && <LoadBar />}

                <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                    <Typography sx={styles.mainHeadingText}>Defaults</Typography>

                    <Box display={'flex'} alignItems={'center'}>
                        <Button
                            sx={{ ...styles.saveBtn }}
                            disabled={loading}
                            onClick={() => saveSettings()}
                        >
                            <Typography sx={styles.btnText}>SAVE</Typography>
                        </Button>
                        <Button sx={{ ...styles.cancelBtn, marginLeft: '10px' }}>
                            <Typography sx={styles.btnText}>CANCEL</Typography>
                        </Button>
                    </Box>
                </Box>

                <Typography sx={{ ...styles.paraText, marginTop: '10px' }}>
                    Configure default data source and welcome message
                </Typography>

                <Box marginTop={'20px'}>

                    <Box display={'flex'} alignItems={'center'}>
                        <Typography sx={styles.paraText}>Default Source Required?</Typography>
                        <Box marginLeft={'20px'}>
                            <FormControl>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    value={chatConfigData?.is_default_source}
                                    onChange={(e) => setChatConfigData({ ...chatConfigData, is_default_source: e.target.value })}
                                    style={{ display: 'flex', flexDirection: 'row' }}
                                >
                                    <FormControlLabel value="true" control={<Radio color='#59A292' />} label="Yes" />
                                    <FormControlLabel value="false" control={<Radio color='#59A292' />} label="No" />
                                </RadioGroup>
                            </FormControl>
                        </Box>
                    </Box>

                    {chatConfigData?.is_default_source === "true" &&
                        <Box marginTop={'10px'}>
                            <FormControl >
                                <Typography sx={{ ...styles.paraText, marginBottom: '6px' }}>Default Datasource</Typography>
                                <Select
                                    labelId="expiration-label"
                                    id="expiration"
                                    value={chatConfigData.chat_dataset ? JSON.stringify(chatConfigData.chat_dataset) : ""}

                                    onChange={(e) => {
                                        if (!e.target.value) {
                                            setChatConfigData(prev => ({ ...prev, chat_dataset: null }));
                                            return;
                                        }
                                        const selectedSource = JSON.parse(e.target.value);
                                        setChatConfigData(prev => ({ ...prev, chat_dataset: selectedSource }));
                                    }}
                                    size='small'
                                    displayEmpty
                                    sx={{ ...componentsStyles.selectField, width: '300px' }}
                                >
                                    <MenuItem value="">
                                        <Typography variant='body1'>Select Data Source</Typography>
                                    </MenuItem>
                                    {Array.isArray(datasourceList) && datasourceList.length > 0 ? (
                                        datasourceList.map((item, index) => {
                                            if (!item?.source_name || !item?.source_type || !item?.source_id) return null;

                                            const sourceData = {
                                                source_name: item.source_name,
                                                source_type: item.source_type,
                                                source_id: item.source_id
                                            };

                                            return (
                                                <MenuItem key={index} value={JSON.stringify(sourceData)}>
                                                    <Typography variant="body1">{item.source_name}</Typography>
                                                </MenuItem>
                                            );
                                        })
                                    ) : (
                                        <MenuItem value="">
                                            <Typography variant="body1">No Source Available</Typography>
                                        </MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Box>
                    }
                    <Box marginTop={'20px'}>
                        <FormControl>
                            <Typography sx={{ ...styles.paraText, marginBottom: '6px' }}>Welcome Message</Typography>
                            <TextField
                                id="welcome-message"
                                placeholder='Welcome Message'
                                size='small'
                                sx={{ ...componentsStyles.textField, width: '600px' }}
                                value={chatConfigData.chat_welcome_message}
                                onChange={(e) => setChatConfigData({ ...chatConfigData, chat_welcome_message: e.target.value })}
                            />
                        </FormControl>
                        {formErrors.chat_welcome_message && <Typography sx={{ ...styles.paraText, color: 'red' }}>{formErrors.chat_welcome_message}</Typography>}
                    </Box>
                </Box>

            </Box>
        </Box>
    )

} export default Defaults;