import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    user_roles: [],
    spaces: [],
    appflyte_engines: [],
    appflyte_functions: [],
    appflyte_event_types: [],
    projects: [],
    agent_api_tokens: [],
    project_source_folders: [],
    coafiles: [],
    specificationfiles: [],
    audit_test_history: [],
    docsearchfiles: [],
    llmdocsearchfiles: [],
    relam_settings: [],
    agent_logs: []
};

const dataSlice = createSlice({
    name: 'all_data',
    initialState,
    reducers: {

        setUserRoleState(state, action) {
            state.user_roles = action.payload;
        },

        // Spaces
        setSpacesState(state, action) {
            state.spaces = action.payload;
        },

        // Engines
        setAppflyteEngineState(state, action) {
            state.appflyte_engines = action.payload;
        },

        // Functions
        setAppflyteFunctionState(state, action) {
            state.appflyte_functions = action.payload;
        },

        // Event Types
        setAppflyteEventTypesState(state, action) {
            state.appflyte_event_types = action.payload;
        },

        // Projects
        setProjectsState(state, action) {
            state.projects = action.payload;
        },

        // AgentAPiToken
        setAgentApiTokenState(state, action) {
            state.agent_api_tokens = action.payload;
        },

        // SourceFiles
        setProjectSourceFolderState(state, action) {
            state.project_source_folders = action.payload;
        },
        setCOAFilesState(state, action) {
            state.coafiles = action.payload;
        },
        setSpecificationFilesState(state, action) {
            state.specificationfiles = action.payload;
        },
        setAuditTestHistoryState(state, action) {
            state.audit_test_history = action.payload;
        },

        // Doc Search Files
        setDocSearchFilesState(state, action) {
            state.docsearchfiles = action.payload;
        },
        setLLMDocSearchFilesState(state, action) {
            state.llmdocsearchfiles = action.payload;
        },
        setRelamSettingState(state, action) {
            state.relam_settings = action.payload;
        },

        // Agent Logs
        setAgentLogsState(state, action) {
            state.agent_logs = action.payload;
        },
    },
});

export const {
    setUserRoleState,
    setSpacesState,
    setAppflyteEngineState,
    setAppflyteFunctionState,
    setAppflyteEventTypesState,
    setProjectsState,
    setAgentApiTokenState,
    setProjectSourceFolderState,
    setCOAFilesState,
    setSpecificationFilesState,
    setAuditTestHistoryState,
    setDocSearchFilesState,
    setLLMDocSearchFilesState,
    setRelamSettingState,
    setAgentLogsState
} = dataSlice.actions;

export default dataSlice.reducer;
