import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useTheme } from '@mui/material/styles';
import { Box, Typography, MenuItem, Select, Menu, Chip, Avatar } from '@mui/material';
import { getStyles } from './Styles';

import { ArrowDropDown, Logout } from '@mui/icons-material';
import getSpaceData from '../../utils/ApiFunctions/SpaceData';

import { setProjectsState, setSpacesState } from "../../Redux/slice/dataSlice";
import { setProjectAdded, setWorkspaceAddedAdded } from '../../Redux/slice/newDataSlice';
import { handleSidebarConfig } from "../../utils/SidebarConfig";

import ameya_logo from "../../images/ameya_nav_logo.png"
import { fontStyles } from '../../styles/fontStyles';
import { getUserInitials, getUserName } from '../../utils/GetAccountDetails';
import { getSessionData, storeSessionData } from '../../utils/sessionDataHandle';
import { setProjectChanged, setSpaceChanged } from '../../Redux/slice/currentSelectedSlice';
import getProjectData from '../../utils/ApiFunctions/ProjectData';

function Navbar() {
    const theme = useTheme();
    const styles = getStyles(theme)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const all_spaces = useSelector(state => state.all_data.spaces)
    const all_projects = useSelector(state => state.all_data.projects)
    const space_changed = useSelector(state => state.current_selected_data.space_changed)
    const project_changed = useSelector(state => state.current_selected_data.project_changed)
    const workspace_added = useSelector(state => state.data_added.workspace_added)
    const project_added = useSelector(state => state.data_added.project_added)
    const selected_space = getSessionData("selected_space")
    const selected_project = getSessionData("selected_project")

    const [spaces, setSpaces] = useState([])
    const [projects, setProjects] = useState([])
    const [spacesLoading, setSpaceLoading] = useState([])
    const [projectsLoading, setProjectsLoading] = useState([])

    const [spacesmenu, setSpacesMenu] = useState("");
    const [projectsmenu, setProjectsMenu] = useState("");

    const [logoutmenu, setLogoutMenu] = useState(null);

    const handleClick = (event) => {
        setLogoutMenu(event.currentTarget)
    }

    const handleClose = () => {
        setLogoutMenu(null)
    }

    const handleLogout = () => {
        localStorage.clear()
        navigate("/login")
    };

    useEffect(() => {
        if (spacesLoading && spaces?.length === 0 && selected_space) {
            const selected_space_name = selected_space?.payload?.name ?? "";
            setSpacesMenu(selected_space_name)
        }
        if (projectsLoading && projects?.length === 0 && selected_project) {
            const selected_project_name = selected_project?.payload?.name ?? "";
            setProjectsMenu(selected_project_name)
        }
    }, [spacesLoading, projectsLoading])

    useEffect(() => {
        spaceHandle()
        //eslint-disable-next-line
    }, [space_changed])

    useEffect(() => {
        projectHandle()
        //eslint-disable-next-line
    }, [project_changed])

    const spaceHandle = async () => {
        setSpaceLoading(true)
        try {
            if (selected_space || space_changed) {
                if (space_changed) {
                    sessionStorage.removeItem("selected_project");
                    dispatch(setSpaceChanged(false))
                }
                const space_details = await getSpaceDetails();
                const selected_space_name = selected_space?.payload?.name ?? ""
                await handleSpaceChange(selected_space_name, space_details)
            }
        } catch (error) {
            console.error(error)
        } finally {
            setSpaceLoading(false)
        }
    }

    const projectHandle = async () => {
        setProjectsLoading(true)
        try {
            if (selected_project || project_changed) {
                const selected_space_id = selected_space?.payload?.__auto_id__ ?? null
                if (!selected_space || !selected_space_id) {
                    sessionStorage.removeItem("selected_space")
                    sessionStorage.removeItem("selected_project")
                    navigate('/')
                    return
                }
                if (project_changed) {
                    dispatch(setProjectChanged(false))
                }
                const project_details = await getProjectDetails();
                const selected_project_name = selected_project?.payload?.name ?? "";
                await handleProjectChange(selected_project_name, project_details)
            }
        } catch (error) {
            console.error(error)
        }
        finally {
            setProjectsLoading(false)
        }
    }

    const getSpaceDetails = async () => {
        try {
            let response
            if (all_spaces?.length > 0 && workspace_added === false) {
                response = all_spaces
            } else {
                response = await getSpaceData()
                dispatch(setSpacesState(response))
                dispatch(setWorkspaceAddedAdded(false))
            }
            setSpaces(response)
            return response
        } catch (error) {
            console.error(error);
            return []
        }
    }

    const getProjectDetails = async () => {
        try {
            let response
            if (all_projects?.length > 0 && project_added === false) {
                response = all_projects
            } else {
                response = await getProjectData()
                dispatch(setProjectsState(response))
                dispatch(setProjectAdded(false))
            }
            const selected_space_id = selected_space?.payload?.__auto_id__ ?? null
            const filterd_projects = response?.filter(project => project?.payload?.workspace?.includes(selected_space_id))
            setProjects(filterd_projects)
            return response
        } catch (error) {
            console.error(error);
            return []
        }
    }

    const handleSpaceChange = async (space_selected, space_data) => {
        if (space_selected === "*") {
            sessionStorage.removeItem("selected_space")
            sessionStorage.removeItem("selected_project")
            setSpacesMenu(null)
            navigate("/")
            return
        }
        const selectedSpace = space_data?.length > 0 ? space_data?.filter(space => space?.payload?.name === space_selected)?.[0] : null;
        if (selectedSpace) {
            setSpacesMenu(space_selected);
            storeSessionData("selected_space", selectedSpace)
            sessionStorage.removeItem("selected_project")
            navigate(`/spaces/${selectedSpace?.payload?.__auto_id__}/projects`)
        }
        return
    }

    const handleProjectChange = async (project_selected, project_data) => {
        if (project_selected === "*") {
            sessionStorage.removeItem("selected_project")
            setProjectsMenu("");
            navigate(`/spaces/${selected_space?.payload?.__auto_id__}/projects`)
            return
        }
        const selectedProject = project_data?.length > 0 ? project_data?.filter(project => project?.payload?.name === project_selected)?.[0] : null;
        if (selectedProject) {
            setProjectsMenu(project_selected);
            storeSessionData("selected_project", selectedProject)
            handleSidebarConfig(selected_space, selectedProject, navigate)
        }
        return
    }

    const handleHomeNavigation = () => {
        sessionStorage.removeItem("selected_space")
        sessionStorage.removeItem("selected_project")
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <nav style={styles.navbar}>
                <Box sx={styles.navLeftContent}>
                    <Box>
                        <Link
                            to="/"
                            onClick={() => handleHomeNavigation()}
                        >
                            <img src={ameya_logo} alt="Upperline Health Logo" style={{ ...styles.navbarBrand }} />
                        </Link>
                    </Box>

                    {selected_space !== null && (<Box sx={{ marginLeft: '10px', display: 'flex', alignItems: 'center' }}>
                        <Select
                            size='small'
                            sx={{
                                border: 'none',
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: 'none',
                                },
                            }}
                            value={spacesmenu || ""}
                            onChange={(e) => handleSpaceChange(e.target.value, spaces)}
                        >
                            <MenuItem value="*">
                                <Typography variant='body1'>All Spaces</Typography>
                            </MenuItem>

                            {spaces && spaces.length > 0 ? spaces.map((item, index) => (
                                <MenuItem key={index} value={item?.payload?.name}>
                                    <Typography variant='body1'>{item?.payload?.name}</Typography>
                                </MenuItem>
                            )) :
                                (<MenuItem disabled>
                                    <Typography variant='body1'>No Spaces Available</Typography>
                                </MenuItem>)
                            }
                        </Select>
                    </Box>)
                    }

                    {(selected_space !== null && selected_project !== null) &&
                        (<Box sx={{ marginLeft: '5px', display: 'flex', alignItems: 'center' }}>
                            <Select
                                size='small'
                                sx={{
                                    border: 'none',
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none',
                                    },
                                }}
                                value={projectsmenu || ""}
                                onChange={(e) => handleProjectChange(e.target.value, projects)}
                            >
                                <MenuItem value="*">
                                    <Typography variant='body1'>All Projects</Typography>
                                </MenuItem>

                                {Array.isArray(projects) && projects.length > 0 ? projects.map((item, index) => (
                                    <MenuItem key={index} value={item?.payload?.name}>
                                        <Typography variant='body1'>{item?.payload?.name}</Typography>
                                    </MenuItem>
                                )) :
                                    (<MenuItem disabled>
                                        <Typography variant='body1'>No Projects Available</Typography>
                                    </MenuItem>)
                                }
                            </Select>
                        </Box>)
                    }
                </Box>

                <Box sx={styles.navRightContent}>
                    <Box>
                        <Chip
                            avatar={
                                <Avatar sx={{ bgcolor: '#000000', height: '32px', width: '32px' }}>
                                    <Typography
                                        sx={{ ...fontStyles.smallText, color: '#ffffff', padding: '2px' }}
                                        onClick={handleClick}
                                    >
                                        {getUserInitials()}
                                    </Typography>
                                </Avatar>
                            }
                            label={<div style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography sx={fontStyles.mediumText}>
                                    {getUserName()}
                                </Typography>
                                <ArrowDropDown
                                    sx={{ cursor: 'pointer', ml: 1 }}
                                    onClick={handleClick}
                                />
                            </div>}
                            sx={{
                                padding: '8px 7px',
                                backgroundColor: '#FFFFFF',
                                borderRadius: '20px',
                            }}
                        />

                        <Box>
                            <Menu
                                anchorEl={logoutmenu}
                                open={logoutmenu}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={() => handleLogout()} sx={{ ...fontStyles.smallText, dispaly: 'flex', alignItems: 'center' }}>
                                    <Typography>Logout&nbsp;</Typography>
                                    <Logout />
                                </MenuItem>
                            </Menu>
                        </Box>
                    </Box>
                </Box>

            </nav>
        </Box>
    )
}

export default Navbar