import { Box, CircularProgress, Snackbar, Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { getStyles } from "./styles";
import { useEffect, useRef, useState } from "react";
import LoadBar from "../../../../utils/LoadBar";
import { getSessionData } from "../../../../utils/sessionDataHandle";
import getAmeyaSettingsData from "../../../../utils/ApiFunctions/AmeyaSettingsData";
import { fetchSubscriberId, fetchSubscriptionId } from "../../../../utils/GetAccountDetails";
import FilesApi from "../../../../Api/Services/AppflyteBackend/FileServiceApi";
import { apiErrorHandler } from "../../../../utils/ApiErrorHandler";
import handleDocumentUpload from "../../../../components/FileUpload";

let s3_foldername = window.env.REACT_APP_ANALYTICS_S3_BUCKET_FOLDER || process.env.REACT_APP_ANALYTICS_S3_BUCKET_FOLDER
let s3_file_name = window.env.REACT_APP_ANALYTICS_S3_BUCKET_SCRIPT_FILE || process.env.REACT_APP_ANALYTICS_S3_BUCKET_SCRIPT_FILE

function Embed() {
    const theme = useTheme();
    const styles = getStyles(theme);
    const codeRef = useRef(null);
    const [copied, setCopied] = useState(false);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [realmKeys, setRealmKeys] = useState([]);
    const [subscriberId, setSubscriberId] = useState(null);
    const [subscriptionId, setsubscriptionId] = useState(null);

    useEffect(() => {
        fetchAllData()
    }, [])

    const fetchAllData = async () => {
        setLoading(true);
        try {
            const [analyticsResponse, realmResponse] = await Promise.all([
                fetchAnalyticsConfig(),
                fetchRelamDetails()
            ])
            if (realmResponse && analyticsResponse) {
                const chatbot_realm_data = analyticsResponse?.SOURCE_DETAILS?.realm_attributes ?? []
                const is_equal = await compareRealmSettings(realmResponse, chatbot_realm_data);
                if (!is_equal) {
                    console.log("!..updating the relam configs")
                    await saveChatBotSettings(analyticsResponse, realmResponse);
                }
                return { analyticsResponse, realmResponse }
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false);
        }
    }

    const compareRealmSettings = async (arr1, arr2) => {
        if (arr1?.length !== arr2?.length) return false;
        return arr1?.every((item, index) => item === arr2[index]);
    };

    const fetchAnalyticsConfig = async () => {
        try {
            const subscriber_id = await fetchSubscriberId();
            const subscription_id = await fetchSubscriptionId();
            setSubscriberId(subscriber_id); setsubscriptionId(subscription_id);
            const file_name = `${s3_foldername}/${s3_file_name}_${subscriber_id}_${subscription_id}.js`;
            const response = await FilesApi.getUploadedFileUrls(file_name)
            if (response.status === 200) {
                const responseData = response?.data ?? [];
                const download_url = responseData[responseData?.length - 1]?.download_url ?? null;
                const configData = await fetch(download_url);
                const scriptText = await configData.text();
                const configMatch = scriptText.match(/const chatbotConfig = ({[\s\S]*?});/);

                if (configMatch && configMatch[1]) {
                    const chatbotConfig = JSON.parse(configMatch[1]);
                    return chatbotConfig;
                } else {
                    console.log("chatbotConfig not found!");
                    return {};
                }
            }
        } catch (error) {
            console.log(error)
            return {}
        }
    }

    const fetchRelamDetails = async () => {
        try {
            const selected_project = getSessionData("selected_project")
            const response = await getAmeyaSettingsData(selected_project?.payload?.__auto_id__);
            if (response?.length > 0) {
                const responseData = response?.[0] ?? [];
                const realm_details = responseData?.payload?.settings?.realm_attributes ?? [];
                if (realm_details?.length > 0) {
                    setRealmKeys(realm_details)
                }
                return realm_details;
            }
            return [];
        } catch (error) {
            console.log(error);
            return [];
        }
    }

    const saveChatBotSettings = async (analytics_config, realm_data) => {
        try {
            const chatEmbedd_file = `(function () {
                function initChatbot() {

                    console.log("Initializing chatbot...");

                    function cleanupExistingChatbot() {
                        const existingIframe = document.getElementById("ameya-chatbot-iframe");
                        const existingCloseBtn = document.getElementById("chatbot-close-btn");
                        if (existingIframe) existingIframe.remove();
                        if (existingCloseBtn) existingCloseBtn.remove();
                    }
                    cleanupExistingChatbot();

                    function handleCloseButton() {
                        const iframe = document.getElementById("ameya-chatbot-iframe");
                        const closeBtn = document.getElementById("chatbot-close-btn");
                        const chatBotButton = document.getElementById("chatBotButton");

                        if (iframe) iframe.style.display = "none";
                        if (closeBtn) closeBtn.style.display = "none";

                        if (chatBotButton) {
                            chatBotButton.style.display = "block";
                            console.log("Chatbot closed, button made visible");
                        }
                    }

                    window.toggleChatbot = (params) => {
                        
                        // START chatbotConfig
                        const chatbotConfig = {
                            "WELCOME_MESSAGE": ${JSON.stringify(analytics_config?.WELCOME_MESSAGE || "")},
                            "APPFLYTE_ACCOUNT_CONFIGS": ${JSON.stringify(analytics_config?.APPFLYTE_ACCOUNT_CONFIGS || {})},
                            "API_URL_CONFIGS": ${JSON.stringify(analytics_config?.API_URL_CONFIGS || {})},
                            "SOURCE_DETAILS": {
                                "default_source": ${JSON.stringify(analytics_config?.SOURCE_DETAILS?.default_source || false)},
                                "source_type": ${JSON.stringify(analytics_config?.SOURCE_DETAILS?.source_type || "")},
                                "source_id": ${JSON.stringify(analytics_config?.SOURCE_DETAILS?.source_id || "")},
                                "source_name": ${JSON.stringify(analytics_config?.SOURCE_DETAILS?.source_name || "")},
                                "realm_attributes": ${JSON.stringify(realm_data || [])}
                            },
                            "UI_CONFIGS":${JSON.stringify(analytics_config?.UI_CONFIGS || {})},
                            "AUTHENTICATION_CONFIGS": ${JSON.stringify(analytics_config?.AUTHENTICATION_CONFIGS || {})}
                        };
                        // END chatbotConfig

                        const realm_attributes = chatbotConfig?.SOURCE_DETAILS?.realm_attributes ?? [];
                        const realm_details = params?.realm_details ?? {};
                        if (realm_attributes?.length !== Object.keys(realm_details)?.length) {
                            alert("Invalid Realm Details");
                            return
                        } else {
                            const allKeysMatch = realm_attributes.every(attr => realm_details.hasOwnProperty(attr));
                            if (!allKeysMatch) {
                                alert("Invalid Realm Details");
                                return
                            }
                        }

                        let iframe = document.getElementById("ameya-chatbot-iframe");
                        let closeBtn = document.getElementById("chatbot-close-btn");
                        const chatBotButton = document.getElementById("chatBotButton");

                        if (iframe && closeBtn) {
                            const isHidden = iframe.style.display === "none";
                            iframe.style.display = isHidden ? "block" : "none";
                            closeBtn.style.display = isHidden ? "block" : "none";

                            if (chatBotButton) {
                                chatBotButton.style.display = isHidden ? "none" : "block";
                            }
                            return;
                        }

                        if (chatBotButton) chatBotButton.style.display = "none";

                        iframe = document.createElement("iframe");
                        iframe.id = "ameya-chatbot-iframe";
                        iframe.style.position = "fixed";
                        iframe.style.top = "0";
                        iframe.style.left = "0";
                        iframe.style.width = "100vw";
                        iframe.style.height = "100vh";
                        iframe.style.border = "none";
                        iframe.style.zIndex = "9999";
                        iframe.style.display = "block";
                        document.body.appendChild(iframe);

                        closeBtn = document.createElement("button");
                        closeBtn.id = "chatbot-close-btn";
                        closeBtn.innerText = "✖";
                        closeBtn.style.position = "fixed";
                        closeBtn.style.top = "18px";
                        closeBtn.style.right = "20px";
                        closeBtn.style.color = "black";
                        closeBtn.style.border = "none";
                        closeBtn.style.borderRadius = "5px";
                        closeBtn.style.fontSize = "16px";
                        closeBtn.style.cursor = "pointer";
                        closeBtn.style.zIndex = "10000";
                        closeBtn.style.display = "block";
                        document.body.appendChild(closeBtn);

                        const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
                        const script = document.createElement("script");
                        script.src = "/dist/ameya-chatbot-v0.1.0.js";
                        // script.src = "https://ameyachatbotsettingsconfig.s3.us-east-1.amazonaws.com/newtestingfolder/ameya-chatbot-v0.1.0.js"
                        script.async = true;

                        function evalPlaceholder(expression) {
                            return new Function("params", \`return \${expression}\`)(params);
                        }

                        const userConfigs={
                            "realm_details": params?.realm_details ?? {},
                            "third_party_token_provider": evalPlaceholder('params?.token_provider ?? null'),
                            "third_party_token": evalPlaceholder('params?.third_party_token ?? null')
                        }

                        const finalConfig = { ...chatbotConfig, ...userConfigs }; 
                        script.onload = () => {
                            iframe.contentWindow.AmeayaChatBot?.("init", finalConfig);
                        };

                        iframeDoc.body.appendChild(script);
                    };

                    document.addEventListener("click", (event) => {
                        if (event.target.id === "chatbot-close-btn") {
                            handleCloseButton();
                        }
                    });

                }
                initChatbot();
            })()`
            const blob = new Blob([chatEmbedd_file], { type: "application/javascript" });
            const filename = `${s3_file_name}_${subscriberId}_${subscriptionId}.js`;
            const file = new File([blob], filename, { type: "application/javascript" });
            const response = await handleDocumentUpload(filename, s3_foldername, file);
            return response
        } catch (error) {
            console.log(error)
            apiErrorHandler(error)
        }
    }

    const scriptText = `<script>
        (function () {
            var script = document.createElement("script");
            script.src = "https://ameyachatbotsettingsconfig.s3.amazonaws.com/${s3_foldername}/${s3_file_name}_${subscriberId}_${subscriptionId}.js?v=" + new Date().getTime();
            script.defer = true;
            document.body.appendChild(script);
        })();
    </script>

    const params={
        "third_party_token_provider":"",
        "third_party_token:"" ${realmKeys?.length > 0 ? ',' : ''}
        ${realmKeys?.length > 0 ? realmKeys.map(item => `"${item}": ""`).join(',  ') : ''}
    }

    <button 
        id="chatBotButton"
        onClick={() => {
            if (window.toggleChatbot) {
                window.toggleChatbot(params);
            }
        }}
    >
        Launch
    </button>
    `;

    const handleScriptCopy = () => {
        navigator.clipboard.writeText(scriptText).then(() => {
            setCopied(true);
            setOpen(true);
            setTimeout(() => setCopied(false), 2000);
        });
    };

    const handleClose = () => {
        setOpen(false);
    };


    return (
        <Box sx={styles.mainContainer}>
            <Box sx={styles.cardContainer}>

                {loading && (<LoadBar />)}
                <Typography sx={styles.mainHeadingText}>Embed</Typography>

                <Typography sx={{ ...styles.paraText, marginTop: '10px' }}>
                    Copy the below script to embed Conversational AI Analyst agent in your app
                </Typography>

                <Box sx={{ marginTop: '20px', paddingBottom: '20px' }}>
                    <Box sx={styles.embeddCodeContainer(copied)} onClick={() => !loading && handleScriptCopy(scriptText)}>
                        {loading
                            ?
                            (<Box textAlign={'center'} padding={'100px'}>
                                <CircularProgress sx={{ height: '18px', width: '18px', color: '#0B51C5' }} />
                            </Box>)
                            :
                            (<Typography
                                ref={codeRef}
                                component="pre"
                                sx={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}
                            >
                                {scriptText}
                            </Typography>)
                        }
                    </Box>
                </Box>

                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={open}
                    autoHideDuration={2000}
                    onClose={handleClose}
                    message="Script Copied"
                />

            </Box>
        </Box >
    )
}
export default Embed; 