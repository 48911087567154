import { AxiosAuditToolObj } from "../../Configurations/axios-ameya-setup";
import { fetchAccountId, fetchSubscriberId, fetchSubscriptionId } from "../../../utils/GetAccountDetails"

class ameyaBackendApi {

    constructor() {
        this.accountId = null;
        this.subscribeId = null;
        this.subscriptionId = null;
    }

    async initialize() {
        this.accountId = await fetchAccountId();
        this.subscribeId = await fetchSubscriberId();
        this.subscriptionId = await fetchSubscriptionId();
    }

    // Handle File Upload and get file details
    addPdfFileDetails = async (reqObj) => {
        await this.initialize();
        return AxiosAuditToolObj.post(`/subscriber/${this.subscribeId}/subscription/${this.subscriptionId}/function/addauditartifact/event/qms_add_audit_artifact`, reqObj)
    }

    addJsonFileDetails = async (reqObj) => {
        await this.initialize();
        return AxiosAuditToolObj.post(`/subscriber/${this.subscribeId}/subscription/${this.subscriptionId}/function/addspecartifact/event/qms_add_spec`, reqObj)
    }

    getPdfDataSource = async (page_size, current_page) => {
        await this.initialize();
        return AxiosAuditToolObj.get(`/subscriber/${this.subscribeId}/subscription/${this.subscriptionId}/function/checkcompliance/event/qms_get_source_artifacts?page_size=${page_size}&current_page=${current_page}`)
    }

    getJsonDataSource = async (page_size, current_page) => {
        await this.initialize();
        return AxiosAuditToolObj.get(`/subscriber/${this.subscribeId}/subscription/${this.subscriptionId}/function/checkcompliance/event/qms_get_spec_artifacts?page_size=${page_size}&current_page=${current_page}`)
    }

    deleteUploadedFiles = async (fileId) => {
        await this.initialize();
        return AxiosAuditToolObj.delete(`/subscriber/${this.subscribeId}/subscription/${this.subscriptionId}/function/checkcompliance/event/qms_remove_spec_and_artifact?file_id=${fileId}`)
    }

    // Qms Model  Train And Chat Message

    qmsCheckCompliance = async (reqObj) => {
        await this.initialize();
        return AxiosAuditToolObj.post(`/subscriber/${this.subscribeId}/subscription/${this.subscriptionId}/function/checkcompliance/event/qms_check_compliance`, reqObj)
    }

    trainQmsModel = async (trainId) => {
        await this.initialize();
        return AxiosAuditToolObj.post(`/subscriber/${this.subscribeId}/subscription/${this.subscriptionId}/function/traintopickfactsfromauditartifact/event/qms_train_to_pick_facts_from_audit_artifacts?test_id=${trainId}`)
    }

    qmsUserChatMessage = async (reqObj) => {
        await this.initialize();
        return AxiosAuditToolObj.post(`/subscriber/${this.subscribeId}/subscription/${this.subscriptionId}/function/traintopickfactsfromauditartifact/event/qms_user_chat_message`, reqObj)
    }


    // Train History And Retrain
    getTrainHistory = async (page_size, current_page) => {
        await this.initialize();
        return AxiosAuditToolObj.post(`/subscriber/${this.subscribeId}/subscription/${this.subscriptionId}/function/checkcompliance/event/qms_get_all_tests?page_size=${page_size}&current_page=${current_page}`)
    }

    getTrainHistoryByTemplate = async (page_size, current_page, request_from) => {
        await this.initialize();
        return AxiosAuditToolObj.get(`/subscriber/${this.subscribeId}/subscription/${this.subscriptionId}/function/checkcompliance/event/get_tests/template_layout?page_size=${page_size}&current_page=${current_page}&playground=${request_from}`)
    }

    retrainQmsModel = async (reqObj) => {
        await this.initialize();
        return AxiosAuditToolObj.post(`/subscriber/${this.subscribeId}/subscription/${this.subscriptionId}/function/checkcompliance/event/qms_retrain_compliance`, reqObj)
    }

}

const AuditToolApi = new ameyaBackendApi();
export default AuditToolApi;


